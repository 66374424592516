import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Button } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';

import AzentaTable from './AzentaTable';
import { useEffect, useState } from 'react';
import { AzentaSeqOrder } from 'src/models/azenta';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { getAllAzentaOrders } from 'src/api/AzentaOrderQueries';
import { useAuth0 } from '@auth0/auth0-react';

function Azenta() {
  const { getAccessTokenSilently } = useAuth0();

  const [azentaOrders, setAzentaOrders] = useState<AzentaSeqOrder[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAzentaOrders();
  }, [])

  const fetchAzentaOrders = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const responseAzentaOrders = await getAllAzentaOrders(accessToken);
    if (responseAzentaOrders) {
      setAzentaOrders(responseAzentaOrders);
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Azenta Orders</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchAzentaOrders()}>
              <RefreshIcon fontSize='small'/>
            </LoadingButton>
            <AzentaTable Orders={azentaOrders}/>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Azenta;
