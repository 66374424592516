import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import SeqViz from 'seqviz';
import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { Sequence } from 'src/models/sequence';
import { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import PublishIcon from '@mui/icons-material/Publish';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  p: 4
};

interface AIseOrderProps {
  onClose: () => void;
  open: boolean;
  sequences: Sequence[];
}

function AIseOrder(props: AIseOrderProps) {
  const { onClose, sequences, open } = props;

  const handleClose = () => {
    onClose();
  };

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [quantity, setQuantity] = useState("");
  const [formulation, setFormulation] = useState("");
  const [purification, setPurification] = useState("");

  const handleDownload = () => {
    let csv = "Sequence\n";
    sequences.forEach((sequence) => {
      csv += `${sequence.seq}\n`;
    });
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
    element.setAttribute('download', 'AIse_result.csv');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack spacing={2} direction="column" alignItems="center" sx={{ height: "100%" }}>
          <Typography variant="h4" >
            Create AIse order
          </Typography>
          <Typography variant="body1" >
            Click 'Submit' to place an order with AIse.
          </Typography>
          {/* <FormControl size="small" sx={{ minWidth: 300 }}>
            <TextField size="small" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 300 }}>
            <TextField size="small" label="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
          </FormControl>
          <FormControl size="small" sx={{ minWidth: 300 }}>
            <TextField size="small" label="Quantity (µM)" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
          </FormControl> */}
          {/* <FormControl size="small">
            <InputLabel id="formulation-label">Formulation</InputLabel>
            <Select
              label="Formulation"
              sx={{ minWidth: 300 }}
              value={formulation}
              onChange={(e) => setFormulation(e.target.value)}
            >
              {["None", "LabReady (100 µM in IDTE, pH 8.0)"].map((formulation) => {
                return <MenuItem key={formulation} value={formulation}>{formulation}</MenuItem>
              })}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ pb: 2 }}>
            <InputLabel id="purification-label">Purification</InputLabel>
            <Select
              label="Purification"
              sx={{ minWidth: 300 }}
              value={purification}
              onChange={(e) => setPurification(e.target.value)}
            >
              {["None", "Standard Desalting"].map((purification) => {
                return <MenuItem key={purification} value={purification}>{purification}</MenuItem>
              })}
            </Select>
          </FormControl> */}
          <Button
            variant="contained"
            // disabled={!name || !address || !quantity || !formulation || !purification}
            startIcon={<PublishIcon />}
            onClick={() => handleDownload()}
          >
            Submit
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
export default AIseOrder;
