import { Typography, Grid } from '@mui/material';
export function PageHeader() {
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            My resources
          </Typography>
          <Typography variant="subtitle2">
            See the resources enabled for your organization. Add your keys to the services if needed.
            If you don't see a resource you need, contact an admin of your organization.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}