import { useMemo } from 'react';
import { Box, Modal, Stack, Typography } from '@mui/material';
import { Sequence } from 'src/models/sequence';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  maxHeight: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 3,
  overflowY: 'scroll'
};

interface ComplexityScoreDetailsProps {
  open: boolean
  onClose: () => void
  sequence: Sequence
}

function ComplexityScoreDetails({ onClose, sequence, open }: ComplexityScoreDetailsProps) {

  const handleClose = () => {
    onClose();
  };

  const totalScore = useMemo(() => {
    return sequence.idtComplexGBlock.reduce((acc, item) => acc + item.score, 0).toFixed(2)
  }, [sequence])

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack direction="column" spacing={3} alignItems='center'>
          <Typography variant="h4" >
            Complexity Score details
          </Typography>
          {sequence.idtComplexGBlock.map((gblock, index) => {
            return (
              <Stack direction='column' spacing={1} key={index}>
                <Typography variant="body1" >
                  <b>Issue: </b>{gblock.name}
                </Typography>
                <Typography variant="body1" >
                  <b>Description: </b>{gblock.displayText}
                </Typography>
                <Typography variant="body1" >
                  <b>Score: </b>{gblock.score}
                </Typography>
              </Stack>
            );
          })}
          <Typography variant="h6" >
            Total Score: {totalScore}
          </Typography>
        </Stack>
      </Box>
    </Modal>
  );
}
export default ComplexityScoreDetails;
