import { Box, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px'
};

interface UpdateQuartzyInventoryProps {
  open: boolean;
  onClose: () => void;
}

function UpdateQuartzyInventory({ onClose, open }: UpdateQuartzyInventoryProps) {
  const [amount, setAmount] = useState<number | null>();
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [alreadyRun, setAlreadyRun] = useState(false);

  useEffect(() => {
    setAmount(null);
    setLocation("");
    setType("");
    setAlreadyRun(false);
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const updateInventory = async () => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setAlreadyRun(true);
    setLoading(false);
  }


  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack spacing={2} direction="column" alignItems="center" sx={{ height: "100%" }}>
          <Typography variant="h4" >
            Update Quartzy inventory
          </Typography>
          <Typography variant="body1" >
            Replenish your Quartzy inventory using Quartzy API.
          </Typography>
          <Stack spacing={2} direction="column" alignItems="center">
            <Typography variant="body1">
              Select location:
            </Typography>
            <FormControl size="small">
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                label="Location"
                sx={{ minWidth: 150 }}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                {["Lab1", "Lab2", "Basement"].map((location) => {
                  return <MenuItem key={location} value={location}>{location}</MenuItem>
                })}
              </Select>
            </FormControl>
            <Typography variant="body1">
              Select type:
            </Typography>
            <FormControl size="small">
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                label="Type"
                sx={{ minWidth: 150 }}
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                {["General supply", "Oligo or Primer", "Plasmid", "Solutions"].map((type) => {
                  return <MenuItem key={type} value={type}>{type}</MenuItem>
                })}
              </Select>
            </FormControl>
            <Typography variant="body1">
              Specify amount of items:
            </Typography>
            <FormControl size="small">
              <TextField
                size='small'
                label="Amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(parseInt(e.target.value))}
              />
            </FormControl>
          </Stack>
          {!alreadyRun &&
            <LoadingButton loading={loading} variant="contained" onClick={() => updateInventory()} disabled={!amount || !type || !amount}>
              Update
            </LoadingButton>
          }
          {alreadyRun &&
            <Typography variant="body1" color="success">
              Inventory successfully updated. You can close this window.
            </Typography>
          }
        </Stack>
      </Box>
    </Modal>
  );
}
export default UpdateQuartzyInventory;
