import { FC, ChangeEvent, useState, useEffect, useMemo } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  Stack,
  InputLabel,
  TextField,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Organization, Resource } from 'src/models/organization';
import { getAllResources } from 'src/api/ResourceQueries';
import { addAdmin, updateOrganizationEnabledResources } from 'src/api/OrganizationQueries';
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface OrganizationsTableProps {
  className?: string;
  organizations: Organization[];
  setOrganizations: (organization: Organization[]) => void;
}

const applyPagination = (
  organizations: Organization[],
  page: number,
  limit: number
): Organization[] => {
  return organizations.slice(page * limit, page * limit + limit);
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const OrganizationsTable: FC<OrganizationsTableProps> = ({ organizations, setOrganizations }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [allResources, setAllResources] = useState<Resource[]>([]);
  const [newAdminEmails, setNewAdminEmails] = useState<Record<string, string>>({});
  const [loadingNewAdminOrgId, setLoadingNewAdminOrgId] = useState<string | null>(null);


  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const fetchResources = async () => {
    const accessToken = await getAccessTokenSilently();
    const responseResources = await getAllResources(accessToken);
    if (responseResources) {
      setAllResources(responseResources);
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  const handleResourceChange = async (selectedOrganization, resourceNames) => {
    const findResources = allResources.filter((resource) => resourceNames.includes(resource.name));
    const oldOrganizations = [...organizations];
    const updatedOrganizations = organizations.map((organization) => {
      if (organization.id === selectedOrganization.id) {
        return { ...organization, enabledResources: findResources };
      }
      return organization;
    });
    setOrganizations(updatedOrganizations);

    const accessToken = await getAccessTokenSilently();
    const newOrgs = updateOrganizationEnabledResources(accessToken, selectedOrganization.id, findResources.map((role) => role.id));
    if (!newOrgs) {
      setOrganizations(oldOrganizations);
    }
  };

  const getAdmins = (organization: Organization) => {
    return organization.users
      .filter((user) => user.roles.some((role) => role.name === 'organization-admin'))
      .map((user) => user.email).join('\n');
  }

  const handleEmailChange = (organizationId: string, email: string) => {
    setNewAdminEmails({ ...newAdminEmails, [organizationId]: email });
  }

  const handleAddAdmin = async (organizationId: string) => {
    setLoadingNewAdminOrgId(organizationId);
    const newAdminEmail = newAdminEmails[organizationId];
    if (!newAdminEmail) {
      return;
    }
    const accessToken = await getAccessTokenSilently();
    await addAdmin(accessToken, organizationId, newAdminEmails[organizationId]);
    setLoadingNewAdminOrgId(null);
  }

  const paginatedOrganizations = applyPagination(
    organizations,
    page,
    limit
  );

  return (
    <Card>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Enabled resources</TableCell>
              <TableCell>Admins</TableCell>
              <TableCell>Assign admin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrganizations.map((org) => {
              return (
                <TableRow
                  hover
                  key={org.name}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {org.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <FormControl size='small'>
                      <Select
                        multiple
                        size='small'
                        value={org.enabledResources.map((resource) => resource.name)}
                        onChange={(e) => handleResourceChange(org, e.target.value)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {allResources.map((role) => (
                          <MenuItem
                            key={role.id}
                            value={role.name}
                          >
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                    >
                      {getAdmins(org)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <FormControl size='small'>
                        <TextField
                          size='small'
                          label="Email"
                          sx={{ width: '200px' }}
                          value={newAdminEmails[org.id] || ''}
                          onChange={(e) => handleEmailChange(org.id, e.target.value)}
                        />
                      </FormControl>
                      {loadingNewAdminOrgId !== org.id ?
                        <IconButton onClick={() => handleAddAdmin(org.id)}>
                          <AddCircleIcon />
                        </IconButton> :
                        <CircularProgress size="1rem" />
                      }
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={organizations.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default OrganizationsTable;
