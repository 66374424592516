import { Button, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { LoadingButton } from '@mui/lab';

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`
);

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);

function Hero() {
  const { logout } = useAuth0();
  const { initialized } = useContext(SidebarContext);

  return (
    <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
      <Grid
        spacing={{ xs: 6, md: 10 }}
        justifyContent="center"
        alignItems="center"
        container
      >
        <Grid item md={10} lg={10} mx="auto">
          <TypographyH1 sx={{ mb: 2 }} variant="h1">
            Lattice Dashboard
          </TypographyH1>
          <TypographyH2
            sx={{ lineHeight: 1.5, p: 2 }}
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            Seamlessly connect your biotechnology projects.
          </TypographyH2>
          <LoadingButton component={RouterLink} to="/sequences/" variant='contained' sx={{ m: 2 }} color="primary" size='large' loading={!initialized}>
            Get started
          </LoadingButton>
          <Button
            color="primary"
            variant='outlined'
            size='large'
            onClick={() => logout({ logoutParams: { returnTo: process.env.REACT_APP_FRONTEND_URI  } })}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
          <Typography variant="h3" sx={{ my: 4 }}>
            Integrated with
          </Typography>
          <Grid container spacing={3}>
            {["/static/images/companies/damplab-bw.png",
              "/static/images/companies/azenta-bw.png",
              "/static/images/companies/geneious-bw.png",
              "/static/images/companies/idt-bw.png",
              "/static/images/companies/kernel-bw.png",
              "/static/images/companies/benchling-bw.png",
              "/static/images/companies/olon_logo_pos-bw.png",
              "/static/images/companies/aclid-bw.png",
              "/static/images/companies/securedna-bw.png"].
              map(company => {
                return (
                  <Grid item md={3} key={company}>
                    <Stack direction='row' alignItems="center" sx={{ height: "100%" }}>
                      <img
                        src={company}
                        alt=""
                        height={40}
                        style={{ display: 'block', margin: 'auto' }}
                      />
                    </Stack>
                  </Grid>
                )
              })}
            <Grid item md={4}>
              <div style={{ paddingLeft: 40, paddingRight: 40 }}>
                <Stack direction='column' sx={{ border: '1px dashed', borderRadius: 12, p: 1 }}>
                  <Typography variant="body1">
                    Your brand here
                  </Typography>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Learn more
                  </Typography>
                </Stack>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Hero;
