import { useState } from 'react';

import { Box, Typography, Button, Grid } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';


function PageHeader() {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          DAMP Canvas Job Submissions
        </Typography>
        <Typography variant="subtitle2">
          Submit jobs and files to the DAMP Canvas.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
