import { Typography, Grid } from '@mui/material';

function PageHeader() {

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            Admin documents manager
          </Typography>
          <Typography variant="subtitle2">
            Review and approve or reject documents such as NDAs and MTAs for different services.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
