export const getAllResources = async (accessToken: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/resources/all`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};
