import { Typography, Grid } from '@mui/material';

function PageHeader() {

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            Roles and permissions
          </Typography>
          <Typography variant="subtitle2">
            See the roles and permissions in the system. See which users have which role.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
