import { useEffect, useState } from 'react';
import { Box, Modal, Stack, Typography } from '@mui/material';
import { Sequence } from 'src/models/sequence';
import { LoadingButton } from '@mui/lab';
import { runComplexityScore } from 'src/api/ComplexityScoreQueries';
import { useAuth0 } from '@auth0/auth0-react';


const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  p: 4
};

interface RunComplexityScoreProps {
  open: boolean
  onClose: () => void
  sequences: Array<Sequence>
}

function RunComplexityScore({ onClose, sequences, open }: RunComplexityScoreProps) {
  const [loading, setLoading] = useState(false);
  const [alreadyRunning, setAlreadyRunning] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const handleClose = () => {
    onClose();
  };

  const idtComplexityScores = async (allSequences: Sequence[]) => {
    setLoading(true);
    setAlreadyRunning(true);
    const accessToken = await getAccessTokenSilently();
    const sequenceIds = allSequences.map(sequence => sequence.id,);
    await runComplexityScore(accessToken, { sequenceIds });
    setLoading(false);
  };

  useEffect(() => {
    setAlreadyRunning(false);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack direction="column" spacing={3} alignItems='center'>
          <Typography variant="h4" >
            Run complexity score
          </Typography>
          <Typography variant="body1">
            Powered by IDT, the complexity score evaluates the manufacturability of a sequence by checking
            stuff like high GC content, problematic sequences, repeats, and more.
          </Typography>
          {!alreadyRunning &&
            <LoadingButton loading={loading} variant="contained"
              onClick={() => idtComplexityScores(sequences)}>
              Run
            </LoadingButton>
          }
          {alreadyRunning &&
            <Typography variant="body1" color="success">
              The complexity score calculation is running on the backround. You can close this window. Results will be showin in the sequence view.
            </Typography>
          }
        </Stack>
      </Box>
    </Modal>
  );
}
export default RunComplexityScore;
