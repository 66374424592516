import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Stack, TextField, FormControl, Select, MenuItem, InputLabel, Typography, Box } from '@mui/material';
import Footer from 'src/components/Footer';
import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Role } from 'src/models/user';
import { getAllRoles } from 'src/api/RoleQueries';
import RolesTable from './RolesTable';

function RolesPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState<string | undefined>();


  useEffect(() => {
    fetchRoles();
  }, [])

  const fetchRoles = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    let responseRoles = await getAllRoles(accessToken);
    if (responseRoles) {
      setRoles(responseRoles);
      setLoading(false);
    }
  }

  const allRoles = useMemo(() => {
    return roles.map((role) => role.name);
  }, [roles]);

  const selectedRolePermissions = useMemo(() => {
    if (role === undefined) {
      return [];
    }
    return roles.find((r) => r.name === role)?.permissions;
  }, [role, roles]);

  const selectedRoleUsers = useMemo(() => {
    if (role === undefined) {
      return [];
    }
    return roles.find((r) => r.name === role)?.users;
  }, [role, roles]);

  return (
    <>
      <Helmet>
        <title>Roles</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack direction='row' sx={{pb: 4}} justifyContent='center'>
              <FormControl size='small'>
                <InputLabel>Role</InputLabel>
                <Select
                  label='Role'
                  sx={{ minWidth: 120 }}
                  value={role}
                  onChange={(e) => setRole(e.target.value as string)}
                >
                  {allRoles.map((role, index) => (
                    <MenuItem key={index} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <RolesTable permissions={selectedRolePermissions} users={selectedRoleUsers} />
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}

export default RolesPage;
