import { FC, ChangeEvent, useState } from 'react';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
} from '@mui/material';

import { Sequence } from 'src/models/sequence';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import BulkActions from './BulkActions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SequenceViewer from 'src/components/Modals/SequenceViewer';
import DeleteConfirmation from 'src/components/Modals/SeqDeleteConfirmation';


interface SequencesTableProps {
  className?: string;
  sequences: Sequence[];
}

const applyPagination = (
  sequences: Sequence[],
  page: number,
  limit: number
): Sequence[] => {
  const shortSeqs: Sequence[] = sequences.filter((sequence) => sequence.seq.length <= 100 );
  return shortSeqs.slice(page * limit, page * limit + limit);
};

const SequencesTable: FC<SequencesTableProps> = ({ sequences }) => {
  const [selectedSequences, setSelectedSequences] = useState<Sequence[]>(
    []
  );
  const selectedBulkActions = selectedSequences.length == 2;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [openSeq, setOpenSeq] = useState(false);
  const [viewingSequence, setViewingSequence] = useState<Sequence | null>(null);
  const [delConfirm, setDelConfirm] = useState(false);
  const [deletingSequence, setDeletingSequence] = useState<Sequence | null>(null);
  
  const handleSeqClickOpen = (sequence: Sequence) => {
    setViewingSequence(sequence);
    setOpenSeq(true);
  };

  const handleSeqClose = () => {
    setOpenSeq(false);
  };

  const handleDelClickOpen = (sequence: Sequence) => {
    setDeletingSequence(sequence);
    setDelConfirm(true);
  }

  const handleDelClose = () => {
    setDelConfirm(false);
  }

  const handleSelectOneSequence = (
    event: ChangeEvent<HTMLInputElement>,
    sequenceId: string
  ): void => {
    const foundSequence = sequences.find((seq) => seq.id === sequenceId);
    const alreadySelectedOrder = selectedSequences.filter((order) => order.id === sequenceId);
    if (alreadySelectedOrder.length === 0) {
      setSelectedSequences([...selectedSequences, foundSequence]);
    } else {
      setSelectedSequences(selectedSequences.filter((order) => order.id !== sequenceId));
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedSequences = applyPagination(
    sequences,
    page,
    limit
  );

  const theme = useTheme();

  return (
    <Card>
      <Box flex={1} p={2}>
        <BulkActions selectedSequences={selectedSequences} selectedBulkActions={selectedBulkActions}/>
      </Box>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Sequence</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedSequences.map((seq) => {
              const isSequenceSelected = selectedSequences.filter((selected) => selected.id === seq.id).length > 0;
              return (
                <TableRow
                  hover
                  key={seq.id}
                  selected={isSequenceSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSequenceSelected}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleSelectOneSequence(event, seq.id)
                      }
                      value={isSequenceSelected}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {seq.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'uppercase' }}
                      gutterBottom
                      noWrap
                    >
                      {seq.type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                      sx={{ maxWidth: 1000, textOverflow: "ellipsis", textTransform: 'uppercase' }}
                    >
                      {seq.seq}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="View sequence" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.secondary.lighter },
                          color: theme.palette.secondary.main
                        }}
                        color="inherit"
                        size="small"
                        onClick={() => handleSeqClickOpen(seq)}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete sequence" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                        onClick={()=>handleDelClickOpen(seq)}
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={sequences.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
      {viewingSequence && <SequenceViewer open={openSeq} onClose={handleSeqClose} sequence={viewingSequence} />}
      {deletingSequence && <DeleteConfirmation open={delConfirm} onClose={handleDelClose} sequence={deletingSequence} />}
    </Card>
  );
};

export default SequencesTable;
