export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 4
};


// Company colors/themes
export const azentaButton = {
  m: 2,
  backgroundColor: '#826ec3',
  '&:hover': { backgroundColor: '#6348bd' }
}
