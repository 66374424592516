import { Box, FormControl, InputLabel, MenuItem, Modal, Select, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { createMultipleOptimizations } from 'src/api/OptimizationQueries';
import { useAuth0 } from '@auth0/auth0-react';
import { Sequence } from 'src/models/sequence';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px'
};
interface RunOptimizationProps {
  open: boolean
  onClose: () => void
  sequences: Array<Sequence>
}

function RunOptimization({ onClose, sequences, open }: RunOptimizationProps) {
  const [organism, setOrganism] = useState("");
  const [cellType, setCellType] = useState("");
  const [loading, setLoading] = useState(false);
  const [alreadyRun, setAlreadyRun] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setOrganism("");
    setCellType("");
    setAlreadyRun(false);
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const runOptimization = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    await createMultipleOptimizations(accessToken, {
      "email": "user",
      "sequences": sequences,
      "sequenceType": "dna",
      "date": new Date().toISOString(),
      "parameters": [
        {
          "weight": 0.5,
          "target": "min",
          "name": "NucleotideRepeats",
          "nucleotide": "T",
          "repeats": 12
        }
      ]
    })
    setAlreadyRun(true);
    setLoading(false);
  }


  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack spacing={2} direction="column" alignItems="center" sx={{ height: "100%" }}>
          <Typography variant="h4" >
            Run optimization
          </Typography>
          <Typography variant="body1" >
            Powered by University of Kent, this state of the art algorithm optimizes the sequence by
            selecting the best combination of codons that reduce manufacturability issues.
          </Typography>
          <Typography variant="body1" sx={{ pt: 4 }}>
            Select organism and application:
          </Typography>
          <Stack spacing={2} direction="row" alignItems="top">
            <FormControl size="small" sx={{ pb: 4 }}>
              <InputLabel id="organism-label">Organism</InputLabel>
              <Select
                label="Organism"
                sx={{ minWidth: 150 }}
                value={organism}
                onChange={(e) => setOrganism(e.target.value)}
              >
                {["Human"].map((organism) => {
                  return <MenuItem key={organism} value={organism}>{organism}</MenuItem>
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="cell-type-label">Cell type</InputLabel>
              <Select
                label="Cell type"
                sx={{ minWidth: 150 }}
                value={cellType}
                onChange={(e) => setCellType(e.target.value)}
              >
                {["HEK293", "Liver", "Lung", "unspecified"].map((cellType) => {
                  return <MenuItem key={cellType} value={cellType}>{cellType}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Stack>
          {!alreadyRun &&
            <LoadingButton loading={loading} variant="contained" onClick={() => runOptimization()} disabled={!organism || !cellType}>
              Run
            </LoadingButton>
          }
          {alreadyRun &&
            <Typography variant="body1" color="success">
              Optimization is running on the backround. You can close this window. Results will be showin in the sequence view.
            </Typography>
          }
        </Stack>
      </Box>
    </Modal>
  );
}
export default RunOptimization;
