import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import React from 'react';
import Auth0ProviderWithRedirectCallback from './components/Auth0/Auth0ProviderWithRedirectCallback';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Auth0ProviderWithRedirectCallback
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: process.env.REACT_APP_FRONTEND_URI,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "openid profile email offline_access"
          }}
          useRefreshTokens={true}
          useRefreshTokensFallback={true}
        >
          <SidebarProvider>
            <App />
          </SidebarProvider>
        </Auth0ProviderWithRedirectCallback>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

serviceWorker.unregister();
