import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Stack, FormControl, Select, MenuItem, InputLabel, Typography } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useMemo, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import OrdersTable from './OrdersTable';
import { fakeOrders, Order } from 'src/models/order';

function OrdersPage() {

  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>('Any');

  useEffect(() => {
    fetchOrders();
  }, [])

  const fetchOrders = async () => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setOrders(fakeOrders);
    setLoading(false);
  }

  const filteredOrders = useMemo(() => {
    const filteredLogs = orders.filter(order => {
      let statusMatch = true;
      if (status !== 'Any') {
        statusMatch = order.genscript.status === status;
      }
      return statusMatch;
    });
    return filteredLogs;
  }, [orders, status]);


  return (
    <>
      <Helmet>
        <title>Orders</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack direction='row' spacing={3} sx={{ mb: 1 }}>
              <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchOrders()}>
                <RefreshIcon fontSize='small' />
              </LoadingButton>
              <FormControl size='small'>
                <InputLabel>Order status</InputLabel>
                <Select
                  label='Order status'
                  sx={{ minWidth: 120 }}
                  value={status}
                  onChange={(e) => setStatus(e.target.value as string)}
                >
                  {['Any', "Order Created", "Order in Production", "Order Shipped", "Order Delivered"].map((status, index) => (
                    <MenuItem key={index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <OrdersTable filteredOrders={filteredOrders} allOrders={orders} setOrders={setOrders} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default OrdersPage;
