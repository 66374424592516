function Logo() {

  return (
    <img
      src="/static/images/logo/mpi-logo.png"
      alt="mpi"
      width={80}
    />
  );
}

export default Logo;
