import {
  Button,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ConstructData } from 'src/models/twist';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TwistQuoteModal from 'src/components/Modals/TwistQuote';

interface BulkActionsProps {
  refreshConstructData: (accessToken: string, constructId: string) => void;
  constructId: string;
  constructsData: ConstructData[];
}

function BulkActions({ refreshConstructData, constructId, constructsData }: BulkActionsProps) {
  const { getAccessTokenSilently } = useAuth0();

  const [refreshingConstructData, setRefreshingConstructData] = useState<boolean>(false);
  const [openRequestQuote, setOpenRequestQuote] = useState<boolean>(false);

  const handleRefreshConstructData = async () => {
    setRefreshingConstructData(true);
    const accessToken = await getAccessTokenSilently();
    await refreshConstructData(accessToken, constructId);
    setRefreshingConstructData(false);
  };

  const allConstructsAreBuildable = constructsData && constructsData.every((construct) => construct.score === 'BUILDABLE');

  return (
    <>
      <Stack direction='row' spacing={4} alignItems='center'>
        <Button
          variant="outlined"
          startIcon={<AttachMoneyIcon />}
          onClick={() => setOpenRequestQuote(true)}
          disabled={!allConstructsAreBuildable}
        >
          Request quote
        </Button>
      </Stack>
      {openRequestQuote && <TwistQuoteModal open={openRequestQuote} onClose={() => setOpenRequestQuote(false)} constructId={constructId} />}
    </>
  );
}

export default BulkActions;
