import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Footer from 'src/components/Footer';
import {
  Box,
  Typography,
  Card,
  Container,
  Grid,
  ListItem,
  List,
  Divider,
  Link,
  Stack,
  Button,
  TextField
} from '@mui/material';
import { useMemo, useState } from 'react';
import AccountDocuments from 'src/components/Modals/AccountDocuments';
import { useAuth0 } from '@auth0/auth0-react';

function LinkedAccounts() {
  const { user } = useAuth0();

  const [open, setOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");

  const handleDocumentsClick = (account) => {
    setSelectedAccount(account);
    setOpen(true);
  }

  const sectionsAndAccounts = useMemo(() => {
    const username = user?.name.split('@')[0];
    return {
      biosecurity: [
        { name: "SecureDNA", email: `${username}@securedna.com`, img: "/static/images/companies/securedna-bw.png" },
        { name: "Aclid", email: `${username}@aclid.com`, img: "/static/images/companies/aclid.png" },
      ],
      services: [
        { name: "Azenta", email: `${username}@azenta.com`, img: "/static/images/companies/azenta.png" },
        { name: "IDT", email: `${username}@idt.com`, img: "/static/images/companies/idt.png" },
        { name: "Olon", email: `${username}@olon.com`, img: "/static/images/companies/olon_logo_pos.png" },
        { name: "AIse", email: `${username}@AIse.com`, img: "/static/images/companies/AIse_blue.png" },
        { name: "Quartzy", email: `${username}@Quartzy.com`, img: "/static/images/companies/quartzy.png" },
      ],
      tools: [
        { name: "Benchling", email: `${username}@benchling.com`, img: "/static/images/companies/benchling.png" },
        { name: "Geneious", email: `${username}@geneious.com`, img: "/static/images/companies/geneious.png" },
        { name: "Kernel", email: `${username}@kernel.com`, img: "/static/images/companies/kernel.png" },
        { name: "Damp Lab", email: `${username}@damplab.com`, img: "/static/images/companies/damp-lab-logo-2x.png" },
      ]
    }
  }, [user]);

  // const icon_style = {
  //   width: '200px'
  // };

  // const link_style = {
  //   fontWeight: 'bold',
  //   lineHeight: 2
  // }

  return (
    <>
      <Helmet>
        <title>Linked accounts</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="start"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {Object.keys(sectionsAndAccounts).map((section) => (
                <Grid item xs={12} key={section}>
                  <Box pb={2}>
                    <Typography variant="h3">{section.charAt(0).toUpperCase() + section.slice(1)}</Typography>
                  </Box>
                  <Card>
                    <List>
                      {sectionsAndAccounts[section].map((account, index) => (
                        <>
                          <ListItem sx={{ p: 3 }}>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ width: "100%" }}>
                              <Stack direction='column' spacing={1}>
                                <img src={account.img} style={{ width: "200px" }} />
                                <Typography variant='subtitle1'>
                                  Connected to {account.email}
                                </Typography>
                              </Stack>
                              <Stack direction='row' spacing={2} alignItems='center'>
                                {account.name === "Benchling" && 
                                <TextField
                                  disabled
                                  size='small'
                                  sx={{ width: '300px' }}
                                  id="outlined-required"
                                  label="MPI Access Token"
                                  defaultValue="7g5eWieFDIOpKxsUtTcyxp6mTPaY"
                                />}
                                <Button variant='outlined' size='small' onClick={() => handleDocumentsClick(account.name)}>
                                  DOCUMENTS
                                </Button>
                                <Link sx={{ fontWeight: "bold", cursor: 'pointer' }}>
                                  UNLINK
                                </Link>
                              </Stack>

                            </Stack>
                          </ListItem>
                          {index < sectionsAndAccounts[section].length - 1 && <Divider component="li" />}
                        </>
                      ))}
                    </List>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <br/>
        <i>
          Note: The logos shown are indicative of the types of tools and services that can be added ot the MPI.
          Lattice does not formally represent any of these organizations.
        </i>
      </Container>
      <Footer />
      {open && <AccountDocuments onClose={() => setOpen(false)} account={selectedAccount} open={open} />}
    </>
  );
}

export default LinkedAccounts;
