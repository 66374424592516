import { useState, useEffect } from 'react';

import {
  Box,
  IconButton,
  Button,
  Typography,
  Link,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingButton } from '@mui/lab';

import { Sequence } from 'src/models/sequence';
import { exportJSONToExcel } from 'src/utils/exportJSONToExcel';

interface BulkActionsProps {
  selectedSequences: Sequence[];
  open: boolean;
  setOpen: (arg0: boolean) => void;
}

const style = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: '70%',
  height: '80%',
  p: 4,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px'
};


function NEBAnnealing({ open, setOpen, selectedSequences }: BulkActionsProps) {
  const [conc, setConc] = useState<string>('');
  const [prodcode, setProdcode] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [apiResult, setApiResult] = useState<any>(null);
  const [loading,    setLoading]    = useState(false);

  const onClose = (): void => {
    setOpen(false);
    setApiResult(null);
    setError(null);
  };

  const handleApiRequest = async () => {
    setLoading(true);
    try {
      const seqOne = selectedSequences[0]?.seq || '';
      const seqTwo = selectedSequences[1]?.seq || '';

      const url = new URL(`https://tmapi.neb.com/tm`);
      const queryParams = {
        seq1: seqOne,
        seq2: seqTwo,
        conc: conc,
        prodcode: prodcode,
        email: email,
      };

      Object.keys(queryParams).forEach((key) =>
        url.searchParams.append(key, queryParams[key])
      );

      const response = await fetch(url.toString());
      if (!response.ok) {
        const body = await response.text();
        throw new Error(
          `Request failed with error code: ${response.status}\nBody:\n${body}`
        );
      }
      const data = await response.json();
      setApiResult(data);
    } catch (error) {
      console.error('Error querying NEB API:', error);
      setApiResult({ error: 'An error occurred while making the API request.' });
    }
    setLoading(false);
  };

  const handleCopyToClipboard = (id: string) => {
    navigator.clipboard.writeText(id);
  };

  const handleCopyResults = () => {
    navigator.clipboard.writeText(JSON.stringify(apiResult));
  };

  useEffect(() => {
    const seqOne = selectedSequences[0]?.seq || '';
    const seqTwo = selectedSequences[1]?.seq || '';
    if (seqOne.length > 100 || seqTwo.length > 100) {
      setError('One or both sequences are longer than 100bp.');
    } else {
      setError(null);
    }
  }, [selectedSequences]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={style}
      >
        <Box sx={{textAlign: 'center', mb: 3}}>
          <Typography variant="h4" style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
            Run NEB Annealing Test
          </Typography>
          <br/>
          <Typography variant="body1" style={{ position: 'sticky', top: 40, background: 'white', zIndex: 1 }}>
            Use the <Link href='https://tmcalculator.neb.com/#!/main'>NEB Tm Calculator</Link> to estimate an appropriate annealing temperature when using NEB PCR products.
          </Typography>
        </Box>
        <Box display="flex" flex={1} overflow="hidden">
          <Box flex={2} pr={2} sx={{ overflowY: 'auto' }}>
            {/* Left column: Inputs */}
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              <b>Selected Sequences</b>
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Sequence</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedSequences.map((sequence) => (
                    <TableRow key={sequence.id}>
                      <TableCell>{sequence.name}
                        <Tooltip title={`Copy Sequence ID: ${sequence.id}`} arrow placement="right">
                          <IconButton
                            onClick={() => handleCopyToClipboard(sequence.id)}
                            size="small"
                            sx={{ml:1}}
                          >
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{sequence.seq}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TextField
              label="Concentration, e.g. 0.5"
              value={conc}
              onChange={(e) => setConc(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Production Code, e.g. q5-0"
              value={prodcode}
              onChange={(e) => setProdcode(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
            />
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </Box>
          <Box flex={1} pl={2} sx={{ overflowY: 'auto' }}>
            {/* Right column: Outputs */}
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              <b>NEB Annealing Temp Result</b>
            </Typography>
            {apiResult && (
              <Box mt={2}>
                {Object.keys(apiResult).map((key) => (
                  (key === 'data' || key === 'meta') ?
                  Object.keys(apiResult[key]).map((k) => (
                    <TextField
                      key={k}
                      label={k}
                      value={apiResult[key][k]}
                      fullWidth
                      margin="normal"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  )) :
                  <TextField
                    key={key}
                    label={key}
                    value={apiResult[key]}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

          <LoadingButton
            loading={loading}
            onClick={handleApiRequest}
            variant="contained"
            color="success"
            sx={{ m: 1 }}
            disabled={!!error}
          >
            Run Query
          </LoadingButton>

          <Button onClick={onClose} variant="contained" color="error" sx={{ m: 1 }}>
            Close
          </Button>

          <Button
            onClick={() => exportJSONToExcel(apiResult)}
            variant="contained"
            sx={{ m: 1 }}
            startIcon={<img src="/static/images/misc/excel.png" alt="excel" style={{height: "20px"}}/>}
          >
            Export to Excel
          </Button>

          <Button onClick={() => handleCopyResults()} variant="contained" sx={{ m: 1 }}
                  startIcon={<ContentCopyIcon/>}>
            Copy Results
          </Button>

        </Box>
      </Box>
    </Modal>
  );
}

export default NEBAnnealing;
