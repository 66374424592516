import { Typography, Grid } from '@mui/material';


function PageHeader() {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          NEB  Annealing
        </Typography>
        <Typography variant="subtitle2">
          Select two sequences (shorter than 100bp) to estimate the optimal annealing temperature for PCR with NEB polymerases.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageHeader;
