import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { Grid, Container, Button, Box, Typography, TextField } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Footer from 'src/components/Footer';
import { File } from 'src/api/DataQueries';

import { useAuth0 } from '@auth0/auth0-react';


function Canvas() {
  
  const [loading, setLoading] = useState(false);

  const [dampId,      setDampId]      = useState('');
  const [synBioHubId, setSynBioHubId] = useState('');

  // Experiment fields
  const [experimentType, setExperimentType] = useState('');

  // Job fields
  const [jobName,   setJobName]   = useState('');
  const [username,  setUsername]  = useState('');
  const [institute, setInstitute] = useState('');
  const [email,     setEmail]     = useState('');

  // File fields
  const [fileName, setFileName] = useState('');
  const [mimeType, setMimeType] = useState('');
  const [size,     setSize]     = useState(0);
  const [fileData, setFileData] = useState('');

  const { getAccessTokenSilently } = useAuth0();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      setMimeType(file.type);
      setSize(file.size);

      const reader = new FileReader();
      reader.onload = (e) => {
        setFileData(e.target?.result as string);
      };
      reader.readAsText(file); 
    }
  };

  const createDAMPCanvasJob = async () => {
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();

      const payload = {
        // dampId        : dampId,
        // synBioHubId   : synBioHubId,
        // experimentType: experimentType,
        // jobName       : jobName,
        // username      : username,
        // institute     : institute,
        // email         : email,
        filename      : fileName,
        // mimeType      : mimeType,
        // size          : size,
        data          : fileData,
      };
      console.log('PAYLOAD: ', payload);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/damp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, 
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (response.ok) {
        console.log('Job created successfully:', result);
        alert('Job successfully created!');  // TODO: Replace with proper alerts feature...
      } else {
        console.error('Error creating job:', result);
        alert('Error creating job.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to create job.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>DAMP Job Submission</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }}>
              <input type="file"
                onChange={handleFileChange} id={'filePicker'}
                style={{ display: 'none' }} />
              <label htmlFor={'filePicker'}>
                <Button 
                  component="span" 
                  variant='contained' 
                  sx={{ mt: { xs: 2, md: 0 }, mb: 1, width: 300 }}
                  startIcon={<FileUploadIcon fontSize="small" />}
                >
                  Upload File
                </Button>
              </label>
            </Box>
            {fileName}
            <Box
              width='300px'
            >
              <TextField
                sx={{ mt: 3, mb: 1 }}
                fullWidth
                label="DAMP ID"
                value={dampId}
                onChange={(e) => setDampId(e.target.value)}
              />
              <TextField
                sx={{ mt: 3, mb: 1 }}
                fullWidth
                label="SynBioHub ID"
                value={synBioHubId}
                onChange={(e) => setSynBioHubId(e.target.value)}
              />
              <TextField
                sx={{ mt: 3, mb: 1 }}
                fullWidth
                label="Experiment Type"
                value={experimentType}
                onChange={(e) => setExperimentType(e.target.value)}
              />
              <TextField
                sx={{ mt: 3, mb: 1 }}
                fullWidth
                label="Job Name"
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
              />
              <TextField
                sx={{ mt: 1, mb: 1 }}
                fullWidth
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                sx={{ mt: 1, mb: 1 }}
                fullWidth
                label="Institute"
                value={institute}
                onChange={(e) => setInstitute(e.target.value)}
              />
              <TextField
                sx={{ mt: 1, mb: 3 }}
                fullWidth
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              </Box>
            <LoadingButton loading={loading} variant='contained' color='success' sx={{ mb: 1, width: 300 }} onClick={() => createDAMPCanvasJob()}>
              Create Job in Canvas
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Canvas;
