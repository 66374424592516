import * as XLSX from 'xlsx';

const transposeObject = (obj: any) => {
    const result: any[] = [];
    Object.keys(obj).forEach(key => {
        result.push({ Property: key, Value: obj[key] });
    });
    return result;
};

export const exportToExcel = (order: any) => {

    // Transpose Order Details
    const transposedOrder = transposeObject(order);
    const orderDetailsSheet = XLSX.utils.json_to_sheet(transposedOrder);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, orderDetailsSheet, "Order Details");

    // Consolidate Pool Information
    const consolidatedPools = order.pools.map((pool: any, index: number) => ({
        poolIndex: index + 1,
        ...pool,
    }));
    const consolidatedPoolsSheet = XLSX.utils.json_to_sheet(consolidatedPools);
    XLSX.utils.book_append_sheet(workbook, consolidatedPoolsSheet, "Pool Information");

    // Consolidate Library Information
    const consolidatedLibraries: any[] = [];
    order.pools.forEach((pool: any, index: number) => {
        pool.libraries.forEach((library: any) => {
            consolidatedLibraries.push({
                poolIndex: index + 1,
                ...library,
            });
        });
    });
    const consolidatedLibrariesSheet = XLSX.utils.json_to_sheet(consolidatedLibraries);
    XLSX.utils.book_append_sheet(workbook, consolidatedLibrariesSheet, "Individual Library Information");

    XLSX.writeFile(workbook, "Azenta_Order.xlsx");
};
