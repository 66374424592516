export const createConstruct = async (accessToken: string, sequences: string[]) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/twist/create-construct`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sequences }),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getConstructScore = async (accessToken: string, constructId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/twist/get-construct-score/${constructId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const createQuote = async (accessToken: string, constructId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/twist/create-quote`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ constructId }),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getQuoteStatus = async (accessToken: string, quoteId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/twist/quote-status/${quoteId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const orderQuote = async (accessToken: string, quoteId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/twist/order-quote`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ quoteId }),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getOrders = async (accessToken: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/twist/orders/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};
