import { FC, ChangeEvent, useState } from 'react';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  Button,
  Stack,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Label from 'src/components/Label';
import { RequestLog } from 'src/models/logs';
import RequestLogDetails from 'src/components/Modals/RequestLogDetails';
import { Permission, Role, User } from 'src/models/user';

interface RolesTableProps {
  className?: string;
  permissions: Permission[];
  users: User[]
}

const RolesTable: FC<RolesTableProps> = ({ permissions, users }) => {

  return (
    <Stack direction='row' justifyContent='space-evenly'>
      <Card>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Permissions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions.map((permissions) => {
                return (
                  <TableRow
                    hover
                    key={permissions.id}
                  >
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {permissions.name}
                      </Typography>

                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Card>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User email</TableCell>
                <TableCell>User organization</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return (
                  <TableRow
                    hover
                    key={user.id}
                  >
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {user.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {user.organization ? user.organization.name : 'N/A'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

    </Stack>
  );
};

export default RolesTable;
