import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Stack, TextField, FormControl, Select, MenuItem, InputLabel, Typography } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useMemo, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import LogsTable from './LogsTable';
import { RequestLog } from 'src/models/logs';
import { getLogs } from 'src/api/LogsQueries';
import { useAuth0 } from '@auth0/auth0-react';

function LogsPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [logs, setLogs] = useState<RequestLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [user, setUser] = useState<string>('Any');
  const [method, setMethod] = useState<string>('Any');
  const [service, setService] = useState<string>('Any');
  const [allUsers, setAllUsers] = useState<string[]>([]);

  useEffect(() => {
    fetchLogs();
  }, [startDate, endDate])

  const fetchLogs = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const startDateWithoutTimeZone = new Date(startDate.split('T')[0]);
    const endDateWithoutTimeZone = new Date(endDate.split('T')[0]);
    // Add one day to the end date to include the end date in the search
    endDateWithoutTimeZone.setDate(endDateWithoutTimeZone.getDate() + 1);
    const responseLogs: RequestLog[] = await getLogs(accessToken, startDateWithoutTimeZone, endDateWithoutTimeZone);
    if (responseLogs) {
      setLogs(responseLogs);
      setLoading(false);
      const users = responseLogs.map(log => log.user.email);
      const uniqueUsers = Array.from(new Set(users));
      setAllUsers(uniqueUsers);
    }
  }

  // TODO: move filters to the backend
  const filteredLogs = useMemo(() => {
    const filteredLogs = logs.filter(log => {
      let userMatch = true;
      let methodMatch = true;
      let serviceMatch = true;
      if (user !== 'Any') {
        userMatch = log.user.email === user;
      }
      if (method !== 'Any') {
        methodMatch = log.method === method;
      }
      if (service !== 'Any') {
        switch (service) {
          case 'Sequences':
            serviceMatch = log.path.includes('sequences');
            break;
          case 'Aclid':
            serviceMatch = log.path.includes('aclid');
            break;
          case 'SecureDNA':
            serviceMatch = log.path.includes('biosecurity');
            break;
          case 'Kent Optimizer':
            serviceMatch = log.path.includes('optimization');
            break;
          case 'Azenta':
            serviceMatch = log.path.includes('azenta');
            break;
          case 'IDT':
            serviceMatch = log.path.includes('idt');
            break;
          default:
            serviceMatch = true;
        }
      }
      return userMatch && methodMatch && serviceMatch;
    });
    return filteredLogs;
  }, [logs, user, method, service]);


  return (
    <>
      <Helmet>
        <title>Activity tracker</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack direction='row' spacing={3} sx={{ mb: 1 }}>
              <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchLogs()}>
                <RefreshIcon fontSize='small' />
              </LoadingButton>
              <FormControl size='small'>
                <TextField
                  size='small'
                  label="Start date"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormControl>
              <FormControl size='small'>
                <TextField
                  size='small'
                  label="End date"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormControl>
              <FormControl size='small'>
                <InputLabel>User</InputLabel>
                <Select
                  label='User'
                  sx={{ minWidth: 120 }}
                  value={user}
                  onChange={(e) => setUser(e.target.value as string)}
                >
                  {/* TODO: get a list of all users from the backend. we don't have names yet */}
                  {['Any', ...allUsers].map((user, index) => (
                    <MenuItem key={index} value={user}>
                      {user}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size='small'>
                <InputLabel>Method</InputLabel>
                <Select
                  label='Method'
                  sx={{ minWidth: 120 }}
                  value={method}
                  onChange={(e) => setMethod(e.target.value as string)}
                >
                  {['Any', 'GET', 'POST', 'DELETE', 'PATCH'].map((method, index) => (
                    <MenuItem key={index} value={method}>
                      {method}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size='small'>
                <InputLabel>Service</InputLabel>
                <Select
                  label='Service'
                  sx={{ minWidth: 120 }}
                  value={service}
                  onChange={(e) => setService(e.target.value as string)}
                >
                  {['Any', 'Sequences', 'Aclid', 'SecureDNA', 'Kent Optimizer', 'Azenta', 'IDT'].map((service, index) => (
                    <MenuItem key={index} value={service}>
                      {service}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Typography variant='body1' sx={{ p: 2 }} textAlign='center'>
              <b>Number of requests: {filteredLogs.length}</b>
            </Typography>
            <LogsTable logs={filteredLogs} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default LogsPage;
