import { useState, useRef } from 'react';

import {
  Box,
  Menu,
  IconButton,
  Button,
  ListItemText,
  ListItem,
  List,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { Sequence } from 'src/models/sequence';

interface BulkActionsProps {
  selectedSequences: Sequence[];
  selectedBulkActions: boolean;
}

function BulkActions({ selectedSequences, selectedBulkActions }: BulkActionsProps) {
  const [onMenuOpen, menuOpen] = useState<boolean>(false);
  const moreRef = useRef<HTMLButtonElement | null>(null);
  const [showLibrary, setShowLibrary] = useState<boolean>(false);

  const openMenu = (): void => {
    menuOpen(true);
  };

  const closeMenu = (): void => {
    menuOpen(false);
  };

  const handleAddToLibrary = (): void => {
    selectedSequences.forEach((sequence) => {
      console.log('Adding sequence to library:', sequence);
    })
    setShowLibrary(true)
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" color="text.secondary">
            Start batch workflow:
          </Typography>
          <Button
            component={RouterLink}
            to="/sequences/cmo-workflow"
            state={{ initialSequences: selectedSequences }}
            variant="outlined"
            sx={{ ml: 3 }}
            startIcon={<PrecisionManufacturingIcon />}
            disabled={!selectedBulkActions}
          >
            CMO Order workflow
          </Button>
          <Button
            component={RouterLink}
            to="/sequences/golden-gate-workflow"
            state={{ initialSequences: selectedSequences }}
            variant="outlined"
            sx={{ ml: 3 }}
            startIcon={<AutoModeIcon />}
            disabled={!selectedBulkActions}
          >
            Golden Gate Order workflow
          </Button>
          <Button
            component={RouterLink}
            to="/sequences/twist-workflow"
            state={{ initialSequences: selectedSequences }}
            variant="outlined"
            sx={{ ml: 3 }}
            startIcon={<PrecisionManufacturingIcon />}
            disabled={!selectedBulkActions}
          >
            Twist Order workflow
          </Button>
        </Box>
        <IconButton
          color="primary"
          onClick={openMenu}
          ref={moreRef}
          sx={{ ml: 2, p: 1 }}
          disabled={!selectedBulkActions}
        >
          <MoreVertTwoToneIcon />
        </IconButton>
      </Box>

      <Menu
        keepMounted
        anchorEl={moreRef.current}
        open={onMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'center',
          
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <List sx={{ p: 1 }} component="nav">
        <ListItem component={RouterLink}
            to="/sequences/component-library"
            state={{ initialSequences: selectedSequences }} button>
          <ListItemText primary="Add to component library" />
        </ListItem>
          <ListItem button>
            <ListItemText primary="Bulk delete" />
          </ListItem>
        </List>
      </Menu>
    </>
  );
}

export default BulkActions;
