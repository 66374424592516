import { useState, useRef } from 'react';

import {
  Box,
  Menu,
  IconButton,
  ListItemText,
  ListItem,
  List,
  Typography,
  Stack,
} from '@mui/material';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { Order } from 'src/models/order';
import ImportToBenchling from 'src/components/Modals/ImportToBenchling';
import UpdateQuartzyInventory from 'src/components/Modals/UpdateQuartzyInventory';

interface BulkActionsProps {
  selectedOrders: Order[];
  selectedBulkActions: boolean;
  deleteSelected: () => void;
}

function BulkActions({ selectedOrders, deleteSelected, selectedBulkActions }: BulkActionsProps) {
  const [onMenuOpen, menuOpen] = useState<boolean>(false);
  const [importToBenchlingOpen, setImportToBenchlingOpen] = useState<boolean>(false);
  const [updateQuartyInventory, setUpdateQuartyInventory] = useState<boolean>(false);
  const moreRef = useRef<HTMLButtonElement | null>(null);

  const openMenu = (): void => {
    menuOpen(true);
  };

  const closeMenu = (): void => {
    menuOpen(false);
  };

  return (
    <>
      <Stack direction='row'>
        <Typography variant="h5" sx={{ p: 2 }} textAlign='center'>
          Bulk actions
        </Typography>
        <IconButton
          color="primary"
          onClick={openMenu}
          ref={moreRef}
          sx={{ ml: 2, p: 1 }}
          disabled={!selectedBulkActions}
        >
          <MoreVertTwoToneIcon />
        </IconButton>
      </Stack>

      <Menu
        keepMounted
        anchorEl={moreRef.current}
        open={onMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <List sx={{ p: 1 }} component="nav">
          <ListItem button onClick={() => setImportToBenchlingOpen(true)}>
            <ListItemText primary="Import to Benchling" />
          </ListItem>
          <ListItem button onClick={() => setUpdateQuartyInventory(true)}>
            <ListItemText primary="Update Quartzy inventory" />
          </ListItem>
          <ListItem button>
            <ListItemText primary="Delete" onClick={() => deleteSelected()} />
          </ListItem>
        </List>
      </Menu>
      {importToBenchlingOpen && <ImportToBenchling open={importToBenchlingOpen} onClose={() => setImportToBenchlingOpen(false)} />}
      {updateQuartyInventory && <UpdateQuartzyInventory open={updateQuartyInventory} onClose={() => setUpdateQuartyInventory(false)} />}
    </>
  );
}

export default BulkActions;
