import { AzentaSeqOrder } from "src/models/azenta";

export const getAllAzentaOrders = async (accessToken: string): Promise<AzentaSeqOrder[] | undefined> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/azenta/seqOrder`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    }
    const message = await data["message"];
    console.log(message);
  } catch (e) {
    console.log(e);
  }
};

export const createAzentaOrder = async (
  accessToken: string,
  azentaOrder: AzentaSeqOrder
): Promise<AzentaSeqOrder | undefined> => {
  try {
    const { id, ...orderData } = azentaOrder;
    const response = await fetch(`${process.env.REACT_APP_API_URL}/azenta/seqOrder`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(orderData),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateAzentaOrder = async (
  accessToken: string,
  azentaOrderId: string,
  azentaOrder: Partial<AzentaSeqOrder>
) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/azenta/seqOrder/${azentaOrderId}`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(azentaOrder),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteAzentaOrder = async (accessToken: string, azentaOrderId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/azenta/seqOrder/${azentaOrderId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};
