import { FC, ChangeEvent, useState } from 'react';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Label from 'src/components/Label';
import { RequestLog } from 'src/models/logs';
import RequestLogDetails from 'src/components/Modals/RequestLogDetails';

interface LogsTableProps {
  className?: string;
  logs: RequestLog[];
}

  // TODO: move pagination to the backend
const applyPagination = (
  logs: RequestLog[],
  page: number,
  limit: number
): RequestLog[] => {
  return logs.slice(page * limit, page * limit + limit);
};

const getStatusCodeLabel = (status): JSX.Element => {
  switch (status) {
    case 200:
    case 201:
    case 202:
    case 204:
      return <Label color='success'>{status}</Label>;
    case 400:
    case 401:
    case 403:
    case 500:
      return <Label color='error'>{status}</Label>;
    default:
      return <Label>{status}</Label>;
  }
};

const ScreeningsTable: FC<LogsTableProps> = ({ logs }) => {
  const theme = useTheme();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [open, setOpen] = useState(false);
  const [viewingLog, setViewingLog] = useState<RequestLog | null>(null);

  const handleClickOpen = (log: RequestLog) => {
    setViewingLog(log);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedLogs = applyPagination(
    logs,
    page,
    limit
  );

  return (
    <Card>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Path</TableCell>
              <TableCell>Method</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status code</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedLogs.map((log) => {
              return (
                <TableRow
                  hover
                  key={log.id}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ maxWidth: 150, textOverflow: "ellipsis" }}
                      gutterBottom
                      noWrap
                    >
                      {log.user.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {log.path}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {log.method}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {new Date(log.timestamp).toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {getStatusCodeLabel(log.statusCode)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="View details" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.secondary.lighter },
                          color: theme.palette.secondary.main
                        }}
                        color="inherit"
                        size="small"
                        onClick={() => handleClickOpen(log)}
                      >
                        <VisibilityIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={logs.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 30]}
        />
      </Box>
      {open && <RequestLogDetails open={open} onClose={handleClose} log={viewingLog} />}
    </Card>
  );
};

export default ScreeningsTable;
