// TODO: add types
export const runBiosecurity = async (accessToken: string, input) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/biosecurity`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(input),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getAllGenomes = async (accessToken: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/biosecurity/genomes`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateGenome = async (accessToken: string, id: string, status: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/biosecurity/genomes`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ id: id, adminStatus: status }),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};
