import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { useAuth0 } from '@auth0/auth0-react';
import { Resource, ResourceKey } from 'src/models/organization';
import { getMyOrganization } from 'src/api/OrganizationQueries';
import ResourcesTable from './ResourcesTable';
import { PageHeader } from './PageHeader';

function OrganizationResourcesPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [myResources, setMyResources] = useState<Resource[]>([]);
  const [resourceKeys, setResourceKeys] = useState<ResourceKey[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOrganization();
  }, [])

  const fetchOrganization = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const responseOrganization = await getMyOrganization(accessToken);
    if (responseOrganization) {
      setMyResources(responseOrganization.enabledResources);
      setResourceKeys(responseOrganization.resourceKeys);
      setLoading(false);
    }
  }


  return (
    <>
      <Helmet>
        <title>Organization resources</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchOrganization()}>
              <RefreshIcon fontSize='small'/>
            </LoadingButton>
            <ResourcesTable resources={myResources} resourceKeys={resourceKeys} forOrganizations/>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default OrganizationResourcesPage;
