import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Button } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';

import SequencesTable from './SequencesTable';
import { useEffect, useState } from 'react';
import { Sequence } from 'src/models/sequence';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { DataType, File, getAllData } from 'src/api/DataQueries';
import { useAuth0 } from '@auth0/auth0-react';


function AllSequences() {
  const [sequences, setSequences] = useState<Sequence[]>([]);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const response = await getAllData(accessToken);
    if (response) {
      const toSequences = response.map((data) => {
        const file = data.attributes as File 
        switch (data.type) {
          case DataType.File:
            return ({
              name: file.filename,
              type: "File",
              seq: "",
              annotations: []
            }) as Sequence
          case DataType.Sequence:
            return data.attributes as Sequence
          default:
            throw new Error(`Data type ${data.type} is not supported`)
        }
      })

      setSequences(toSequences)
      setLoading(false);
    }

    console.log(response)
  }

  return (
    <>
      <Helmet>
        <title>Sequences</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchData()}>
              <RefreshIcon fontSize='small' />
            </LoadingButton>
            <SequencesTable sequences={sequences} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default AllSequences;
