import React, { useState } from 'react';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchIcon from '@mui/icons-material/Search';
import seqparse, { parseFile } from "seqparse";
import { Sequence } from 'src/models/sequence';
import { createData, DataType } from 'src/api/DataQueries';
import { useAuth0 } from '@auth0/auth0-react';


const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '70%',
    minHeight: '650px',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: '16px',
    padding: 4
};

interface SeqImporterProps {
    open: boolean;
    onClose: () => void;
}


const SeqImporter = ({ open, onClose }: SeqImporterProps) => {
    const { getAccessTokenSilently } = useAuth0();

    const [sequences, setSequences] = useState<Sequence[]>([]);
    const [currentSeqIndex, setCurrentSeqIndex] = useState<number>(0);
    const [fileName, setFileName] = useState('');
    const [accessionNumber, setAccessionNumber] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFileName(file.name);
            const reader = new FileReader();
            reader.onload = async (e) => {
                const fileContent = e.target?.result as string;
                try {
                    const parsedSequences = parseFile(fileContent);
                    setSequences(parsedSequences);
                    setCurrentSeqIndex(0);
                } catch (error) {
                    console.error("Error parsing sequences:", error);
                    setMessage("Error parsing sequences");
                }
            };
            reader.readAsText(file);
        }
    };

    const handleFolderUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const allSequences: any[] = []; // Adjust type based on sequence structure
            setMessage(""); // Clear any previous messages
            try {
                for (const file of Array.from(files)) {
                    const reader = new FileReader();
                    const fileContent = await new Promise<string>((resolve, reject) => {
                        reader.onload = (e) => {
                            if (e.target?.result) {
                                resolve(e.target.result as string);
                            } else {
                                reject(new Error("File could not be read"));
                            }
                        };
                        reader.onerror = () => reject(reader.error);
                        reader.readAsText(file);
                    });
    
                    const parsedSequences = parseFile(fileContent);
                    allSequences.push(...parsedSequences);
                }
                setSequences(allSequences);
                setCurrentSeqIndex(0);
            } catch (error) {
                console.error("Error parsing folder:", error);
                setMessage("Error parsing folder or sequences");
            }
        }
    };
    

    // e.g. fetch and parse a sequence ("MN623123.1") from NCBI: https://www.ncbi.nlm.nih.gov/nuccore/MN623123.1
    const handleFetch = async () => {
        try {
            const parsedSeq = await seqparse(accessionNumber);
            const transformedSeq: Sequence = {
                id: '',
                name: parsedSeq.name,
                type: parsedSeq.type,
                seq: parsedSeq.seq,
                annotations: parsedSeq.annotations
            };
            setSequences([transformedSeq]);
            setCurrentSeqIndex(0);
        } catch (error) {
            console.error("Error fetching sequence:", error);
        }
    };

    const handleNext = () => {
        if (currentSeqIndex < sequences.length - 1) {
            setCurrentSeqIndex(currentSeqIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentSeqIndex > 0) {
            setCurrentSeqIndex(currentSeqIndex - 1);
        }
    };

    const handleClose = () => {
        setFileName('');
        setSequences([]);
        setCurrentSeqIndex(0);
        setMessage('');
        onClose();
    };

    const handleImport = async () => {
        setLoading(true);
        try {
            const accessToken = await getAccessTokenSilently();
            let allSucceeded = true;
            for (const seq of sequences) {
                // const result = await createSequence(seq);
                const result = await createData(accessToken, { type: DataType.Sequence, attributes: seq })
                if (!result) {  // Should only return data if response.ok (i.e. query was successful)
                    allSucceeded = false;
                    setMessage("Error importing sequence");
                    break;
                }
            }
            if (allSucceeded) {
                setMessage(`Sequence${sequences.length === 1 ? '' : 's'} imported successfully`);
            }
        } catch (error) {
            console.error("Error importing sequence:", error);
            setMessage("Error importing sequence");
        } finally {
            setLoading(false);
        }
    };

    const currentSeq = sequences[currentSeqIndex];

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Stack direction="column" spacing={3} alignItems='center'>
                    <Typography variant="h4">
                        Import Sequences
                    </Typography>
                    <Typography variant="body1">
                        Parse a file (e.g. .gb) with one or more sequences or provide an accession number to fetch a sequence from NCBI.
                    </Typography>
                    <Box sx={{ alignItems: 'left', width: '90%' }}>
                        <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }}>
                            <input type="file" accept=".seq,.gb,.gbk,.genbank,.ape,.fasta,.fas,.fa,.dna,.sbd"
                                onChange={handleFileChange} id={'seqFilePicker'}
                                style={{ display: 'none' }} />
                            <label htmlFor={'seqFilePicker'}>
                                <Button component="span" variant='contained'
                                    sx={{ m: 1, width: 130 }}
                                    startIcon={<FileUploadIcon fontSize='small' />}
                                >
                                    Browse File...
                                </Button>
                            </label>
                            <input 
                                type="file" 
                                {...{ webkitdirectory: "true" }}
                                multiple 
                                onChange={handleFolderUpload} 
                                id={'seqFolderPicker'}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor={'seqFolderPicker'}>
                                <Button component="span" variant='contained'
                                    sx={{ m: 1, width: 130 }}
                                    startIcon={<FileUploadIcon fontSize='small' />}
                                >
                                    Browse Folder...
                                </Button>
                            </label>
                            {fileName && <Typography variant="body2">{fileName}</Typography>}
                        </Box>
                        <Box>
                            <Button onClick={handleFetch} variant='contained'
                                sx={{ m: 1, width: 130 }}
                                startIcon={<SearchIcon fontSize='small' />}
                            >
                                Fetch...
                            </Button>
                            <input type="text" value={accessionNumber}
                                onChange={(e) => setAccessionNumber(e.target.value)} placeholder="Accession value..." />
                        </Box>
                        <Typography sx={{ fontSize: 16, width: '90%', margin: 2, height: '20%', overflowX: 'auto' }}>
                            {currentSeq ? (
                                <Box>
                                    <pre>Name: {currentSeq.name}</pre>
                                    <pre>Type: {currentSeq.type}</pre>
                                    <pre>Sequence: {currentSeq.seq}</pre>
                                    <pre>Annotations: {JSON.stringify(currentSeq.annotations)}</pre>
                                </Box>
                            ) : (
                                <Box>
                                    <pre>Name: </pre>
                                    <pre>Type: </pre>
                                    <pre>Sequence: </pre>
                                    <pre>Annotations: </pre>
                                </Box>
                            )}
                        </Typography>
                        <Box>
                            <Button onClick={handlePrevious} disabled={currentSeqIndex === 0} variant="contained">
                                Previous
                            </Button>
                            <Button onClick={handleNext} disabled={currentSeqIndex >= sequences.length - 1} variant="contained">
                                Next
                            </Button>
                            <LoadingButton loading={loading} onClick={handleImport}
                                disabled={sequences.length === 0} sx={{ ml: '15%' }}
                                variant='contained' color='success' >
                                {sequences.length < 2 ? 'Import' : 'Import All'}
                            </LoadingButton>
                            <Button onClick={() => handleClose()}
                                variant='contained' color='error'
                                sx={{ ml: '15%' }}
                            >
                                Close
                            </Button>
                        </Box>
                        {message &&
                            <Typography sx={{ color: message.startsWith('Error') ? 'red' : 'green', mt: 5, textAlign: 'center' }}>
                                {message}
                            </Typography>}
                    </Box>
                </Stack>
            </Box>
        </Modal>
    );
};

export default SeqImporter;
