import { Typography, Grid } from '@mui/material';

function PageHeader() {

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            Twist orders
          </Typography>
          <Typography variant="subtitle2">
            Review here the stauts of the orders created with the Twist order workflow
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default PageHeader;
