import React, { useState } from 'react';
import { Button, Typography, CircularProgress, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const ExcelUploader = () => {
  const [file1, setFile1] = useState<File | null>(null);
  const [file2, setFile2] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleFile1Change = (event) => {
    setFile1(event.target.files[0]);
    setError(null);
    setSuccess(null);
  };

  const handleFile2Change = (event) => {
    setFile2(event.target.files[0]);
    setError(null);
    setSuccess(null);
  };

  const handleUpload = async () => {
    if (!file1 || !file2) {
      setError('Please select both files before uploading.');
      return;
    }

    setUploading(true);
    setError(null);
    setSuccess(null);

    const formData = new FormData();
    formData.append('excel', file1);
    formData.append('asmb_files', file2);

    try {
      const response = await fetch('http://127.0.0.1:5000/api/assemble', {
        method: 'POST',
        body: formData,
      });
      const blob = await response.blob();

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'assembled_constructs.zip');
      document.body.appendChild(link);
      link.click();
      link.remove();

      setSuccess('Files uploaded successfully.');
    } catch (error) {
      setError('Failed to upload files.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>

      <Button variant="contained" component="label" sx={{ mb: 2 }}>
        Choose Assembly Schema (excel sheet)
        <input type="file" hidden onChange={handleFile1Change} accept=".xlsx, .xls" />
      </Button>
      {file1 && (
        <Typography variant="body1" gutterBottom>
          Selected file 1: {file1.name}
        </Typography>
      )}

      <Button variant="contained" component="label" sx={{ mb: 2 }}>
        Choose DNA Components (zip file)
        <input type="file" hidden onChange={handleFile2Change} accept=".zip" />
      </Button>
      {file2 && (
        <Typography variant="body1" gutterBottom>
          Selected file 2: {file2.name}
        </Typography>
      )}

      <LoadingButton
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={uploading || !file1 || !file2}
        loading={uploading}
        sx={{ mt: 2 }}
      >
      Upload Files
      </LoadingButton>

      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {success && (
        <Typography variant="body2" color="success.main" sx={{ mt: 2 }}>
          {success}
        </Typography>
      )}
    </Box>
  );
};

export default ExcelUploader;


