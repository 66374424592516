import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { createMultipleOptimizations } from 'src/api/OptimizationQueries';
import { AccountDocument, AccountDocumentStatus } from 'src/models/accountDocument';
import AccountDocumentUpload from './AccountDocumentUpload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Label from '../Label';
import { deleteAccountDocument, getAccountDocuments } from 'src/api/AccountDocumentQueries';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth0 } from '@auth0/auth0-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px'
};

interface AccountDocumentsProps {
  onClose: () => void;
  account: string;
  open: boolean;
}

const getStatusColor = (status: AccountDocumentStatus): string => {
  switch (status) {
    case 'APPROVED':
      return 'green';
    case 'REJECTED':
      return 'red';
    case 'PENDING':
      return 'orange';
  }
};

function AccountDocuments({ onClose, account, open }: AccountDocumentsProps) {
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState<AccountDocument[]>([]);
  const [openUpload, setOpenUpload] = useState(false);

  useEffect(() => {
    getDocumentsForAccount();
  }, []);

  const getDocumentsForAccount = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const response = await getAccountDocuments(accessToken, account);
    if (response) {
      setDocuments(response);
      setLoading(false);
    }
  }

  const deleteDocument = async (documentId: string) => {
    const accessToken = await getAccessTokenSilently();
    deleteAccountDocument(accessToken, documentId);
    const newDocuments = documents.filter(document => document.id !== documentId);
    setDocuments(newDocuments);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={style}>
        <Stack spacing={3} direction="column" alignItems="center" sx={{ height: "100%" }}>
          <Typography variant="h4" >
            View or upload documents for {account}
          </Typography>
          <Typography variant="body1" >
            Absence or rejection of documents may result in suspension of some of the services of the account.
          </Typography>
          {loading ?
            <CircularProgress />
            :
            <>
              <Grid container spacing={3}>
                {documents.map((document) => (
                  <Grid item xs={4} key={document.id}>
                    <Stack
                      sx={{ width: '100%', border: '1px solid #000', p: 1, borderRadius: '8px' }}
                      direction='row'
                      spacing={2}
                      justifyContent='space-between'
                      alignItems='center'>
                      <PictureAsPdfIcon fontSize='large' />
                      <Stack direction='column' spacing={0}>
                        <Typography variant='subtitle1' fontWeight='bold'>
                          {document.name}
                        </Typography>
                        <Typography variant='caption' color={getStatusColor(document.status)}>
                          {document.type} - {document.status}
                        </Typography>
                      </Stack>
                      <IconButton aria-label="delete" onClick={() => deleteDocument(document.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  </Grid>

                ))}
              </Grid>
              <Button variant='contained' onClick={() => setOpenUpload(true)}>
                Upload new document
              </Button>
            </>
          }
        </Stack>
        {openUpload && <AccountDocumentUpload onClose={() => setOpenUpload(false)} account={account} documents={documents} setDocuments={setDocuments} open={openUpload} />}
      </Box>
    </Modal>
  );
}
export default AccountDocuments;
