import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { AccountDocument, AccountDocumentType } from 'src/models/accountDocument';
import { uploadAccountDocument } from 'src/api/AccountDocumentQueries';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px'
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface AccountDocumentUploadProps {
  onClose: () => void;
  account: string;
  documents: AccountDocument[];
  setDocuments: (documents) => void;
  open: boolean;

}

function AccountDocuments({ onClose, documents, setDocuments, account, open }: AccountDocumentUploadProps) {
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState<AccountDocumentType | undefined>()
  const [name, setName] = useState("");
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const document = await uploadAccountDocument(accessToken, account, name, type);
    if (document) {
      const newDocuments = [...documents, document];
      setDocuments(newDocuments);
      onClose();
    }
    setLoading(false);
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={style}>
        <Stack spacing={2} direction="column" alignItems="center" sx={{ height: "100%" }}>
          <Typography variant="h4" >
            Upload a document
          </Typography>
          <FormControl size="small" sx={{ minWidth: 300 }}>
            <TextField size="small" label="Name" value={name} onChange={(e) => setName(e.target.value)} />
          </FormControl>
          <FormControl size="small" sx={{ pb: 2 }}>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              size='small'
              label="Type"
              sx={{ minWidth: 300 }}
              value={type}
              onChange={(e) => setType(e.target.value as AccountDocumentType)}
            >
              {["NDA", "MTA", "OTHER"].map((type) => {
                return <MenuItem key={type} value={type}>{type}</MenuItem>
              })}
            </Select>
          </FormControl>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Select file
              <VisuallyHiddenInput type="file" onChange={handleFileChange} accept=".pdf" />
            </Button>
            {file && <Typography>{file.name}</Typography>}
          </Stack>
          <LoadingButton
            loading={loading}
            variant='contained'
            disabled={!file || !name || !type} onClick={handleUpload}>
            Upload
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}
export default AccountDocuments;
