import React, { useState } from 'react';

import { Box, Button, Modal, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Sequence } from 'src/models/sequence';
import { deleteSequence } from 'src/api/SequencesQueries';
import { useAuth0 } from '@auth0/auth0-react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '55%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    borderRadius: '16px',
    padding: 4
};

interface DeleteConfirmationProps {
    open: boolean;
    onClose: () => void;
    sequence: Sequence;
}


function DeleteConfirmation({ open, onClose, sequence }: DeleteConfirmationProps) {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const { getAccessTokenSilently } = useAuth0();

    const handleDel = async () => {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();
        const result = await deleteSequence(accessToken, sequence.id);
        if (!result) {  // Should only return data if response.ok (i.e. query was successful)
            setMessage("Error deleting sequence");
        }
        if (!message) {
            setMessage(`Sequence deleted successfully`);
        }
        setLoading(false);
    };

    const handleClose = () => {
        setMessage('');
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style}>
                <Stack direction="column" spacing={3} alignItems='center'>

                    <Typography variant="h4" >
                        Sequence Deletion Confirmation
                    </Typography>
                    <Typography variant="body1" >
                        Are you sure you want to delete this sequence?
                    </Typography>
                    <Typography sx={{ fontSize: 16, width: '90%', margin: 2, height: '90%', overflowX: 'auto' }}>
                        <pre>Name: {sequence?.name ?? ''}</pre>
                        <pre>Type: {sequence?.type ?? ''}</pre>
                        <pre>Sequence: {sequence?.seq ?? ''}</pre>
                        <pre>Annotations: {JSON.stringify(sequence?.annotations ?? '')}</pre>
                    </Typography>
                    <Box sx={{ display: 'flex', direction: 'row' }}>
                        <LoadingButton loading={loading} onClick={() => handleDel()} variant='contained' color='error' sx={{ m: 2 }}>
                            Yes
                        </LoadingButton>
                        <Button onClick={() => handleClose()} variant='contained' sx={{ m: 2 }}>
                            No
                        </Button>
                    </Box>
                    {message &&
                        <Typography sx={{ color: message.startsWith('Error') ? 'red' : 'green', mt: 5, textAlign: 'center' }}>
                            {message}
                        </Typography>
                    }
                </Stack>
            </Box>
        </Modal>
    );
}

export default DeleteConfirmation;
