import { Box, FormControl, InputLabel, MenuItem, Modal, Select, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px'
};

interface ImportToBenchlingProps {
  open: boolean;
  onClose: () => void;
}

function ImportToBenchling({ onClose, open }: ImportToBenchlingProps) {
  const [schema, setSchema] = useState("");
  const [loading, setLoading] = useState(false);
  const [alreadyRun, setAlreadyRun] = useState(false);

  useEffect(() => {
    setSchema("");
    setAlreadyRun(false);
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const importToBenchling = async () => {
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 1000));
    setAlreadyRun(true);
    setLoading(false);
  }


  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack spacing={2} direction="column" alignItems="center" sx={{ height: "100%" }}>
          <Typography variant="h4" >
            Import to Benchling
          </Typography>
          <Typography variant="body1" >
            Import your entities into Benchling using Benchling API.
          </Typography>
          <Typography variant="body1" sx={{ pt: 4 }}>
            Select Benchling's schema:
          </Typography>
          <Stack spacing={2} direction="row" alignItems="top">
            <FormControl size="small" sx={{ pb: 4 }}>
              <InputLabel id="schema-label">Schema</InputLabel>
              <Select
                label="schema"
                sx={{ minWidth: 150 }}
                value={schema}
                onChange={(e) => setSchema(e.target.value)}
              >
                {["Plasmid", "Oligo", "Gene", "Protein", "Antibody"].map((schema) => {
                  return <MenuItem key={schema} value={schema}>{schema}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Stack>
          {!alreadyRun &&
            <LoadingButton loading={loading} variant="contained" onClick={() => importToBenchling()} disabled={!schema}>
              Import
            </LoadingButton>
          }
          {alreadyRun &&
            <Typography variant="body1" color="success">
              Entities successfully imported. You can close this window.
            </Typography>
          }
        </Stack>
      </Box>
    </Modal>
  );
}
export default ImportToBenchling;
