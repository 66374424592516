import {
  Box,
  Button,
  Typography,
  Modal,
  CircularProgress,
  TextField
} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
};

interface RunBLASTProps {
  isModalOpen: boolean;
  closeModal: () => void;
  loading: boolean;
  blastResult: string;
}

function RunBLAST ({isModalOpen, closeModal, loading, blastResult}: RunBLASTProps) {

  const downloadTextFile = () => {
    const element = document.createElement('a');
    const file = new Blob([blastResult], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = 'blast_results.txt';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <Modal open={isModalOpen} onClose={closeModal}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          BLAST Query Results
        </Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '80%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <TextField
            multiline
            fullWidth
            rows={20}
            value={blastResult}
            InputProps={{
              readOnly: true,
              sx: { fontFamily: 'Courier New, monospace' }
            }}
            variant="outlined"
            sx={{ mt: 2 }}
          />
        )}
        <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
          <Button onClick={downloadTextFile} variant="contained" color="primary" sx={{ mr: 2 }}>
            Download Result
          </Button>
          <Button onClick={closeModal} variant="contained">
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default RunBLAST;