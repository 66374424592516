export const screenSequence = async (
  accessToken: string,
  screeningName: string,
  sequenceName: string,
  sequence: string
) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/aclid/screen`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        submissionName: screeningName,
        sequences: [
          {
            name: sequenceName,
            sequence,
          },
        ],
      }),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getAllScreenings = async (accessToken: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/aclid/screens`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getScreeningDetails = async (accessToken: string, id: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/aclid/screens/${id}/details`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};
