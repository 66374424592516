import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Stack, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useMemo, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { AccountDocument, AccountDocumentStatus } from 'src/models/accountDocument';
import { getAllAccountDocuments, updateAccountDocumentStatus } from 'src/api/AccountDocumentQueries';
import DocumentsTable from './DocumentsTable';
import { useAuth0 } from '@auth0/auth0-react';

function DocumentsPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [documents, setDocuments] = useState<AccountDocument[]>([]);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<string>('Any');
  const [type, setType] = useState<string>('Any');
  const [account, setAccount] = useState<string>('Any');

  useEffect(() => {
    fetchDocuments();
  }, [])

  const fetchDocuments = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    let responseDocuments = await getAllAccountDocuments(accessToken)
    if (responseDocuments) {
      setDocuments(responseDocuments);
      setLoading(false);
    }
  }

  // TODO: move filters to the backend
  const filteredDocuments = useMemo(() => {
    const filteredDocuments = documents.filter(doc => {
      let userMatch = true;
      let typeMatch = true;
      let accountMatch = true;
      if (user !== 'Any') {
        userMatch = true // TODO: get the users and do this
      }
      if (type !== 'Any') {
        typeMatch = doc.type === type;
      }
      if (account !== 'Any') {
        accountMatch = doc.serviceId === account;
      }
      return userMatch && typeMatch && accountMatch;
    });
    return filteredDocuments;
  }, [documents, user, type, account]);

  const updateDocumentStatus = async (documentId: string, status: AccountDocumentStatus) => {
    const accessToken = await getAccessTokenSilently();
    const newDoc = await updateAccountDocumentStatus(accessToken, documentId, status);
    if (newDoc) {
      const newDocuments = documents.map(doc => {
        if (doc.id === documentId) {
          return { ...doc, status: newDoc.status };
        }
        return doc;
      });
      setDocuments(newDocuments);
    }
  }


  return (
    <>
      <Helmet>
        <title>Accound documents</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack direction='row' spacing={3} sx={{ mb: 1 }}>
              <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchDocuments()}>
                <RefreshIcon fontSize='small' />
              </LoadingButton>
              <FormControl size='small'>
                <InputLabel>User</InputLabel>
                <Select
                  label='User'
                  sx={{ minWidth: 120 }}
                  value={user}
                  onChange={(e) => setUser(e.target.value as string)}
                >
                  {/* TODO: get a list of all users from the backend. we don't have names yet */}
                  {['Any'].map((user, index) => (
                    <MenuItem key={index} value={user}>
                      {user}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size='small'>
                <InputLabel>Type</InputLabel>
                <Select
                  label='Method'
                  sx={{ minWidth: 120 }}
                  value={type}
                  onChange={(e) => setType(e.target.value as string)}
                >
                  {['Any', 'NDA', 'MTA', 'OTHER'].map((type, index) => (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size='small'>
                <InputLabel>Account</InputLabel>
                <Select
                  label='Account'
                  sx={{ minWidth: 120 }}
                  value={account}
                  onChange={(e) => setAccount(e.target.value as string)}
                >
                  {['Any', 'SecureDNA', 'Aclid', 'Azenta', 'IDT', 'Olon', 'Benchling', 'Geneious', 'Kernel', 'Damp Lab'].map((account, index) => (
                    <MenuItem key={index} value={account}>
                      {account}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <DocumentsTable documents={filteredDocuments} updateDocumentStatus={updateDocumentStatus} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default DocumentsPage;
