
export interface Order {
  genscript: {
    order_number: string;
    order_name: string;
    quantity_uom: string;
    status: string;
    order_date: string;
    estimated_delivery_date: string;    
  },
  fedex: {
    tracking_number: string;
    status_description: string;
    received_by: string;
    actual_delivery_time: string;
  }
}


export const fakeOrders = [
  {
    "genscript": {
      "order_number": "100001",
      "order_name": "Gene Synthesis",
      "quantity_uom": "2",
      "status": "Order Created",
      "order_date": "2023-05-01",
      "estimated_delivery_date": "2023-05-10",
    },
    "fedex": {
      "tracking_number": "ABC123",
      "status_description": "Label Created",
      "received_by": "Jane Smith",
      "actual_delivery_time": "2023-05-12"
    }
  },
  {
    "genscript": {
      "order_number": "100002",
      "order_name": "PCR Primers",
      "quantity_uom": "3",
      "status": "Order in Production",
      "order_date": "2023-04-15",
      "estimated_delivery_date": "2023-04-25",
    },
    "fedex": {
      "tracking_number": "XYZ789",
      "status_description": "In Transit",
      "received_by": "Michael Brown",
      "actual_delivery_time": "2023-04-28"
    }
  },
  {
    "genscript": {
      "order_number": "100003",
      "order_name": "Custom Oligos",
      "quantity_uom": "5",
      "status": "Order Shipped",
      "order_date": "2023-03-01",
      "estimated_delivery_date": "2023-03-08",
    },
    "fedex": {
      "tracking_number": "DEF456",
      "status_description": "Out for Delivery",
      "received_by": "Chris Green",
      "actual_delivery_time": "2023-03-10"
    }
  },
  {
    "genscript": {
      "order_number": "100004",
      "order_name": "DNA Sequencing",
      "quantity_uom": "4",
      "status": "Order Delivered",
      "order_date": "2023-02-10",
      "estimated_delivery_date": "2023-02-17",
    },
    "fedex": {
      "tracking_number": "GHI012",
      "status_description": "Delivered",
      "received_by": "Laura White",
      "actual_delivery_time": "2023-02-18"
    }
  },
  {
    "genscript": {
      "order_number": "100005",
      "order_name": "Plasmid Prep",
      "quantity_uom": "1",
      "status": "Order Created",
      "order_date": "2023-01-05",
      "estimated_delivery_date": "2023-01-12",
    },
    "fedex": {
      "tracking_number": "JKL345",
      "status_description": "At Local Facility",
      "received_by": "David Johnson",
      "actual_delivery_time": "2023-01-15"
    }
  },
  {
    "genscript": {
      "order_number": "100006",
      "order_name": "Protein Expression",
      "quantity_uom": "2",
      "status": "Order in Production",
      "order_date": "2022-12-20",
      "estimated_delivery_date": "2022-12-27",
    },
    "fedex": {
      "tracking_number": "MNO678",
      "status_description": "In Transit",
      "received_by": "Emily Davis",
      "actual_delivery_time": "2022-12-30"
    }
  },
  {
    "genscript": {
      "order_number": "100007",
      "order_name": "RNA Synthesis",
      "quantity_uom": "3",
      "status": "Order Shipped",
      "order_date": "2022-11-15",
      "estimated_delivery_date": "2022-11-22",
    },
    "fedex": {
      "tracking_number": "PQR901",
      "status_description": "Delivered",
      "received_by": "Daniel Wilson",
      "actual_delivery_time": "2022-11-23"
    }
  },
  {
    "genscript": {
      "order_number": "100008",
      "order_name": "Peptide Synthesis",
      "quantity_uom": "4",
      "status": "Order Delivered",
      "order_date": "2022-10-10",
      "estimated_delivery_date": "2022-10-17",
    },
    "fedex": {
      "tracking_number": "STU234",
      "status_description": "Delivered",
      "received_by": "Olivia Martinez",
      "actual_delivery_time": "2022-10-18"
    }
  },
  {
    "genscript": {
      "order_number": "100009",
      "order_name": "CRISPR Kits",
      "quantity_uom": "5",
      "status": "Order Created",
      "order_date": "2022-09-05",
      "estimated_delivery_date": "2022-09-12",
    },
    "fedex": {
      "tracking_number": "VWX567",
      "status_description": "At Local Facility",
      "received_by": "Sophia Garcia",
      "actual_delivery_time": "2022-09-15"
    }
  },
  {
    "genscript": {
      "order_number": "100010",
      "order_name": "Custom Antibodies",
      "quantity_uom": "1",
      "status": "Order in Production",
      "order_date": "2022-08-01",
      "estimated_delivery_date": "2022-08-08",
    },
    "fedex": {
      "tracking_number": "YZA890",
      "status_description": "Out for Delivery",
      "received_by": "Liam Hernandez",
      "actual_delivery_time": "2022-08-10"
    }
  },
  {
    "genscript": {
      "order_number": "100011",
      "order_name": "Gene Cloning",
      "quantity_uom": "2",
      "status": "Order Shipped",
      "order_date": "2022-07-20",
      "estimated_delivery_date": "2022-07-27",
    },
    "fedex": {
      "tracking_number": "BCD123",
      "status_description": "Delivered",
      "received_by": "Isabella Lopez",
      "actual_delivery_time": "2022-07-28"
    }
  },
  {
    "genscript": {
      "order_number": "100012",
      "order_name": "Cell Line Development",
      "quantity_uom": "3",
      "status": "Order Delivered",
      "order_date": "2022-06-10",
      "estimated_delivery_date": "2022-06-17",
    },
    "fedex": {
      "tracking_number": "EFG456",
      "status_description": "Delivered",
      "received_by": "Lucas Perez",
      "actual_delivery_time": "2022-06-18"
    }
  },
  {
    "genscript": {
      "order_number": "100013",
      "order_name": "Molecular Biology Kits",
      "quantity_uom": "4",
      "status": "Order Created",
      "order_date": "2022-05-05",
      "estimated_delivery_date": "2022-05-12",
    },
    "fedex": {
      "tracking_number": "HIJ789",
      "status_description": "In Transit",
      "received_by": "Mia Sanchez",
      "actual_delivery_time": "2022-05-14"
    }
  },
  {
    "genscript": {
      "order_number": "100014",
      "order_name": "Virus Production",
      "quantity_uom": "2",
      "status": "Order in Production",
      "order_date": "2022-04-01",
      "estimated_delivery_date": "2022-04-08",
    },
    "fedex": {
      "tracking_number": "KLM012",
      "status_description": "At Local Facility",
      "received_by": "Ava Walker",
      "actual_delivery_time": "2022-04-10"
    }
  },
  {
    "genscript": {
      "order_number": "100015",
      "order_name": "Protein Purification",
      "quantity_uom": "1",
      "status": "Order Shipped",
      "order_date": "2022-03-15",
      "estimated_delivery_date": "2022-03-22",
    },
    "fedex": {
      "tracking_number": "NOP345",
      "status_description": "Out for Delivery",
      "received_by": "James Young",
      "actual_delivery_time": "2022-03-24"
    }
  }
]
