import { Organization, ResourceKey } from "./organization";

export interface User {
  id: string;
  authId: string;
  email: string;
  roles: Role[];
  organization?: Organization
  resourceKeys?: ResourceKey[];
}

export enum RoleName { 
  SUPER_ADMIN = 'super-admin',
  ORGANIZATION_ADMIN = 'organization-admin',
  USER = 'user'
}

export interface Role {
  id: string;
  name: RoleName;
  permissions: Permission[];
  users?: User[];
}

export interface Permission {
  id: string;
  name: string;
}