import SeqViz from 'seqviz';
import {
  Box, Modal, Stack, Typography, Table, TableBody, TableCell, Tooltip,
  TableContainer, TableHead, TableRow, Button, FormControl, Select, MenuItem, IconButton
} from '@mui/material';
import { Sequence } from 'src/models/sequence';
import DownloadIcon from '@mui/icons-material/Download';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 4
};

interface BiosecurityDetailsProps {
  onClose: () => void;
  open: boolean;
  sequence: Sequence;
}

function ManufacturingDetails({ onClose, sequence, open }: BiosecurityDetailsProps) {

  const handleClose = () => {
    onClose();
  };

  const experimentTypes = ["Gibson Assembly", "MoClo", "NGS"];
  
  const manufacturers = [
    { name: "DAMP Lab", time: "12 days",  cost: "$1600", url: `${process.env.REACT_APP_DAMP_FRONT_END}/canvas/gibsonassembly`, csvFile: "/files/pTEL_0004.csv", icon: "/static/images/companies/DAMP_logo.png"},
    { name: "IDT",      time: "14 days",  cost: "$1800", url: "https://www.idtdna.com/site/order/stock", csvFile: "/files/pTEL_0004.csv", icon: "/static/images/companies/idt_logo.png" },
    { name: "Twist",    time: "16 days",  cost: "$2000", url: "https://ecommerce.twistdna.com/app/gene?_gl=1*fffbii*_gcl_au*MjA5MzY3MTU1OC4xNzI2MjE2MTU3*_ga*NjMyNzIxMzMuMTcyNjIxNjE1Ng..*_ga_5YCKYQX1L6*MTcyNjIyMzkwMy4yLjEuMTcyNjIyMzk4NS41OS4wLjA.*_ga_K8BD2PW8T3*MTcyNjIyMzkwMy4yLjEuMTcyNjIyMzk4NC4wLjAuMA..", csvFile: "/files/pTEL_0004.csv", icon: "/static/images/companies/twist_dark.png" },
  ];

  const downloadCSV = (csvFile: string) => {
    const link = document.createElement("a");
    link.href = csvFile; 
    link.download = csvFile.substring(csvFile.lastIndexOf('/') + 1);
    link.click();
  };

  const handleManufactureClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack direction="column" spacing={2} alignItems='center'>
          <Typography variant="h4">
            Manufacturing check details
          </Typography>
          <Typography variant="h5">
            Sequence name: {sequence.name}
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>Estimated Time*</TableCell>
                  <TableCell>Estimated Cost*</TableCell>
                  <TableCell>Experiment Type</TableCell>
                  <TableCell>Send to Manufacturer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {manufacturers.map((manufacturer, index) => (
                  <TableRow key={manufacturer.name}>
                    <TableCell>{manufacturer.name}</TableCell>
                    <TableCell>{manufacturer.time}</TableCell>
                    <TableCell>{manufacturer.cost}</TableCell>
                    <TableCell>
                      <FormControl>
                        <Select
                          defaultValue={experimentTypes[0]}
                          size="small"
                        >
                          {experimentTypes.map((type, idx) => (
                            <MenuItem key={idx} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Tooltip title="Download Default Gibson Assembly CSV Configuration">
                        <IconButton onClick={() => downloadCSV(manufacturer.csvFile)}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={() => handleManufactureClick(manufacturer.url)}
                      >
                        <img src={manufacturer.icon} style={{height: "20px"}}/>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
        <br/>
        * Estimates for demo purposes only
      </Box>
    </Modal>
  );
}

export default ManufacturingDetails;
