export const getAllRoles = async (accessToken: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/roles/all-roles`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getRoles = async (accessToken: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/roles`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const assignRolesToUser = async (accessToken: string, userId: string, roleIds: string[]) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/roles/assign-role-to-user`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          userId,
          roleIds,
        }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
}
