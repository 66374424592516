import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  FormControl,
  Stack,
  TextField,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { Resource, ResourceKey } from 'src/models/organization';
import SaveIcon from '@mui/icons-material/Save';
import { updateResourceOrgKey } from 'src/api/OrganizationQueries';
import { updateResourceUserKey } from 'src/api/UserQueries';

interface ResourcesTableProps {
  className?: string;
  resources: Resource[];
  resourceKeys: ResourceKey[];
  forOrganizations?: boolean;
}

const applyPagination = (
  resources: Resource[],
  page: number,
  limit: number
): Resource[] => {
  return resources.slice(page * limit, page * limit + limit);
};

const ResourcesTable: FC<ResourcesTableProps> = ({ resources, resourceKeys, forOrganizations }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [newResourceKeyIds, setNewResourceKeyIds] = useState<Record<string, string>>({});
  const [newResourceKeySecrets, setNewResourceKeySecrets] = useState<Record<string, string>>({});
  const [loadingSavingKeyId, setLoadingSavingKeyId] = useState<string | null>(null);
  const [loadingSavingKeySecret, setLoadingSavingKeySecret] = useState<string | null>(null);

  useEffect(() => {
    parseResourceKeyAndSecrets(resourceKeys);
  }, [resourceKeys]);

  const parseResourceKeyAndSecrets = (resourceKeys: ResourceKey[]) => {
    const newResourceKeyIds: Record<string, string> = {};
    const newResourceKeySecrets: Record<string, string> = {};
    resourceKeys.forEach((key) => {
      if (key.accessId) {
        newResourceKeyIds[key.resourceId] = key.accessId;
      }
      newResourceKeySecrets[key.resourceId] = key.accessSecret;
    });
    setNewResourceKeyIds(newResourceKeyIds);
    setNewResourceKeySecrets(newResourceKeySecrets);
  }


  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleKeyIdChange = (resourceId: string, keyId: string) => {
    setNewResourceKeyIds({ ...newResourceKeyIds, [resourceId]: keyId });
  }

  const handleSaveKeyId = async (resourceId: string) => {
    setLoadingSavingKeyId(resourceId);
    const newKeyId = newResourceKeyIds[resourceId];
    if (newKeyId === null) {
      return;
    }
    const accessToken = await getAccessTokenSilently();
    if (forOrganizations) {
      await updateResourceOrgKey(accessToken, { resourceId, keyId: newKeyId });
    } else {
      await updateResourceUserKey(accessToken, { resourceId, keyId: newKeyId });
    }
    setLoadingSavingKeyId(null);
  }

  const handleKeySecretChange = (resourceId: string, keySecret: string) => {
    setNewResourceKeySecrets({ ...newResourceKeySecrets, [resourceId]: keySecret });
  }

  const handleSaveKeySecret = async (resourceId: string) => {
    setLoadingSavingKeySecret(resourceId);
    const newKeySecret = newResourceKeySecrets[resourceId];
    if (newKeySecret === null) {
      return;
    }
    const accessToken = await getAccessTokenSilently();
    if (forOrganizations) {
      await updateResourceOrgKey(accessToken, { resourceId, keySecret: newKeySecret });
    } else {
      await updateResourceUserKey(accessToken, { resourceId, keySecret: newKeySecret });
    }
    setLoadingSavingKeySecret(null);
  }

  const paginatedResources = applyPagination(
    resources,
    page,
    limit
  );

  return (
    <Card>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Key id (optional)</TableCell>
              <TableCell>Key secret</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedResources.map((resource) => {
              return (
                <TableRow
                  hover
                  key={resource.name}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {resource.name}
                    </Typography>
                  </TableCell>
                  {(forOrganizations && resource.allowsOrganizationalKey) || (!forOrganizations && resource.allowsOrganizationalKey !== null) ?
                    <TableCell>
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <FormControl size='small'>
                          <TextField
                            size='small'
                            label="Key id"
                            sx={{ width: '300px' }}
                            value={newResourceKeyIds[resource.id] || ''}
                            onChange={(e) => handleKeyIdChange(resource.id, e.target.value)}
                          />
                        </FormControl>
                        {loadingSavingKeyId !== resource.id ?
                          <IconButton onClick={() => handleSaveKeyId(resource.id)}>
                            <SaveIcon />
                          </IconButton> :
                          <CircularProgress size="1rem" />
                        }
                      </Stack>
                    </TableCell> :
                    (forOrganizations && resource.allowsOrganizationalKey === false) ?
                      <TableCell>
                        <Typography variant='body2' color='text.secondary'>Organizational key not allowed</Typography>
                      </TableCell> :
                      <TableCell>
                        <Typography variant='body2' color='text.secondary'>Key not needed</Typography>
                      </TableCell>
                  }
                  {(forOrganizations && resource.allowsOrganizationalKey) || (!forOrganizations && resource.allowsOrganizationalKey !== null) ?
                    <TableCell>
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <FormControl size='small'>
                          <TextField
                            size='small'
                            label="Key secret"
                            sx={{ width: '300px' }}
                            value={newResourceKeySecrets[resource.id] || ''}
                            onChange={(e) => handleKeySecretChange(resource.id, e.target.value)}
                          />
                        </FormControl>
                        {loadingSavingKeySecret !== resource.id ?
                          <IconButton onClick={() => handleSaveKeySecret(resource.id)}>
                            <SaveIcon />
                          </IconButton> :
                          <CircularProgress size="1rem" />
                        }
                      </Stack>
                    </TableCell>
                    :
                    (forOrganizations && resource.allowsOrganizationalKey === false) ?
                      <TableCell>
                        <Typography variant='body2' color='text.secondary'>Organizational key not allowed</Typography>
                      </TableCell> :
                      <TableCell>
                        <Typography variant='body2' color='text.secondary'>Key not needed</Typography>
                      </TableCell>
                  }
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={resources.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default ResourcesTable;
