export const getMe = async (accessToken: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/me`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};


export const getAllUsers = async (accessToken: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/all-users`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const createUser = async (accessToken: string, email: string, password: string, organizationId: string, roleIds: string[]) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          organizationId,
          roleIds,
        }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      return message;
    }
  } catch (e) {
    console.log(e);
  }
};

export const createUserInOrganization = async (accessToken: string, email: string, password: string, roleIds: string[]) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/create-in-organization`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          roleIds,
        }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      return message;
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateResourceUserKey = async (accessToken: string, body: { resourceId: string, keyId?: string, keySecret?: string }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/update-resource-key`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};