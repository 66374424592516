import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Typography,
  Box,
  TextField,
  Button,
  Modal,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import BlurCircularIcon from "@mui/icons-material/BlurCircular";
import { LoadingButton } from "@mui/lab";
import JSZip from "jszip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Carousel from "./Carousel";

function ComponentLibrary() {
  const location = useLocation();
  const { initialSequences } = (location.state as any) ?? {
    initialSequences: [],
  };

  const [checkedStates, setCheckedStates] = useState(
    initialSequences.reduce((acc, sequence) => {
      acc[sequence.id] = {
        vector: false,
        promoter: false,
        rbs: false,
        cds: false,
        terminator: false,
      };
      return acc;
    }, {})
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: "16px",
    padding: 4,
  };

  const [constructs, setConstructs] = useState([]);
  const [constructName, setConstructName] = useState("");
  const { getAccessTokenSilently } = useAuth0();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [seqVizData, setSeqVizData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const tableRef = useRef(null);
  const barRef = useRef(null);

  const handleRowSelect = (sequenceId, shiftPressed) => {
    setSelectedRows((prevSelected) => {
      if (shiftPressed && prevSelected.length > 0) {
        const lastSelected = prevSelected[prevSelected.length - 1];
        const currentIndex = initialSequences.findIndex(
          (seq) => seq.id === sequenceId
        );
        const lastIndex = initialSequences.findIndex(
          (seq) => seq.id === lastSelected
        );

        const [start, end] = [currentIndex, lastIndex].sort((a, b) => a - b);
        const range = initialSequences
          .slice(start, end + 1)
          .map((seq) => seq.id);
        return Array.from(new Set([...prevSelected, ...range]));
      }
      return prevSelected.includes(sequenceId)
        ? prevSelected.filter((id) => id !== sequenceId)
        : [...prevSelected, sequenceId];
    });
  };

  const handleCheckboxChange = (sequenceId, type) => {
    setCheckedStates((prevState) => {
      const rowState = prevState[sequenceId];

      if (rowState[type]) {
        return {
          ...prevState,
          [sequenceId]: {
            vector: false,
            promoter: false,
            rbs: false,
            cds: false,
            terminator: false,
          },
        };
      }

      return {
        ...prevState,
        [sequenceId]: {
          vector: type === "vector",
          promoter: type === "promoter",
          rbs: type === "rbs",
          cds: type === "cds",
          terminator: type === "terminator",
        },
      };
    });
  };

  const handleMapConstruct = () => {
    setSuccess(false);
    if (!constructName.trim()) {
      alert("Please enter a name for the construct.");
      return;
    }

    const selectedComponents = initialSequences
      .map((sequence) => {
        const selectedType = Object.entries(checkedStates[sequence.id]).find(
          ([, isChecked]) => isChecked
        );
        if (selectedType) {
          return {
            sequenceName: sequence.name,
            componentType: selectedType[0],
            seq: sequence.seq,
          };
        }
        return null;
      })
      .filter(Boolean);

    if (selectedComponents.length === 0) {
      alert("No components selected to map the construct.");
      return;
    }

    setConstructs((prev) => [
      ...prev,
      { name: constructName, components: selectedComponents },
    ]);
    setConstructName("");
  };

  const selectedCount = Object.values(checkedStates).filter((row) =>
    Object.values(row).some((isChecked) => isChecked)
  ).length;

  const handleClearSelections = () => {
    setConstructs([])
    setSuccess(null)
    setCheckedStates(
      initialSequences.reduce((acc, sequence) => {
        acc[sequence.id] = {
          vector: false,
          promoter: false,
          rbs: false,
          cds: false,
          terminator: false,
        };
        return acc;
      }, {})
    );
  };

  const parseGenBank = (genbankContent) => {
    const sequenceMatch = genbankContent.match(/ORIGIN[\s\S]*?\/\/\n/);
    const sequence = sequenceMatch
      ? sequenceMatch[0]
          .replace(/ORIGIN|\s|\/\//g, "") 
          .replace(/[0-9]/g, "")
      : "";

    const featuresMatch = genbankContent.match(/FEATURES\s+[\s\S]*?ORIGIN/);
    const featuresSection = featuresMatch ? featuresMatch[0] : "";

    const annotations = [];
    const featureRegex = / {5}(\w+)\s+(\d+)\.\.(\d+)/g; 
    let match;
    let index = 0;

    while ((match = featureRegex.exec(featuresSection)) !== null) {
      const [_, feature, start, end] = match;
      annotations.push({
        name: `${feature} hit ${index + 1}`,
        start: parseInt(start, 10),
        end: parseInt(end, 10),
        direction: 1,
        color: "red",
      });
      index++;
    }

    return { sequence, annotations };
  };

  const handleConstructsSubmit = async () => {
    const accessToken = await getAccessTokenSilently();
    if (constructs.length === 0) {
      alert("No constructs to submit.");
      return;
    }

    try {
      const componentOrder = {
        vector: 0,
        promoter: 1,
        rbs: 2,
        cds: 3,
        terminator: 4,
      };

      constructs.forEach((construct) => {
        construct.components = construct.components.sort((a, b) => {
          return (
            componentOrder[a.componentType] - componentOrder[b.componentType]
          );
        });
      });

      setSubmitLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/dna-assembly/create`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ constructs }),
        }
      );
      const blob = await response.blob();

      const zip = await JSZip.loadAsync(blob);

      const genbankFiles = Object.keys(zip.files).filter(
        (name) => name.endsWith(".gb") || name.endsWith(".genbank")
      );

      if (!genbankFiles) {
        console.error("No GenBank file found in the ZIP");
        return;
      }

      let seqVizData = [];

      genbankFiles.forEach(async (genbankFile) => {
        const genbankContent = await zip.files[genbankFile].async("string");

        const { sequence, annotations } = parseGenBank(genbankContent);
        seqVizData.push({
          seq: sequence,
          annotations: annotations,
          name: genbankFile.split(".")[0],
        });
      });

      setSeqVizData(seqVizData);

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "assembled_constructs.zip");
      document.body.appendChild(link);
      link.click();
      link.remove();

      setSuccess("Assembly submitted. Check your downloads for the results.");
    } catch (error) {
      console.error("Failed to submit constructs:", error);
    }
    setSubmitLoading(false);
  };

  const handleBulkAssign = (type) => {
    setCheckedStates((prevState) => {
      const updatedState = { ...prevState };
      selectedRows.forEach((rowId) => {
        updatedState[rowId] = {
          vector: type === "vector",
          promoter: type === "promoter",
          rbs: type === "rbs",
          cds: type === "cds",
          terminator: type === "terminator",
        };
      });
      return updatedState;
    });
    setSelectedRows([]);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    if (type) {
      handleBulkAssign(type);
    }
    setAnchorEl(null);
  };

  const handleClickOutside = (event) => {
    if (tableRef.current && !tableRef.current.contains(event.target) && !barRef.current.contains(event.target)) {
      setSelectedRows([]);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Box p={3}>
      <Typography sx={{ mb: 3 }} variant="h3" gutterBottom>
        Create DNA Constructs
      </Typography>
      <Typography variant="h6" gutterBottom>
        Selected Components: {selectedCount}
      </Typography>

      <Box ref={barRef} display="flex" alignItems="left" sx={{backgroundColor: 'white', p:3, borderRadius: '16px'}} gap={2} mb={2}>
        <TextField
          label="Construct Name"
          value={constructName}
          onChange={(e) => setConstructName(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleMapConstruct}>
          Map Construct
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleClearSelections}
        >
          Clear Selections
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          disabled={constructs.length == 0}
          onClick={handleConstructsSubmit}
          loading={submitLoading}
        >
          Assemble Constructs
          <BlurCircularIcon sx={{ ml: 1 }} />
        </LoadingButton>
        {success && seqVizData.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowModal(true)}
          >
            View Assembled Constructs
          </Button>
        )}
        <>
          <IconButton onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose(null)}
          >
            {["vector", "promoter", "rbs", "cds", "terminator"].map((type) => (
              <MenuItem key={type} onClick={() => handleClose(type)}>
                Assign as {type}
              </MenuItem>
            ))}
          </Menu>
        </>
      </Box>
      <Typography sx={{ mb: 3, fontStyle: 'italic'}} variant="h6" gutterBottom>
        Shift + Click to select a range of rows and click menu icon to bulk assign components.
      </Typography>
      {success && (
          <Typography variant="body2" color="success.main" sx={{ mt: 1, mb:1}}>
            {success}
          </Typography>
        )}
      <TableContainer component={Paper}>
        <Table ref={tableRef}>
          <TableHead>
            <TableRow>
              <TableCell>Sequence Name</TableCell>
              <TableCell>Vector</TableCell>
              <TableCell>Promoter</TableCell>
              <TableCell>RBS</TableCell>
              <TableCell>CDS</TableCell>
              <TableCell>Terminator</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {initialSequences.map((sequence) => (
              <TableRow
                sx={{ userSelect: "none" }}
                key={sequence.id}
                selected={selectedRows.includes(sequence.id)}
                onClick={(e) => handleRowSelect(sequence.id, e.shiftKey)}
              >
                <TableCell>{sequence.name}</TableCell>
                {["vector", "promoter", "rbs", "cds", "terminator"].map(
                  (type) => (
                    <TableCell key={type}>
                      <Checkbox
                        checked={checkedStates[sequence.id][type]}
                        onChange={() => handleCheckboxChange(sequence.id, type)}
                      />
                    </TableCell>
                  )
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {showModal && (
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <Box sx={style}>
            <Carousel data={seqVizData} />
          </Box>
        </Modal>
      )}

      {/* Construct List */}
      <Box mt={4} p={2} mb={3}>
        <Typography variant="h6">
          Mapped Constructs: {constructs.length}
        </Typography>
        {constructs.map((construct, index) => (
          <Box key={index} mt={1} p={2} border={1} borderColor="grey.300">
            <Typography variant="subtitle1">
              <strong>Name:</strong> {construct.name}
            </Typography>
            <Typography variant="body1">
              <strong>Components:</strong>
            </Typography>
            <ul>
              {construct.components.map((comp, idx) => (
                <li key={idx}>
                  {comp.sequenceName} ({comp.componentType})
                </li>
              ))}
            </ul>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default ComponentLibrary;