import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import Footer from 'src/components/Footer';
import { useEffect, useState } from 'react';
import { getAllSequences } from 'src/api/SequencesQueries';
import { useAuth0 } from '@auth0/auth0-react';


function SecureDna() {
  const { getAccessTokenSilently } = useAuth0();

  const [sequence, setSequence] = useState('');
  const [sequences, setSequences] = useState([]);

  const fetchMPISequences = async () => {
    try{
      const accessToken = await getAccessTokenSilently();
      const responseSequences = await getAllSequences(accessToken);
      if (responseSequences) {
        setSequences(responseSequences);
      }
    }
    catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
    fetchMPISequences();
  },[])
  return (
    <>
      <Helmet>
        <title>SecureDNA</title>
      </Helmet>
      <PageTitleWrapper>
        <Typography variant="h2">
          SecureDNA Biosecurity Screening
        </Typography>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item xs={12}>
            <Stack direction="column" spacing={5}>
            <FormControl variant="outlined" margin="normal">
            <InputLabel id="textarea-label">Enter DNA Sequence</InputLabel>
            <TextField multiline rows={6} sx={{width: '75%'}} value={sequence} onChange={(e) => setSequence(e.target.value.toUpperCase())} variant="outlined"/>
            </FormControl>
            <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="dropdown-label">Select an MPI Sequence</InputLabel>
            <Select
              labelId="dropdown-label"
              value={sequence}
              sx={{width: '50%'}}
              onChange={(e) => setSequence(e.target.value.toUpperCase())}
              label="Select an MPI Sequence"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {sequences && sequences.map((sequence, index) => {
              return <MenuItem key={index} value={sequence.seq}>{sequence.name}</MenuItem>
            })}
            </Select>
          </FormControl>
            <Button sx={{width: '100px'}} variant='contained'>Submit</Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default SecureDna;
