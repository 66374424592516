import * as XLSX from 'xlsx';

interface ProcessedData {
  key: string;
  value: string;
}

const processJSON = (obj: any, prefix = '', depth = 0): ProcessedData[] => {
  let result: ProcessedData[] = [];

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;
    const indentedKey = ' '.repeat(depth * 2) + newKey;

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      result = result.concat(processJSON(value, newKey, depth + 1));
    } else {
      result.push({ key: indentedKey, value: JSON.stringify(value) });
    }
  });

  return result;
};

export const exportJSONToExcel = (apiResult: any) => {
  const processedData = processJSON(apiResult);

  const transposedData = processedData.map(({ key, value }) => ({ Field: key, Value: value }));

  const worksheet = XLSX.utils.json_to_sheet(transposedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'API Result');
  XLSX.writeFile(workbook, 'api_result.xlsx');
};
