import { FC, ChangeEvent, useState } from 'react';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import Label from 'src/components/Label';
import { AccountDocument, AccountDocumentStatus } from 'src/models/accountDocument';

interface DocumentTableProps {
  className?: string;
  documents: AccountDocument[];
  updateDocumentStatus: (documentId: string, status: AccountDocumentStatus) => void;
}

// TODO: move pagination to the backend
const applyPagination = (
  documents: AccountDocument[],
  page: number,
  limit: number
): AccountDocument[] => {
  return documents.slice(page * limit, page * limit + limit);
};

const getStatusLabel = (status): JSX.Element => {
  switch (status) {
    case 'APPROVED':
      return <Label color='success'>{status}</Label>;
    case 'REJECTED':
      return <Label color='error'>{status}</Label>;
    case 'PENDING':
      return <Label color='warning'>{status}</Label>;
    default:
      return <Label>{status}</Label>;
  }
};

const DocumentsTable: FC<DocumentTableProps> = ({ documents, updateDocumentStatus }) => {
  const theme = useTheme();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [updatingDocId, setUpdatingDocId] = useState<string>('');

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleStatusChange = async (status: AccountDocumentStatus, documentId: string): Promise<void> => {
    setUpdatingDocId(documentId);
    await updateDocumentStatus(documentId, status);
    setUpdatingDocId('');
  }

  const paginatedDocuments = applyPagination(
    documents,
    page,
    limit
  );

  return (
    <Card>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Account</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='right'>View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDocuments.map((doc) => {
              return (
                <TableRow
                  hover
                  key={doc.id}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {doc.user.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {doc.serviceId}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {doc.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'uppercase' }}
                      gutterBottom
                      noWrap
                    >
                      {doc.type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {new Date(doc.createdAt).toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    {updatingDocId === doc.id ?
                      <CircularProgress size='30px'/>
                      :
                      <FormControl size='small'>
                        <Select
                          value={doc.status}
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                            "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                            "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          }}
                          onChange={(e) => handleStatusChange(e.target.value as AccountDocumentStatus, doc.id)}
                        >
                          {['APPROVED', 'REJECTED', 'PENDING'].map((status, index) => (
                            <MenuItem key={index} value={status} >
                              {getStatusLabel(status)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    }
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="Download" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.secondary.lighter },
                          color: theme.palette.secondary.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DownloadIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={documents.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default DocumentsTable;
