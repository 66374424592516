// TODO: add types
export const runComplexityScore = async (accessToken: string, input) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/idt/complex/gBlock`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(input),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};
