import { Typography, Button, Grid, Stack } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link as RouterLink } from 'react-router-dom';

function PageHeader() {
  return (

    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="column" spacing={1}>
        <Typography variant="h3" component="h3" gutterBottom>
          CMO Order Workflow
        </Typography>
        <Typography variant="subtitle2">
          Batch optimize and screen your sequences. Create orders for safe and low-complexity sequences.
        </Typography>
      </Stack>
      <Button
        variant="outlined"
        component={RouterLink}
        startIcon={<ArrowBackIosIcon fontSize="small" />}
        to="/sequences/">
        Back to sequences
      </Button>
    </Stack>

  );
}

export default PageHeader;
