import { AccountDocument, AccountDocumentStatus, AccountDocumentType } from "src/models/accountDocument";

export const uploadAccountDocument = async (
  accessToken: string,
  serviceId: string,
  name: string,
  type: AccountDocumentType
): Promise<AccountDocument | undefined> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/account-document/upload`, {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ serviceId, name, type }),
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getAccountDocuments = async (
  accessToken: string,
  serviceId: string
): Promise<AccountDocument[] | undefined> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/account-document?serviceId=${serviceId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getAllAccountDocuments = async (
  accessToken: string
): Promise<AccountDocument[] | undefined> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/account-document/all`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateAccountDocumentStatus = async (
  accessToken: string,
  documentId: string,
  status: AccountDocumentStatus
) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/account-document/${documentId}?status=${status}`, {
      method: "PATCH",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteAccountDocument = async (accessToken: string, documentId: string) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/account-document/${documentId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};
