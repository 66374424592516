import { FC, ChangeEvent, useState } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
} from '@mui/material';

import Label from 'src/components/Label';
import { TwistOrder } from 'src/models/twist';

interface OrdersTableProps {
  className?: string;
  orders: TwistOrder[];
}

const applyPagination = (
  orders: TwistOrder[],
  page: number,
  limit: number
): TwistOrder[] => {
  return orders.slice(page * limit, page * limit + limit);
};

const getStatusLabel = (status): JSX.Element => {
  const map = {
    pending: {
      text: 'Pending',
      color: 'warning'
    },
    'in_production': {
      text: 'In production',
      color: 'primary'
    },
    'completed': {
      text: 'Completed',
      color: 'info'
    },
    'closed': {
      text: 'Completed',
      color: 'success'
    },
    'canceled': {
      text: 'Canceled',
      color: 'error'
    },
  };

  if (!status) {
    return <></>;
  }

  if (map[status]) {
    const { text, color }: any = map[status];
    return <Label color={color}>{text}</Label>;
  } else {
    return <Label color='success'>{status}</Label>;
  }

};

const OrdersTable: FC<OrdersTableProps> = ({ orders }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);


  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedOrders = applyPagination(
    orders,
    page,
    limit
  );

  return (
    <Card>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrders.map((order) => {
              return (
                <TableRow
                  hover
                  key={order.sfdc_id}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ maxWidth: 150, textOverflow: "ellipsis" }}
                      gutterBottom
                      noWrap
                    >
                      {order.order_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {order.sub_type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {getStatusLabel(order.status)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {new Date(order.created_date).toLocaleDateString()}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {order.currency_iso_code} {order.total_amount}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={orders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default OrdersTable;
