import { useState, useRef } from 'react';

import {
  Box,
  Menu,
  IconButton,
  ListItemText,
  ListItem,
  List,
  Typography
} from '@mui/material';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';

import { AzentaSeqOrder } from 'src/models/azenta';

interface BulkActionsProps {
  selectedOrders: AzentaSeqOrder[];
  selectedBulkActions: boolean;
}

function BulkActions({ selectedOrders: selectedOrders, selectedBulkActions }: BulkActionsProps) {
  const [onMenuOpen, menuOpen] = useState<boolean>(false);
  const moreRef = useRef<HTMLButtonElement | null>(null);

  const openMenu = (): void => {
    menuOpen(true);
  };

  const closeMenu = (): void => {
    menuOpen(false);
  };

  return (
    <>
      {/* <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" color="text.secondary">
            Batch Options:
          </Typography>
        </Box>
        <IconButton
          color="primary"
          onClick={openMenu}
          ref={moreRef}
          sx={{ ml: 2, p: 1 }}
          disabled={!selectedBulkActions}
        >
          <MoreVertTwoToneIcon />
        </IconButton>
      </Box>

      <Menu
        keepMounted
        anchorEl={moreRef.current}
        open={onMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <List sx={{ p: 1 }} component="nav">
          <ListItem button>
            <ListItemText primary="Bulk delete" />
          </ListItem>
        </List>
      </Menu> */}
    </>
  );
}

export default BulkActions;
