import { Typography, Button, Grid, Stack } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

function PageHeader() {
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  }

  return (

    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="column" spacing={1}>
        <Typography variant="h3" component="h3" gutterBottom>
          Twist Order Workflow
        </Typography>
        <Typography variant="subtitle2">
          Order Gene Fragments directly from Twist.
        </Typography>
      </Stack>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIosIcon fontSize="small" />}
        onClick={navigateBack}
        >
        Back
      </Button>
    </Stack>

  );
}

export default PageHeader;
