export const tempBlastResult = `
  BLASTN 2.16.0+
  Reference: Stephen F. Altschul, Thomas L. Madden, Alejandro
  A. Schaffer, Jinghui Zhang, Zheng Zhang, Webb Miller, and
  David J. Lipman (1997), "Gapped BLAST and PSI-BLAST: a new
  generation of protein database search programs", Nucleic
  Acids Res. 25:3389-3402.


  RID: DF17D6S4013


  Database: Nucleotide collection (nt)
            108,728,460 sequences; 2,133,416,637,289 total letters
  Query= 
  Length=32


                                                                    Score     E     Max
  Sequences producing significant alignments:                       (Bits)  Value  Ident

  OZ121658.1 Venus verrucosa genome assembly, chromosome: 16         41.9    3.0    90%       
  CP027633.1 Linum usitatissimum chromosome Lu9                      41.0    3.0    93%       
  CP142492.2 Vitis vinifera cultivar Thompson Seedless isolate s...  41.0    3.0    93%       
  CP161734.1 Vitis vinifera cultivar Hongmunage chromosome 09        41.0    3.0    93%       
  CP161772.1 Vitis vinifera cultivar Baimunage chromosome 09         41.0    3.0    93%       
  CP161753.1 Vitis vinifera cultivar Hongmunage chromosome 09        41.0    3.0    93%       
  CP161791.1 Vitis vinifera cultivar Baimunage chromosome 09         41.0    3.0    93%       
  CP161639.1 Vitis vinifera cultivar Pinot Noir chromosome 09        41.0    3.0    93%       
  CP161715.1 Vitis vinifera cultivar Manicure Finger chromosome 09   41.0    3.0    93%       
  CP141012.1 Vitis vinifera cultivar Black Monukka isolate seedl...  41.0    3.0    93%       
  CP140993.1 Vitis vinifera cultivar Black Monukka isolate seedl...  41.0    3.0    93%       
  OY979651.1 Coenonympha glycerion genome assembly, chromosome: 25   41.0    3.0    90%       
  CP126656.1 Vitis vinifera cultivar Pinot Noir 40024 chromosome 9   41.0    3.0    93%       
  OU342982.1 Ypsolopha scabrella genome assembly, chromosome: 22     41.0    3.0    90%       
  OU342972.1 Ypsolopha scabrella genome assembly, chromosome: 12     41.0    3.0    90%       
  OU342970.1 Ypsolopha scabrella genome assembly, chromosome: 10     41.0    3.0    90%       
  OU342965.1 Ypsolopha scabrella genome assembly, chromosome: 5      41.0    3.0    90%       

  ALIGNMENTS
  >OZ121658.1 Venus verrucosa genome assembly, chromosome: 16
  Length=92471629

  Score = 41.9 bits (45),  Expect = 3.0
  Identities = 27/30 (90%), Gaps = 0/30 (0%)
  Strand=Plus/Minus

  Query  3         AGGAATCCTCACATAGGATTCCTCAGTATG  32
                  ||||||||||||| ||||||| | ||||||
  Sbjct  15273508  AGGAATCCTCACAAAGGATTCGTGAGTATG  15273479


  >CP027633.1 Linum usitatissimum chromosome Lu9
  Length=22091576

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Plus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||  |||||||||||||||
  Sbjct  9297125  TGAGGAATCCAAACATAGGATTCCTCA  9297151


  >CP142492.2 Vitis vinifera cultivar Thompson Seedless isolate seedless chromosome 
  09
  Length=24692234

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3536574  TGAGGAATCCTCACATTCGATTCCTCA  3536548


  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3738070  TGAGGAATCCTCACATTCGATTCCTCA  3738044


  >CP161734.1 Vitis vinifera cultivar Hongmunage chromosome 09
  Length=22461621

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3674259  TGAGGAATCCTCACATTCGATTCCTCA  3674233


  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3875757  TGAGGAATCCTCACATTCGATTCCTCA  3875731


  >CP161772.1 Vitis vinifera cultivar Baimunage chromosome 09
  Length=26743058

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3308458  TGAGGAATCCTCACATTCGATTCCTCA  3308432


  >CP161753.1 Vitis vinifera cultivar Hongmunage chromosome 09
  Length=25512083

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3308554  TGAGGAATCCTCACATTCGATTCCTCA  3308528


  >CP161791.1 Vitis vinifera cultivar Baimunage chromosome 09
  Length=23234080

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3680283  TGAGGAATCCTCACATTCGATTCCTCA  3680257


  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3881783  TGAGGAATCCTCACATTCGATTCCTCA  3881757


  >CP161639.1 Vitis vinifera cultivar Pinot Noir chromosome 09
  Length=26294478

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3408432  TGAGGAATCCTCACATTCGATTCCTCA  3408406


  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3609937  TGAGGAATCCTCACATTCGATTCCTCA  3609911


  >CP161715.1 Vitis vinifera cultivar Manicure Finger chromosome 09
  Length=24255331

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Plus

  Query  1         TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  20772714  TGAGGAATCCTCACATTCGATTCCTCA  20772740


  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Plus

  Query  1         TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  20974223  TGAGGAATCCTCACATTCGATTCCTCA  20974249


  >CP141012.1 Vitis vinifera cultivar Black Monukka isolate seedless chromosome 
  09
  Length=23735779

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3526379  TGAGGAATCCTCACATTCGATTCCTCA  3526353


  >CP140993.1 Vitis vinifera cultivar Black Monukka isolate seedless chromosome 
  09
  Length=25643033

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3539336  TGAGGAATCCTCACATTCGATTCCTCA  3539310


  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3740831  TGAGGAATCCTCACATTCGATTCCTCA  3740805


  >OY979651.1 Coenonympha glycerion genome assembly, chromosome: 25
  Length=10159198

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 28/31 (90%), Gaps = 2/31 (6%)
  Strand=Plus/Plus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCAGTAT  31
                  ||||||||||  ||||||||||| |||||||
  Sbjct  4572777  TGAGGAATCC--ACATAGGATTCATCAGTAT  4572805


  >CP126656.1 Vitis vinifera cultivar Pinot Noir 40024 chromosome 9
  Length=24372199

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 25/27 (93%), Gaps = 0/27 (0%)
  Strand=Plus/Minus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCA  27
                  ||||||||||||||||  |||||||||
  Sbjct  3484610  TGAGGAATCCTCACATTCGATTCCTCA  3484584


  >OU342982.1 Ypsolopha scabrella genome assembly, chromosome: 22
  Length=24595334

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 28/31 (90%), Gaps = 2/31 (6%)
  Strand=Plus/Plus

  Query  1        TGAGGAATCCTCACATAGGATTCCTCAGTAT  31
                  ||||||||||  ||||||||||| |||||||
  Sbjct  9268912  TGAGGAATCC--ACATAGGATTCATCAGTAT  9268940


  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 28/31 (90%), Gaps = 2/31 (6%)
  Strand=Plus/Plus

  Query  1         TGAGGAATCCTCACATAGGATTCCTCAGTAT  31
                  ||||||||||  ||||||||||| |||||||
  Sbjct  24022786  TGAGGAATCC--ACATAGGATTCATCAGTAT  24022814


  >OU342972.1 Ypsolopha scabrella genome assembly, chromosome: 12
  Length=29858840

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 28/31 (90%), Gaps = 2/31 (6%)
  Strand=Plus/Minus

  Query  1         TGAGGAATCCTCACATAGGATTCCTCAGTAT  31
                  ||||||||||  ||||||||||| |||||||
  Sbjct  13511141  TGAGGAATCC--ACATAGGATTCATCAGTAT  13511113


  >OU342970.1 Ypsolopha scabrella genome assembly, chromosome: 10
  Length=30740528

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 28/31 (90%), Gaps = 2/31 (6%)
  Strand=Plus/Plus

  Query  1         TGAGGAATCCTCACATAGGATTCCTCAGTAT  31
                  ||||||||||  ||||||||||| |||||||
  Sbjct  15869819  TGAGGAATCC--ACATAGGATTCATCAGTAT  15869847


  >OU342965.1 Ypsolopha scabrella genome assembly, chromosome: 5
  Length=31588498

  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 28/31 (90%), Gaps = 2/31 (6%)
  Strand=Plus/Minus

  Query  1         TGAGGAATCCTCACATAGGATTCCTCAGTAT  31
                  ||||||||||  ||||||||||| |||||||
  Sbjct  11403488  TGAGGAATCC--ACATAGGATTCATCAGTAT  11403460


  Score = 41.0 bits (44),  Expect = 3.0
  Identities = 28/31 (90%), Gaps = 2/31 (6%)
  Strand=Plus/Plus

  Query  1         TGAGGAATCCTCACATAGGATTCCTCAGTAT  31
                  ||||||||||  ||||||||||| |||||||
  Sbjct  21092199  TGAGGAATCC--ACATAGGATTCATCAGTAT  21092227


    Database: Nucleotide collection (nt)
      Posted date:  Aug 31, 2024  9:05 AM
    Number of letters in database: 2,133,416,637,289
    Number of sequences in database:  108,728,460

  Lambda      K        H
    0.634    0.408    0.912 
  Gapped
  Lambda      K        H
    0.625    0.410    0.780 
  Matrix: blastn matrix:2 -3
  Gap Penalties: Existence: 5, Extension: 2
  Number of Sequences: 108728460
  Number of Hits to DB: 2018671
  Number of extensions: 672
  Number of successful extensions: 672
  Number of sequences better than 10: 0
  Number of HSP's better than 10 without gapping: 0
  Number of HSP's gapped: 672
  Number of HSP's successfully gapped: 0
  Length of query: 32
  Length of database: 2133416637289
  Length adjustment: 29
  Effective length of query: 3
  Effective length of database: 2130263511949
  Effective search space: 6390790535847
  Effective search space used: 6390790535847
  A: 0
  X1: 22 (20.1 bits)
  X2: 33 (29.8 bits)
  X3: 110 (99.2 bits)
  S1: 28 (26.5 bits)
  S2: 43 (40.1 bits)
`
