import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { AclidScreen } from 'src/models/aclid';
import { getAllScreenings } from 'src/api/AclidQueries';
import ScreeningsTable from './ScreeningsTable';
import { useAuth0 } from '@auth0/auth0-react';

function AclidScreenings() {
  const { getAccessTokenSilently } = useAuth0();

  const [screenings, setScreenings] = useState<AclidScreen[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchScreenings();
  }, [])

  const fetchScreenings = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const responseScreens = await getAllScreenings(accessToken);
    if (responseScreens) {
      setScreenings(responseScreens['items']);
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Aclid</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchScreenings()}>
              <RefreshIcon fontSize='small'/>
            </LoadingButton>
            <ScreeningsTable screenings={screenings}/>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default AclidScreenings;
