import { useState, useRef } from 'react';
import {
  Box,
  Button,
  Menu,
  IconButton,
  ListItemText,
  ListItem,
  List,
  Typography,
  Modal,
  CircularProgress,
  TextField
} from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { LoadingButton } from '@mui/lab';

import { Sequence } from 'src/models/sequence';
import { tempBlastResult } from './exampleResult';
import RunBLAST from 'src/components/Modals/RunBLAST';
import { useAuth0 } from '@auth0/auth0-react';


interface BulkActionsProps {
  selectedSequences: Sequence[];
  selectedBulkActions: boolean;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
};

function BulkActions({ selectedSequences, selectedBulkActions }: BulkActionsProps) {
  const [onMenuOpen, menuOpen] = useState<boolean>(false);
  const moreRef = useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [blastResult, setBlastResult] = useState<string>('');
  const { getAccessTokenSilently } = useAuth0();

  const openMenu = (): void => {
    menuOpen(true);
  };

  const closeMenu = (): void => {
    menuOpen(false);
  };

  const openModal = (): void => {
    setModalOpen(true);
  };

  const closeModal = (): void => {
    setModalOpen(false);
    setBlastResult('');
  };

  const runBlastQuery = async () => {
    setLoading(true);
    openModal();
    const accessToken = await getAccessTokenSilently();
    const query = selectedSequences.map(seq => seq.seq).join('\n');
    const requestBody = {
      query,
      options: {
        program: 'blastn',
        database: 'nt',
        evalue: 0.001
      }
    };

    // TODO: Temporarily faking for demo purposes
    await new Promise(resolve => setTimeout(resolve, 5000))
    setBlastResult(tempBlastResult)
    // try {
    //   const response = await fetch('http://localhost:5000/blast', {
    //     method: 'POST',
    //     headers: {
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //     body: JSON.stringify(requestBody)
    //   });

    //   if (!response.ok) {
    //     throw new Error(`Error: ${response.status}`);
    //   }

    //   const data = await response.text();
    //   setBlastResult(data);
    // } catch (error) {
    //   console.error('Error running BLAST query:', error);
    //   setBlastResult(`Error running BLAST query: ${error.message}`);
    // }
    
    setLoading(false);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" color="text.secondary">
            BLAST batch of sequences:
          </Typography>
          <LoadingButton
            loading={loading} 
            onClick={runBlastQuery}
            variant="outlined"
            sx={{ ml: 3 }}
            startIcon={<PrecisionManufacturingIcon />}
            disabled={!selectedBulkActions}
          >
            BLAST
          </LoadingButton>
        </Box>
        <IconButton
          color="primary"
          onClick={openMenu}
          ref={moreRef}
          sx={{ ml: 2, p: 1 }}
          disabled={!selectedBulkActions}
        >
          <MoreVertTwoToneIcon />
        </IconButton>
      </Box>

      <Menu
        keepMounted
        anchorEl={moreRef.current}
        open={onMenuOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <List sx={{ p: 1 }} component="nav">
          <ListItem button>
            <ListItemText primary="Bulk delete" />
          </ListItem>
        </List>
      </Menu>
      <RunBLAST isModalOpen={isModalOpen} closeModal={closeModal} loading={loading} blastResult={blastResult}/>
    </>
  );
}

export default BulkActions;
