import { useAuth0 } from '@auth0/auth0-react';
import { FC, useState, createContext, useEffect } from 'react';
import { getMe } from 'src/api/UserQueries';
import { User } from 'src/models/user';

type EnabledResources = {
  DOCUMENTS: boolean;
  ACLID: boolean;
  AZENTA: boolean;
  TWIST: boolean;
  SECURE_DNA: boolean;
  DATA: boolean;
  IDT: boolean;
  LOGS: boolean;
  OPTIMIZATIONS: boolean;
  ORGANIZATIONS: boolean;
  ROLES: boolean;
  SEQUENCES: boolean;
  USERS: boolean;
  RESOURCES: boolean;
  ASSEMBLY: boolean;
  DAMP: boolean;
  WORKFLOWS: boolean;
};

const parseEnabledResources = (user: User): EnabledResources => {
  const enabledResources: EnabledResources = {
    DOCUMENTS: false,
    ACLID: false,
    AZENTA: false,
    TWIST: false,
    SECURE_DNA: false,
    DATA: false,
    IDT: false,
    LOGS: false,
    OPTIMIZATIONS: false,
    ORGANIZATIONS: false,
    ROLES: false,
    SEQUENCES: false,
    USERS: false,
    RESOURCES: false,
    ASSEMBLY: false,
    DAMP: false,
    WORKFLOWS: false
  };
  for (const enabledResource of user.organization.enabledResources) {
    switch (enabledResource.name) {
      case 'Documents':
        enabledResources.DOCUMENTS = true;
        break;
      case 'Aclid':
        enabledResources.ACLID = true;
        break;
      case 'Azenta':
        enabledResources.AZENTA = true;
        break;
      case 'Twist':
        enabledResources.TWIST = true;
        break;
      case 'Secure DNA':
        enabledResources.SECURE_DNA = true;
        break;
      case 'Data':
        enabledResources.DATA = true;
        break;
      case 'IDT':
        enabledResources.IDT = true;
        break;
      case 'Logs':
        enabledResources.LOGS = true;
        break;
      case 'Optimizations':
        enabledResources.OPTIMIZATIONS = true;
        break;
      case 'Organizations':
        enabledResources.ORGANIZATIONS = true;
        break;
      case 'Roles':
        enabledResources.ROLES = true;
        break;
      case 'Sequences':
        enabledResources.SEQUENCES = true;
        break;
      case 'Users':
        enabledResources.USERS = true;
        break;
      case 'Resources':
        enabledResources.RESOURCES = true;
        break;
      case 'Assembly':
        enabledResources.ASSEMBLY = true;
        break;
      case 'DAMP':
        enabledResources.DAMP = true;
        break;
      case 'Workflows':
        enabledResources.WORKFLOWS = true;
        break;
      default:
        console.log(`Resource ${enabledResource.name} not found`);
    }
  }
  return enabledResources;
}

type SidebarContext = {
  sidebarToggle: any;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  user: User | null;
  initialized: boolean;
  enabledResources: EnabledResources | null;
};

// // eslint-disable-next-line @typescript-eslint/no-redeclare  // discouraged by eslint and causing me errors: https://typescript-eslint.io/rules/no-redeclare/
export const SidebarContext = createContext<SidebarContext>(
  {} as SidebarContext
);

export const SidebarProvider: FC = ({ children }) => {
  const { getAccessTokenSilently} = useAuth0();

  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [initialized, setInitialized] = useState(false);
  const [enabledResources, setEnabledResources] = useState<EnabledResources | null>(null);

  const getUser = async () => {
    // No idea why I need this delay, but otherwise it throws an error.
    // Auth0 isLogin and isAuthenticated doesn't indicate anything so I need to wait
    await new Promise(resolve => setTimeout(resolve, 2000));
    const accessToken = await getAccessTokenSilently();
    const user: User = await getMe(accessToken);
    if (user) {
      setUser(user);
      setEnabledResources(parseEnabledResources(user));
      setInitialized(true);
    }
  }

  useEffect(() => {
    getUser();
  }, [])

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  return (
    <SidebarContext.Provider
      value={{ sidebarToggle, toggleSidebar, closeSidebar, user, enabledResources, initialized }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
