import { Typography } from '@mui/material';

function PageHeader() {

  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        Linked accounts
      </Typography>
      <Typography variant="subtitle2">
        Manage your linked accounts and permissions.
      </Typography>
    </>
  );
}

export default PageHeader;
