import React, { useState, useEffect } from 'react';
import { 
  Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Stack, TextField, Typography, Accordion, 
  AccordionSummary, AccordionDetails 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SelectChangeEvent } from '@mui/material/Select';
import { LoadingButton } from '@mui/lab';

import { AzentaSeqOrder, AzentaLibrary, AzentaPool, azentaOrderOptions, initialLibraryDefaults, initialPoolDefaults } from 'src/models/azenta';
import { updateAzentaOrder } from 'src/api/AzentaOrderQueries';
import { exportToExcel } from 'src/utils/exportToExcel';
import LinkToPartnerSite from 'src/components/Modals/LinkToPartnerSite';

import { modalStyle, azentaButton } from 'src/theme/styles';

const style = modalStyle;
import { useAuth0 } from '@auth0/auth0-react';

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '80%',
//   height: '80%',
//   bgcolor: 'background.paper',
//   border: '1px solid #000',
//   boxShadow: 24,
//   borderRadius: '16px',
//   padding: 4,
//   overflowY: 'auto'
// };

interface UpdateAzentaOrderProps {
  open: boolean;
  onClose: () => void;
  order: AzentaSeqOrder;
}

function UpdateAzentaOrder({ open, onClose, order }: UpdateAzentaOrderProps) {
  const [formValues, setFormValues] = useState<AzentaSeqOrder>(order);
  const [selectedLibraryIndex, setSelectedLibraryIndex] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [isPopulating, setIsPopulating] = useState(true);
  const [message, setMessage] = useState('');

  const { getAccessTokenSilently } = useAuth0();
  const [partnerSiteOpen, setPartnerSiteOpen] = useState(false);

  useEffect(() => {
    setIsPopulating(true);
    setTimeout(() => {
      setFormValues(order);
      setIsPopulating(false);
    }, 500);
  }, [order]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string | number | boolean | Date>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: typeof formValues[name] === 'number' ? Number(value) : value
    });
  };

  const handleAddPool = () => {
    const newPool = { ...initialPoolDefaults, pool: `Pool ${formValues.pools.length + 1}` };
    setFormValues({
      ...formValues,
      pools: [...formValues.pools, newPool]
    });
    setSelectedLibraryIndex(formValues.pools.length);
  }

  const handleAddLibrary = () => {
    const newLibrary = { ...initialLibraryDefaults, libName: `Library ${formValues.pools[selectedLibraryIndex].libraries.length + 1}` };
    const updatedPools = [...formValues.pools];
    updatedPools[selectedLibraryIndex].libraries.push(newLibrary);
    setFormValues({
      ...formValues,
      pools: updatedPools
    });
  }

  const handleUpdateOrder = async () => {
    setLoading(true);
    try {
      const { id, ...otherFields } = formValues;
      const accessToken = await getAccessTokenSilently();
      const result = await updateAzentaOrder(accessToken, id, otherFields);
      if (!result) {
        setMessage("Error updating Azenta order");
      } else {
        setMessage(`Azenta order updated successfully`);
      }
    } catch (error) {
      console.error("Error updating Azenta order:", error);
      setMessage("Error updating Azenta order");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setMessage('');
    onClose();
  };

  const handleLibraryChange = (e: SelectChangeEvent<number>) => {
    setSelectedLibraryIndex(Number(e.target.value));
  };

  const renderFormField = (key: keyof AzentaSeqOrder) => {
    const isSelectField = Object.prototype.hasOwnProperty.call(azentaOrderOptions, key);
    if (isSelectField) {
      const fieldOptions = azentaOrderOptions[key as keyof typeof azentaOrderOptions];
      return (
        <FormControl key={key} fullWidth margin="normal">
          <InputLabel>{key.charAt(0).toUpperCase() + key.slice(1)}</InputLabel>
          <Select
            name={key}
            value={formValues[key] ?? ''}
            onChange={(e) => handleInputChange(e as SelectChangeEvent<string | number | boolean | Date>)}
          >
            {fieldOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (key !== 'pools') {
      return (
        <TextField key={key} name={key}
          label={key.charAt(0).toUpperCase() + key.slice(1)}
          type={typeof order[key] === 'number' ? 'number' : 'text'}
          value={formValues[key] ?? ''}
          onChange={handleInputChange}
          fullWidth margin="normal"
        />
      );
    }
  };

  const renderPools = (pool: AzentaPool) => {
    return Object.keys(initialPoolDefaults).map(key => (
      key !== 'libraries' && (
        <TextField
          key={key}
          label={key.charAt(0).toUpperCase() + key.slice(1)}
          value={(pool as any)[key] ?? ''}
          onChange={(e) => {
            const newPools = [...formValues.pools];
            newPools[selectedLibraryIndex][key as keyof AzentaPool] = e.target.value as any;
            setFormValues({ ...formValues, pools: newPools });
          }}
          fullWidth
          margin="normal"
        />
      )
    ));
  };

  const renderLibraries = (libraries: AzentaLibrary[]) => {
    return libraries.map((library, index) => (
      <Accordion key={index}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{library.libName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {Object.keys(initialLibraryDefaults).map(key => (
            <TextField
              key={key}
              label={key.charAt(0).toUpperCase() + key.slice(1)}
              value={(library as any)[key] ?? ''}
              onChange={(e) => {
                const newPools = [...formValues.pools];
                newPools[selectedLibraryIndex].libraries[index][key as keyof AzentaLibrary] = e.target.value as any;
                setFormValues({ ...formValues, pools: newPools });
              }}
              fullWidth
              margin="normal"
            />
          ))}
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          { isPopulating ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <LoadingButton loading={true} variant="outlined" />
            </Box>
          ) : (
            <>
              <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography variant="h4" style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
                  View/Edit/Transfer Azenta Order
                </Typography>
                <br />
                <Typography variant="body1" style={{ position: 'sticky', top: 40, background: 'white', zIndex: 1 }}>
                  Edit order info, download order form, transfer data to Azenta.
                </Typography>
              </Box>
              <Stack direction="row" spacing={3} alignItems='flex-start'>
                <Box width="33%">
                  <Typography sx={{ mb: 1, textAlign: 'center', fontWeight: 'bold' }}>
                    Order Details
                  </Typography>
                  <Box sx={{ height: '45vh', overflowY: 'auto', pr: 2 }}>
                    {Object.keys(order).map(key => renderFormField(key as keyof AzentaSeqOrder))}
                  </Box>
                </Box>
                <Box width="33%" sx={{ height: '45vh', overflowY: 'auto', pr: 2 }}>
                  <Typography sx={{ mb: 1, textAlign: 'center', fontWeight: 'bold' }}>
                    Pools
                  </Typography>
                  <FormControl fullWidth margin="normal">
                    <Select
                      name="library"
                      value={selectedLibraryIndex}
                      onChange={handleLibraryChange}
                      variant='standard'
                    >
                      <MenuItem onClick={handleAddPool}>
                        + Add Pool
                      </MenuItem>
                      {formValues.pools.map((pool, index) => (
                        <MenuItem key={index} value={index}>
                          {pool.pool}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formValues.pools[selectedLibraryIndex] && renderPools(formValues.pools[selectedLibraryIndex])}
                </Box>
                <Box width="33%" sx={{ height: '45vh', overflowY: 'auto', pr: 2 }}>
                  <Typography sx={{ mb:1, textAlign: 'center', fontWeight: 'bold' }}>
                    Pool Libraries
                  </Typography>
                  {formValues.pools[selectedLibraryIndex] && renderLibraries(formValues.pools[selectedLibraryIndex].libraries)}
                  <Button onClick={handleAddLibrary} fullWidth sx={{height: '5vh'}}>
                    <Typography sx={{ textAlign: 'center' }}>
                      + Add Library
                    </Typography>
                  </Button>
                </Box>
              </Stack>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <LoadingButton loading={loading} onClick={handleUpdateOrder} variant='contained' color='success' sx={{ m: 2 }}>
                  Update Info
                </LoadingButton>
                <Button onClick={() => setPartnerSiteOpen(true)} variant='contained' 
                  sx={ azentaButton } startIcon={<img src="/static/images/companies/genewiz_logo.png" alt="excel" style={{height: "20px"}}/>}>
                  Export to Azenta
                </Button>
                <Button onClick={() => exportToExcel(formValues)} variant='contained' 
                  sx={ azentaButton } startIcon={<img src="/static/images/misc/excel.png" alt="excel" style={{height: "20px"}}/>}>
                  Download Order Form
                </Button>
                <Button onClick={handleClose} variant='contained' color='error' sx={{ m: 2 }}>
                  Close
                </Button>
              </Box>
              {message && 
                <Typography sx={{ color: message.startsWith('Error') ? 'red' : 'green', mt: 1, textAlign: 'center' }}>
                  {message}
                </Typography>
              }
            </>
          )}
        </Box>
      </Modal>
      <LinkToPartnerSite open={partnerSiteOpen} onClose={() => setPartnerSiteOpen(false)} />
    </>
  );
}
export default UpdateAzentaOrder;
