import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container, Stack, FormControl, TextField } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from 'src/models/user';
import SaveIcon from '@mui/icons-material/Save';
import UsersTable from './UsersTable';
import { Organization } from 'src/models/organization';
import { getMyOrganization, updateOrganizationName } from 'src/api/OrganizationQueries';

function MyOrganizationPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [organization, setOrganization] = useState<Organization>();
  const [organizationUsers, setOrganizationUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [savingName, setSavingName] = useState(false);

  useEffect(() => {
    fetchOrganization();
  }, [])

  const fetchOrganization = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const responseOrganization = await getMyOrganization(accessToken);
    if (responseOrganization) {
      setOrganization(responseOrganization);
      setOrganizationUsers(responseOrganization.users);
      setOrganizationName(responseOrganization.name);
      setLoading(false);
    }
  }

  const updatedOrganizationName = async () => {
    setSavingName(true);
    const accessToken = await getAccessTokenSilently();
    const responseOrganization = await updateOrganizationName(accessToken, organizationName);
    if (responseOrganization) {
      setSavingName(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>My Organization</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Stack direction='row' spacing={2} sx={{ mb: 1 }}>
              <LoadingButton loading={loading} variant='outlined' size='small' onClick={() => fetchOrganization()}>
                <RefreshIcon fontSize='small' />
              </LoadingButton>
              <FormControl size='small'>
                <TextField
                  size='small'
                  label="Organization name"
                  sx={{ width: '200px' }}
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                />
              </FormControl>
              <LoadingButton loading={savingName} variant='outlined' size='small' onClick={() => updatedOrganizationName()}>
                <SaveIcon fontSize='small' />
              </LoadingButton>
            </Stack>
            <UsersTable users={organizationUsers} setUsers={setOrganizationUsers} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default MyOrganizationPage;
