import { FC, ChangeEvent, useState, useEffect } from 'react';

import {
  Tooltip,
  Divider,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  Stack,
  Link
} from '@mui/material';
import { useLocation } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import BulkActions from './BulkActions';
import { getAllSequences } from 'src/api/SequencesQueries';
import { IDTComplexGBlockSite } from 'src/models/sequence';
import { Sequence } from 'src/models/sequence';
import Label from 'src/components/Label';
import ComplexityScoreDetails from 'src/components/Modals/ComplexityScoreDetails';

import { useAuth0 } from '@auth0/auth0-react';
import { createConstruct, getConstructScore } from 'src/api/TwistQueries';
import { ConstructData, Issue } from 'src/models/twist';
import TwistScoreDetails from 'src/components/Modals/TwistScoreDetails';


const getDifficultyLabel = (status): JSX.Element => {
  console.log(status)
  const map = {
    ERROR: {
      text: 'Error',
      color: 'error'
    },
    'NOT ACCEPTED': {
      text: 'Not accepted',
      color: 'black'
    },
    COMPLEX: {
      text: 'Complex',
      color: 'warning'
    },
    STANDARD: {
      text: 'Standard',
      color: 'success'
    }
  };

  const { text, color }: any = map[status];

  return <Label color={color}>{text}</Label>;
};

interface TwistWorkflowLocationState {
  initialSequences: Sequence[];
}

const applyPagination = (
  sequences: Sequence[],
  page: number,
  limit: number
): Sequence[] => {
  return sequences.slice(page * limit, page * limit + limit);
};

const TwistSequencesTable = () => {
  const { getAccessTokenSilently } = useAuth0();

  const location = useLocation()
  const { initialSequences } = location.state as TwistWorkflowLocationState ?? { initialSequences: [] }

  const [allSequences, setAllSequences] = useState<Sequence[]>(initialSequences);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [loading, setLoading] = useState(false);
  const [viewingIssues, setViewingIssues] = useState<Issue[] | null>(null);
  const [openScoreDetails, setOpenScoreDetails] = useState(false);
  const [constructId, setConstructId] = useState<string>('');
  const [constructsData, setConstructsData] = useState<ConstructData[] | null>(null);

  const scoreConstruct = async (constructId: string) => {
    const accessToken = await getAccessTokenSilently();
    const responseScore = await getConstructScore(accessToken, constructId);
    setConstructsData(responseScore);
  }
  useEffect(() => {
    scoreConstruct(constructId)

    const interval = setInterval(() => {
      scoreConstruct(constructId)
    }, 3000)

    return () => clearInterval(interval);
  }, [constructId])

  const createAndScoreConstruct = async () => {
    setLoading(true)
    const accessToken = await getAccessTokenSilently();
    const sequences = allSequences.map((sequence) => sequence.seq);
    const responseConstruct = await createConstruct(accessToken, sequences);
    setConstructId(responseConstruct['constructId']);
    await scoreConstruct(responseConstruct['constructId']);
    setLoading(false)
  }

  useEffect(() => {
    createAndScoreConstruct();
  }, []);



  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };


  const paginatedSequences = applyPagination(
    allSequences,
    page,
    limit
  );

  return (
    <>
      <Card>
        <Box flex={1} p={2}>
          <BulkActions constructId={constructId} refreshConstructData={scoreConstruct} constructsData={constructsData} />
        </Box>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">
                  <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                    <div>
                      Complexity
                    </div>
                    <Tooltip
                      placement="bottom"
                      title={
                        <>
                          Standard: We do not expect any difficulties in synthesizing your gene. <br /><br />
                          Complex: Some sequences may experience increased turnaround time or risk of manufacturing failure. <br /><br />
                          Not Accepted: The sequence contains elements that make it impossible for us to synthesize. Please see the explanations provided with the error message or refer to the Twist “Design Guidelines”. <br /><br />
                          Error: The sequence contains errors. Please click on the sequence to get more details on how to resolve them.
                        </>
                      }>
                      <InfoTwoToneIcon />
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedSequences.map((seq, index) => {
                return (
                  <TableRow
                    hover
                    key={seq.id}
                    sx={{ height: 65 }}
                  >
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {seq.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" onClick={() => { setViewingIssues(constructsData[index].score_data.issues); setOpenScoreDetails(true) }}>
                      <Tooltip title="View score details" arrow>
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          variant="body1"
                          fontWeight="bold"
                          color="text.secondary"
                          gutterBottom
                          noWrap
                        >
                          {constructsData && constructsData[index].score !== 'NOT_SCORED' ? getDifficultyLabel(constructsData[index].score_data.difficulty) : 'Scoring...'}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={allSequences.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>

        {viewingIssues && openScoreDetails && <TwistScoreDetails open={openScoreDetails} onClose={() => setOpenScoreDetails(false)} issues={viewingIssues} />}

      </Card>
    </>
  );
};

export default TwistSequencesTable;
