import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { useAuth0 } from '@auth0/auth0-react';
import { Resource, ResourceKey } from 'src/models/organization';
import { PageHeader } from './PageHeader';
import { getMe } from 'src/api/UserQueries';
import { User } from 'src/models/user';
import ResourcesTable from 'src/pages/admin/OrganizationResources/ResourcesTable';

function MyResourcesPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [myResources, setMyResources] = useState<Resource[]>([]);
  const [resourceKeys, setResourceKeys] = useState<ResourceKey[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMe();
  }, [])

  const fetchMe = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const responseMe: User | null = await getMe(accessToken);
    if (responseMe) {
      setMyResources(responseMe.organization?.enabledResources || []);
      setResourceKeys(responseMe.resourceKeys);
      setLoading(false);
    }
  }


  return (
    <>
      <Helmet>
        <title>My resources</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchMe()}>
              <RefreshIcon fontSize='small'/>
            </LoadingButton>
            <ResourcesTable resources={myResources} resourceKeys={resourceKeys} forOrganizations={false}/>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default MyResourcesPage;
