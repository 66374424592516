import { useState, useRef } from 'react';

import {
  Box,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  Button,
  ListItemText,
  ListItem,
  List,
  // Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';

import WhatshotIcon from '@mui/icons-material/Whatshot';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import TuneIcon from '@mui/icons-material/Tune';

import { useAuth0 } from '@auth0/auth0-react';
import { Sequence } from 'src/models/sequence';
import { runComplexityScore } from 'src/api/ComplexityScoreQueries';
import { createMultipleOptimizations } from 'src/api/OptimizationQueries';
import { runBiosecurity } from 'src/api/BiosecurityQueries';


const manufacturerIcons = {
  'idt'       : <img src = "/static/images/companies/idt_logo.png"    alt = "idt"               style = {{height: "20px"}}/>,
  'kent'      : <img src = "/static/images/companies/kent.png"        alt = "kent"              style = {{height: "20px"}}/>,
  'aise'      : <img src = "/static/images/companies/AIse_blue.png"   alt = "aise"              style = {{height: "20px"}}/>,
  'securedna' : <img src = "/static/images/companies/securedna.png"   alt = "secure dna"        style = {{height: "20px"}}/>,
  'aclid'     : <img src = "/static/images/companies/aclid.png"       alt = "aclid"             style = {{height: "20px"}}/>,
  'twist'     : <img src = "/static/images/companies/twist_dark.png"  alt = "twist biosciences" style = {{height: "20px"}}/>,
  'damp'      : <img src = "/static/images/companies/DAMP_logo.png"   alt = "damp lab"          style = {{height: "20px"}}/>,
  'azenta'    : <img src = "/static/images/companies/azenta_logo.png" alt = "azenta"            style = {{height: "20px"}}/>,
  'olon'      : <img src = "/static/images/companies/olon_logo.png"   alt = "olon"              style = {{height: "20px"}}/>,
};

const buttonConfigs = [
  {
    id: 'scorer',
    servicesMap: {
      IDT: manufacturerIcons['idt'],
    },
    description: 'Scorer: ',
  },
  {
    id: 'optimizer',
    servicesMap: {
      Kent: manufacturerIcons['kent'],
      AIse: manufacturerIcons['aise'],
    },
    description: 'Optimizer: ',
  },
  {
    id: 'screener',
    servicesMap: {
      SecureDNA: manufacturerIcons['securedna'],
      Aclid: manufacturerIcons['aclid'],
    },
    description: 'Screener: ',
  },
  {
    id: 'manufacturer',
    servicesMap: {
      'Compare options': <LeaderboardIcon sx={{height: "20px", color: 'green'}} />,
      // Azenta: manufacturerIcons['azenta'],
      Twist: manufacturerIcons['twist'],
      'DAMP Lab': manufacturerIcons['damp'],
      IDT: manufacturerIcons['idt'],
      // Olon: manufacturerIcons['olon'],
    },
    description: 'Manufacturer: ',
  },
];


const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

interface BulkActionsProps {
  selectedSequences: Sequence[];
  fetchSequences: () => void;
  selectedBulkActions: boolean;
  setOlonOpen: (value: boolean) => void;
  setIdtOpen: (value: boolean) => void;
  setAiseOpen: (value: boolean) => void;
  setRunComplexityOpen: (value: boolean) => void;
  setRunBiosecurityOpen: (value: boolean) => void;
  setRunOptimizationOpen: (value: boolean) => void;
  setRunProteinOptimizationOpen: (value: boolean) => void;
  setManufacturingFormOpen: (value: boolean) => void;
}

function BulkActions({ selectedSequences, fetchSequences,
                       selectedBulkActions, setOlonOpen, setIdtOpen, setAiseOpen, 
                       setRunComplexityOpen, setRunBiosecurityOpen, setRunOptimizationOpen, 
                       setRunProteinOptimizationOpen, setManufacturingFormOpen
}: BulkActionsProps){
  const [onMenuOpen, menuOpen] = useState<boolean>(false);
  const moreRef  = useRef<HTMLButtonElement | null>(null);
  const { getAccessTokenSilently } = useAuth0();

  const [scorer,    setScorer]    = useState<String>('');
  const [optimizer, setOptimizer] = useState<String>('');
  const [screener,  setScreener]  = useState<String>('');

  const [complexityLoading,   setComplexityLoading]   = useState(false);
  const [biosecurityLoading,  setBiosecurityLoading]  = useState(false);
  const [biosecurityMessage,  setBiosecurityMessage]  = useState('')
  const [optimizationLoading, setOptimizationLoading] = useState(false);

  const [menuState, setMenuState] = useState({
    scorer      : { anchorEl: null, selection: 'IDT' },  // TODO: preserve choice from last session
    optimizer   : { anchorEl: null, selection: 'Kent' },
    screener    : { anchorEl: null, selection: 'SecureDNA' },
    manufacturer: { anchorEl: null, selection: 'Compare options' },
  });
  // options
  const [prioritize, prioritizeScore] = useState<Boolean>(false);

  const idtComplexityScores = async (allSequences: Sequence[]) => {
    setComplexityLoading(true);
    // setAlreadyRunning(true);
    const accessToken = await getAccessTokenSilently();
    const sequenceIds = allSequences.map(sequence => sequence.id,);
    await runComplexityScore(accessToken, { sequenceIds });
    setComplexityLoading(false);
    fetchSequences();
  };

  const runOptimization = async () => {
    setOptimizationLoading(true);
    const accessToken = await getAccessTokenSilently();
    await createMultipleOptimizations(accessToken, {
      "email": "user",
      "sequences": selectedSequences,
      "sequenceType": "dna",
      "date": new Date().toISOString(),
      "parameters": [
        {
          "weight": 0.5,
          "target": "min",
          "name": "NucleotideRepeats",
          "nucleotide": "T",
          "repeats": 12
        }
      ]
    })
    // setAlreadyRun(true);
    setOptimizationLoading(false);
  }

  const runBiosecurityCheck = async () => {
    setBiosecurityLoading(true)
    setBiosecurityMessage('')
    const accessToken = await getAccessTokenSilently()
    const response = await runBiosecurity(accessToken, { ids: selectedSequences.map((sequence) => sequence.id) })
    setBiosecurityMessage(response['message'])
    setBiosecurityLoading(false)
  }

  const openMenu = (): void => {
    menuOpen(true);
  };

  const closeMenu = (): void => {
    menuOpen(false);
  };

  const handleOptions = () => {

  };

  const closeOptions = () => {

  }

  const handleClick = (event, menuId) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuId]: { ...prevState[menuId], anchorEl: event.currentTarget },
    }));
  };

  const handleClose = (menuId) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuId]: { ...prevState[menuId], anchorEl: null },
    }));
  };

  const handleMenuItemClick = (menuId, icon) => {
    setMenuState((prevState) => ({
      ...prevState,
      [menuId]: { ...prevState[menuId], selection: icon, anchorEl: null },
    }));
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" >
        {/* <Box display="flex" alignItems="center"> */}
        <Grid container spacing={4} display="flex" alignItems="center">
          <Grid item key='runWorkflow'>

            <LoadingButton
              loading={complexityLoading || biosecurityLoading || optimizationLoading}
              onClick={() => {
                idtComplexityScores(selectedSequences)
                runOptimization()
                runBiosecurityCheck()
              }}
              variant="contained"
              sx={{ color: "#053e60", backgroundColor: "#EFF6E0" }}  // TODO: Make colors dynamic
              startIcon={<WhatshotIcon style={{height: "20px"}}/>}
              disabled={!selectedBulkActions}
            >
              Run Workflow
            </LoadingButton>

          </Grid>
          <Grid item>
            <IconButton onClick={() => {}}>
              <Box sx={{fontSize: 16}}>
                Options: &nbsp;
              </Box>
              <TuneIcon />
            </IconButton>
          </Grid>
          {buttonConfigs.map(({ id, servicesMap, description }) => {
            const { anchorEl, selection } = menuState[id];
            const open = Boolean(anchorEl);

            return (
              <Grid item key={id}>
                <IconButton onClick={(event) => handleClick(event, id)} aria-controls={id} aria-haspopup="true">
                  <Box sx={{fontSize: 16}}>
                    { description } &nbsp;
                  </Box>
                  {servicesMap[selection]}
                </IconButton>
                <Menu
                  id={id}
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={() => handleClose(id)}
                >
                  {Object.entries(servicesMap).map(([key, iconComponent]) => (
                    <MenuItem key={key} onClick={() => handleMenuItemClick(id, key)}>
                      {key}&emsp;{iconComponent}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            );
          })}
        </Grid>
        <Grid item key='menu'>
          <IconButton
            color="primary"
            onClick={openMenu}
            ref={moreRef}
            sx={{ ml: 2, p: 1 }}
            disabled={!selectedBulkActions}
          >
            <MoreVertTwoToneIcon />
          </IconButton>
          <Menu
            keepMounted
            anchorEl={moreRef.current}
            open={onMenuOpen}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <List sx={{ p: 1 }} component="nav">
              <ListItem button onClick={() => setRunComplexityOpen(true)}>
                <ListItemText primary="Run complexity score..." />
              </ListItem>
              <ListItem button onClick={() => setRunOptimizationOpen(true)}>
                <ListItemText primary="Run optimization..." />
              </ListItem>
              <ListItem button onClick={() => setRunBiosecurityOpen(true)}>
                <ListItemText primary="Run biosecurity screen..." />
              </ListItem>
              <ListItem button onClick={() => window.open(`${process.env.REACT_APP_DAMP_FRONT_END}/canvas/gibsonassembly`, '_blank')}>
                <ListItemText primary="Generate manufacturing order form..." />
              </ListItem>
            </List>
          </Menu>
        </Grid>
        </Box>
    </>
  );
}

export default BulkActions;
