import { useState } from 'react';

import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

import { Sequence } from 'src/models/sequence';
import NEBAnnealing from 'src/components/Modals/NEBAnnealing';

interface BulkActionsProps {
  selectedSequences: Sequence[];
  selectedBulkActions: boolean;
}

function BulkActions({ selectedSequences, selectedBulkActions }: BulkActionsProps) {
  const [open, setOpen] = useState<boolean>(false);

  const openModal = (): void => {
    setOpen(true);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" color="text.secondary">
            Start batch workflow:
          </Typography>
          <Button
            onClick={openModal}
            variant="outlined"
            sx={{ ml: 3 }}
            startIcon={<PrecisionManufacturingIcon />}
            disabled={!selectedBulkActions}
          >
            NEB: Calc Annealing Temp
          </Button>
        </Box>
      </Box>
      <NEBAnnealing open={open} setOpen={setOpen} selectedSequences={selectedSequences} />
    </>
  );
}

export default BulkActions;