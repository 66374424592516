export const getAllOrganizations = async (accessToken: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/all`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getMyOrganization = async (accessToken: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/my-organization`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateOrganizationEnabledResources = async (accessToken: string, organizationId: string, enabledResourceIds: string[]) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/update-enabled-resources`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ enabledResourceIds }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateOrganizationName = async (accessToken: string, name: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const addAdmin = async (accessToken: string, organizationId: string, email: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/${organizationId}/add-admin`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

export const createOrganization = async (accessToken: string, name: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, enabledResourceIds: [] }),
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
}

export const updateResourceOrgKey = async (accessToken: string, body: { resourceId: string, keyId?: string, keySecret?: string }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/organizations/update-resource-key`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      const message = await data["message"];
      console.log(message);
    }
  } catch (e) {
    console.log(e);
  }
};

