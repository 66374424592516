import { useContext, useMemo } from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import LockIcon from '@mui/icons-material/Lock';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CalculateIcon from '@mui/icons-material/Calculate';
import BuildIcon from '@mui/icons-material/Build';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { RoleName } from 'src/models/user';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create(['transform', 'opacity'])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar, user, enabledResources } = useContext(SidebarContext);
  const isUser = useMemo(() => user?.roles?.find((role) => role.name === RoleName.USER), [user?.roles]);
  const isOrganizationAdmin = useMemo(() => user?.roles?.find((role) => role.name === RoleName.ORGANIZATION_ADMIN), [user?.roles]);
  const isSuperAdmin = useMemo(() => user?.roles?.find((role) => role.name === RoleName.SUPER_ADMIN), [user?.roles]);

  return (
    <>
      <MenuWrapper>
        {isUser &&
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Store
              </ListSubheader>
            }>
            <SubMenuWrapper>
              <List component="div">
                {enabledResources?.SEQUENCES && enabledResources?.DATA &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/sequences/"
                      startIcon={<AllInclusiveIcon />}
                    >
                      Data
                    </Button>
                  </ListItem>}
              </List>
            </SubMenuWrapper>
          </List>}
        {isUser &&
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Biosecurity
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                {enabledResources?.SECURE_DNA &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tools/secure-dna"
                      startIcon={<LockIcon />}
                    >
                      SecureDNA
                    </Button>
                  </ListItem>}
                {enabledResources?.ACLID &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tools/aclid"
                      startIcon={<LockIcon />}
                    >
                      Aclid
                    </Button>
                  </ListItem>}
              </List>
            </SubMenuWrapper>
          </List>}
        {isUser &&
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Services
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                {enabledResources?.AZENTA &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/services/azenta"
                      startIcon={<PrecisionManufacturingIcon />}
                    >
                      Azenta
                    </Button>
                  </ListItem>}
                {enabledResources?.IDT &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tools/idt"
                      startIcon={<PrecisionManufacturingIcon />}
                    >
                      IDT
                    </Button>
                  </ListItem>}
                  {enabledResources?.TWIST &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/services/twist"
                      startIcon={<PrecisionManufacturingIcon />}
                    >
                      Twist
                    </Button>
                  </ListItem>}
                {/* <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/tools/olon"
                    startIcon={<PrecisionManufacturingIcon />}
                  >
                    Olon
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/services/neb"
                    startIcon={<PrecisionManufacturingIcon />}
                  >
                    NEB
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/services/blast"
                    startIcon={<PrecisionManufacturingIcon />}
                  >
                    BLAST
                  </Button>
                </ListItem> */}
                 {enabledResources?.WORKFLOWS &&<ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/services/workflows"
                    startIcon={<PrecisionManufacturingIcon />}
                  >
                    Workflows
                  </Button>
                </ListItem>
              }
              </List>
            </SubMenuWrapper>
          </List>}
        {isUser &&
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Optimizers
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                {/* <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/tools/AIse"
                    startIcon={<CalculateIcon />}
                  >
                    AIse
                  </Button>
                </ListItem> */}
                {enabledResources?.OPTIMIZATIONS &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tools/university-of-kent"
                      startIcon={<CalculateIcon />}
                    >
                      University of Kent
                    </Button>
                  </ListItem>}
              </List>
            </SubMenuWrapper>
          </List>}
        {isUser &&
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Tools
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                {/* <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/tools/benchling"
                    startIcon={<BuildIcon />}
                  >
                    Benchling
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/tools/geneious"
                    startIcon={<BuildIcon />}
                  >
                    Geneious
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component="a"
                    href="https://auth.kernel.asimov.com"
                    target="_blank"
                    startIcon={<BuildIcon />}
                    endIcon={<OpenInNewIcon />}
                  >
                    Kernel
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component="a"
                    href="https://damplab.sail.codes/"
                    target="_blank"
                    startIcon={<BuildIcon />}
                    endIcon={<OpenInNewIcon />}
                  >
                    DAMP Canvas
                  </Button>
                </ListItem> */}
                {enabledResources?.DAMP && <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/tools/damp"
                    startIcon={<BuildIcon />}
                  >
                    DAMP Canvas Upload
                  </Button>
                </ListItem>
                }
                {enabledResources?.ASSEMBLY && <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/tools/assemble"
                    startIcon={<BlurCircularIcon />}
                  >
                    DNA Construct Assembly
                  </Button>
                </ListItem>
                }
              </List>
            </SubMenuWrapper>
          </List>}
        {isOrganizationAdmin && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Admin
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                {enabledResources?.SECURE_DNA &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/tools/secure-dna-admin"
                      startIcon={<SupervisorAccountIcon />}
                    >
                      SecureDNA Admin
                    </Button>
                  </ListItem>}
                {enabledResources?.DOCUMENTS &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/admin/documents"
                      startIcon={<SupervisorAccountIcon />}
                    >
                      Document approval
                    </Button>
                  </ListItem>}
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/admin/orders"
                    startIcon={<SupervisorAccountIcon />}
                  >
                    Orders
                  </Button>
                </ListItem>
                {enabledResources?.ORGANIZATIONS &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/admin/my-organization"
                      startIcon={<SupervisorAccountIcon />}
                    >
                      My Organization
                    </Button>
                  </ListItem>}
                {enabledResources?.ORGANIZATIONS &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/admin/organization-resources"
                      startIcon={<SupervisorAccountIcon />}
                    >
                      Organization resources
                    </Button>
                  </ListItem>}
              </List>
            </SubMenuWrapper>
          </List>)}
        {isSuperAdmin && (
          <List
            component="div"
            subheader={
              <ListSubheader component="div" disableSticky>
                Super admin
              </ListSubheader>
            }
          >
            <SubMenuWrapper>
              <List component="div">
                {enabledResources?.LOGS &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/super-admin/logs"
                      startIcon={<SupervisedUserCircleIcon />}
                    >
                      Activity tracker
                    </Button>
                  </ListItem>}
                {enabledResources?.ROLES &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/super-admin/roles"
                      startIcon={<SupervisedUserCircleIcon />}
                    >
                      Roles and permissions
                    </Button>
                  </ListItem>}
                {enabledResources?.USERS &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/super-admin/users"
                      startIcon={<SupervisedUserCircleIcon />}
                    >
                      Users
                    </Button>
                  </ListItem>}
                {enabledResources?.ORGANIZATIONS &&
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/super-admin/organizations"
                      startIcon={<SupervisedUserCircleIcon />}
                    >
                      Organizations
                    </Button>
                  </ListItem>}
              </List>
            </SubMenuWrapper>
          </List>)}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
