import { useMemo } from 'react';
import { Box, Modal, Stack, Typography } from '@mui/material';
import { Sequence } from 'src/models/sequence';
import { Issue } from 'src/models/twist';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 3,
};

interface TwistScoreDetailsProps {
  open: boolean
  onClose: () => void
  issues: Issue[]
}

function TwistScoreDetails({ onClose, issues, open }: TwistScoreDetailsProps) {

  const handleClose = () => {
    onClose();
  };
  console.log(issues);
  const errors = useMemo(() => issues.filter(issue => issue.severity === 'ERROR'), [issues]);
  const warnings = useMemo(() => issues.filter(issue => issue.severity === 'WARN'), [issues]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style} >
        <Stack direction="column" spacing={3} sx={{overflowY: 'scroll', height: '100%'}}>
          <Typography variant="h4" align="center">
            Sequence Analysis Report
          </Typography>

          <Typography variant="h4">Errors</Typography>
          {errors.length > 0 ? (
            errors.map((error, index) => (
              <Stack direction="column" spacing={1} key={index}>
                <Typography variant="body1">
                  <b>Title:</b> {error.title}
                </Typography>
                <Typography variant="body2">
                  <b>Description:</b> {error.description}
                </Typography>
                {error.location && (
                  <Typography variant="body2">
                    <b>Location:</b> {`Start: ${error.location.start}, End: ${error.location.end}`}
                  </Typography>
                )}
              </Stack>
            ))
          ) : (
            <Typography variant="body2">No errors found.</Typography>
          )}

          <Typography variant="h4">Warnings</Typography>
          {warnings.length > 0 ? (
            warnings.map((warning, index) => (
              <Stack direction="column" spacing={1} key={index}>
                <Typography variant="body1">
                  <b>Title:</b> {warning.title}
                </Typography>
                <Typography variant="body2">
                  <b>Description:</b> {warning.description}
                </Typography>
                {warning.location && (
                  <Typography variant="body2">
                    <b>Location:</b> {`Start: ${warning.location.start}, End: ${warning.location.end}`}
                  </Typography>
                )}
              </Stack>
            ))
          ) : (
            <Typography variant="body2">No warnings found.</Typography>
          )}
        </Stack>
      </Box>
    </Modal>
  );
}
export default TwistScoreDetails;
