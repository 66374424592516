import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { useAuth0 } from '@auth0/auth0-react';
import { Organization } from 'src/models/organization';
import { getAllOrganizations } from 'src/api/OrganizationQueries';
import OrganizationsTable from './OrganizationsTable';

function OrganizationsPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchOrganizations();
  }, [])

  const fetchOrganizations = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const responseOrganizations = await getAllOrganizations(accessToken);
    if (responseOrganizations) {
      setOrganizations(responseOrganizations);
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Organizations</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchOrganizations()}>
              <RefreshIcon fontSize='small'/>
            </LoadingButton>
            <OrganizationsTable organizations={organizations} setOrganizations={setOrganizations}/>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default OrganizationsPage;
