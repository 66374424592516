import { FC, ChangeEvent, useState, useEffect } from 'react';

import {
  Tooltip,
  Divider,
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  Stack,
  Link
} from '@mui/material';
import { useLocation } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import BulkActions from './BulkActions';
import { getAllSequences } from 'src/api/SequencesQueries';
import { IDTComplexGBlockSite } from 'src/models/sequence';
import { Sequence } from 'src/models/sequence';
import Label from 'src/components/Label';
import OlonOrder from 'src/components/Modals/OlonOrder';
import IDTOrder from 'src/components/Modals/IDTOrder';
import AIseOrder from 'src/components/Modals/AIseOrder';
import RunBiosecurity from 'src/components/Modals/RunBiosecurity';
import RunComplexityScore from 'src/components/Modals/RunComplexityScore';
import RunOptimization from 'src/components/Modals/RunOptimization';
import RunProteinOptimization from 'src/components/Modals/RunProteinOptimization';
import BiosecurityDetails from 'src/components/Modals/BiosecurityDetails';
import ComplexityScoreDetails from 'src/components/Modals/ComplexityScoreDetails';
import OptimizationDetails from 'src/components/Modals/OptimizationDetails';
import ProteinOptimizationDetails from 'src/components/Modals/ProteinOptimizationDetails';
import ManufacturingDetails from 'src/components/Modals/ManufacturingDetails';
import { useAuth0 } from '@auth0/auth0-react';


const getStatusLabel = (status): JSX.Element => {
  const map = {
    denied: {
      text: 'Denied',
      color: 'error'
    },
    success: {
      text: 'Success',
      color: 'success'
    },
    pending: {
      text: 'Pending',
      color: 'warning'
    },
    granted:  {
      text: 'Granted',
      color: 'success'
    }
  };

  console.log('status: ', status);
  const { text, color }: any = map[status];

  return <Label color={color}>{text}</Label>;
};

interface CmoWorkflowLocationState {
  initialSequences: Sequence[];
}

const applyPagination = (
  sequences: Sequence[],
  page: number,
  limit: number
): Sequence[] => {
  return sequences.slice(page * limit, page * limit + limit);
};

const CmoSequencesTable = () => {
  const { getAccessTokenSilently } = useAuth0();

  const location = useLocation()
  const { initialSequences } = location.state as CmoWorkflowLocationState ?? { initialSequences: [] }

  const [allSequences, setAllSequences] = useState<Sequence[]>(initialSequences);
  const [selectedSequences, setSelectedSequences] = useState<Sequence[]>([]);
  const selectedBulkActions = selectedSequences.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [loading, setLoading] = useState(false);
  const [viewingSequence, setViewingSequence] = useState<Sequence | null>(null);

  const [openIDTModal,  setOpenIDTModal]  = useState(false);
  const [openOlonModal, setOpenOlonModal] = useState(false);
  const [openAiseModal, setOpenAiseModal] = useState(false);

  const [openRunBiosecurityModal,         setOpenRunBiosecurityModal]         = useState(false);
  const [openRunComplexityModal,          setOpenRunComplexityModal]          = useState(false);
  const [openRunOptimizationModal,        setOpenRunOptimizationModal]        = useState(false);
  const [openRunProteinOptimizationModal, setOpenRunProteinOptimizationModal] = useState(false);
  const [openRunManufacturingForm,        setOpenRunManufacturingForm]        = useState(false);

  const [openBiosecurityDetails,         setOpenBiosecurityDetails]         = useState(false);
  const [openComplexityDetails,          setOpenComplexityDetails]          = useState(false);
  const [openOptimizationDetails,        setOpenOptimizationDetails]        = useState(false);
  const [openProteinOptimizationDetails, setOpenProteinOptimizationDetails] = useState(false);
  const [openManufacturingForm,          setOpenManufacturingForm]          = useState(false);
  const [openManufacturingDetails,       setOpenManufacturingDetails]       = useState(false);


  useEffect(() => {
    fetchSequences();
  }, [])

  const fetchSequences = async () => {
    try {
      setLoading(true)
      const accessToken = await getAccessTokenSilently();
      const sequencesResponse = await getAllSequences(accessToken);
      if (sequencesResponse) {
        // Update the allSequences
        const updatedAllSequences = allSequences.map((sequence) => {
          const updatedSequence = sequencesResponse.find((seq) => seq.id === sequence.id);
          if (updatedSequence) {
            return updatedSequence;
          }
          return sequence;
        });
        setAllSequences(updatedAllSequences);
        setLoading(false)
      }
    } catch (error) {
      console.error('Error fetching sequences:', error);
    }
  };

  const getTotalScore = (seq: Sequence) => {
    const block: IDTComplexGBlockSite[] = seq.idtComplexGBlock;
    try {
      if (block.length === 0) {
        return '...';
      } else if (block.length === 1 && block[0].score === 0) {
        return 'No Issues';
      }
      return block.reduce((acc, item) => acc + item.score, 0).toFixed(2);
    } catch (error) {
      console.error('Error calculating total score:', error);
      return '...';
    }
  };

  const getScoreColor = (val: number | string) => {
    if (val === '...') {
      return 'black';
    }
    const score = Number(val);
    if (score > 10.0) {
      return 'red';
    }
    return 'green';
  };

  const handleClickOpen = (sequence: Sequence) => {
    setViewingSequence(sequence);
  };

  const handleSelectAllSequences = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedSequences(
      event.target.checked
        ? allSequences
        : []
    );
  };

  const handleSelectOneSequence = (
    event: ChangeEvent<HTMLInputElement>,
    sequenceId: string
  ): void => {
    const foundSequence = allSequences.find((seq) => seq.id === sequenceId);
    const alreadySelectedOrder = selectedSequences.filter((order) => order.id === sequenceId);
    if (alreadySelectedOrder.length === 0) {
      setSelectedSequences([...selectedSequences, foundSequence]);
    } else {
      setSelectedSequences(selectedSequences.filter((order) => order.id !== sequenceId));
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleCopyToClipboard = (id: string) => {
    navigator.clipboard.writeText(id);
  };

  const paginatedSequences = applyPagination(
    allSequences,
    page,
    limit
  );
  const selectedSomeSequences =
    selectedSequences.length > 0 &&
    selectedSequences.length < allSequences.length;
  const selectedAllSequences =
    selectedSequences.length === allSequences.length;
  const theme = useTheme();

  return (
    <>
      <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchSequences()}>
        <RefreshIcon fontSize='small' />
      </LoadingButton>

      <Card>
        <Box flex={1} p={2}>
          <BulkActions
            selectedSequences={selectedSequences}
            fetchSequences={fetchSequences}
            selectedBulkActions={selectedBulkActions}
            setOlonOpen={setOpenOlonModal}
            setIdtOpen={setOpenIDTModal}
            setAiseOpen={setOpenAiseModal}
            setRunBiosecurityOpen={setOpenRunBiosecurityModal}
            setRunComplexityOpen={setOpenRunComplexityModal}
            setRunOptimizationOpen={setOpenRunOptimizationModal}
            setRunProteinOptimizationOpen={setOpenRunProteinOptimizationModal}
            setManufacturingFormOpen={setOpenManufacturingForm}
          />
        </Box>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={selectedAllSequences}
                    indeterminate={selectedSomeSequences}
                    onChange={handleSelectAllSequences}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="center">
                  <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                    <div>
                      Complexity<br/>score
                    </div>
                    <Tooltip
                      placement="bottom"
                      title="Complexity score is powered by IDT. Optimize your sequence to lower it. Scores higher than 10 are considered of high complexity and CMOs might reject it.">
                      <InfoTwoToneIcon />
                    </Tooltip>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                    <div>
                      Codon<br/>optimizations
                    </div>
                    <Tooltip
                      placement="bottom"
                      title="Sequence optimizations are powered by University of Kent. When an optimization is run, it will try to fix the issues mentioned by the complexity score.">
                      <InfoTwoToneIcon />
                    </Tooltip>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                    <div>
                      Biosecurity<br/>status
                    </div>
                    <Tooltip
                      placement="bottom"
                      title="Biosecurity checks are powered by SecureDNA. When a sequence is screened and no pathogens are found, the sequence is granted the Passed status. Sequences that don't pass the security check are likely to be rejected by CMOs.">
                      <InfoTwoToneIcon />
                    </Tooltip>
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                    <div>
                      Manufacturing<br/>Options
                    </div>
                    <Tooltip
                      placement="bottom"
                      title="Compare different manufacturers in terms of estimated quality and cost. Then pipe your optimized/screened sequence to the chosen manufacturer/experiment type.">
                      <InfoTwoToneIcon />
                    </Tooltip>
                  </Stack>
                </TableCell>
                {/* <TableCell align="center">
                  <Stack direction='row' alignItems='center' justifyContent='center' spacing={1}>
                    <div>
                      Protein<br/>optimizations
                    </div>
                    <Tooltip
                      placement="bottom"
                      title="Protein optimizations are powered by AIse.">
                      <InfoTwoToneIcon />
                    </Tooltip>
                  </Stack>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedSequences.map((seq) => {
                const isSequenceSelected = selectedSequences.filter((selected) => selected.id === seq.id).length > 0;
                return (
                  <TableRow
                    hover
                    key={seq.id}
                    selected={isSequenceSelected}
                    sx={{height: 65}}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSequenceSelected}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                          handleSelectOneSequence(event, seq.id)
                        }
                        value={isSequenceSelected}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {seq.name}
                        <Tooltip title="Copy Sequence ID" arrow placement="right">
                          <IconButton
                            onClick={() => handleCopyToClipboard(seq.id)}
                            size="small"
                            sx={{ml:1}}
                          >
                            <ContentCopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                    <TableCell align="center" onClick={() => { setViewingSequence(seq); setOpenComplexityDetails(true) }}>
                      <Tooltip title="View complexity details" arrow>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.secondary"
                          gutterBottom
                          noWrap
                        >
                          <Link color={getScoreColor(getTotalScore(seq))} style={{ cursor: "pointer" }}>
                            {getTotalScore(seq)}
                          </Link>
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" onClick={() => { setViewingSequence(seq); setOpenOptimizationDetails(true) }}>
                      {seq.optimizations && seq.optimizations.length > 0 ?
                        <Tooltip title="View optimizations" arrow>
                          <IconButton
                            sx={{
                              '&:hover': { background: theme.colors.secondary.lighter },
                              color: theme.palette.secondary.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => handleClickOpen(seq)}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        :
                        <Typography variant="body1">
                          No optimizations
                        </Typography>
                      }
                    </TableCell>
                    {seq.biosecurity ? <TableCell align="center" onClick={() => { setViewingSequence(seq); setOpenBiosecurityDetails(true) }}>
                      <Tooltip title="View biosecurity details" arrow>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          sx={{ cursor: "pointer" }}
                          gutterBottom
                          noWrap
                        >
                          {getStatusLabel(seq.biosecurity.status.toLowerCase())}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                      :
                      <TableCell align="center">
                        <Typography color="text.primary" variant="body1">No biosecurity run</Typography>
                      </TableCell>}
                    {seq.biosecurity ? <TableCell align="center" onClick={() => { setViewingSequence(seq); setOpenManufacturingDetails(true) }}>
                      <Tooltip title="View manufacturing details" arrow>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          color="text.primary"
                          sx={{ cursor: "pointer" }}
                          gutterBottom
                          noWrap
                        >
                          Compare...
                        </Typography>
                      </Tooltip>
                    </TableCell>
                      :
                      <TableCell align="center">
                        <Typography color="text.secondary" variant="body1">Compare...</Typography>
                      </TableCell>}
                    {/* <TableCell align="center" onClick={() => { setViewingSequence(seq); setOpenProteinOptimizationDetails(true) }}>
                      {seq.optimizations && seq.optimizations.length > 0 ?
                        <Tooltip title="View optimizations" arrow>
                          <IconButton
                            sx={{
                              '&:hover': { background: theme.colors.secondary.lighter },
                              color: theme.palette.secondary.main
                            }}
                            color="inherit"
                            size="small"
                            onClick={() => handleClickOpen(seq)}
                          >
                            <VisibilityIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        :
                        <Typography variant="body1">
                          No optimizations
                        </Typography>
                      }
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={allSequences.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
        {selectedBulkActions && <OlonOrder open={openOlonModal} onClose={() => setOpenOlonModal(false)} sequences={selectedSequences} />}
        {selectedBulkActions && <IDTOrder open={openIDTModal} onClose={() => setOpenIDTModal(false)} sequences={selectedSequences} />}
        {selectedBulkActions && <AIseOrder open={openAiseModal} onClose={() => setOpenAiseModal(false)} sequences={selectedSequences} />}

        {selectedBulkActions && <RunBiosecurity open={openRunBiosecurityModal} onClose={() => setOpenRunBiosecurityModal(false)} sequences={selectedSequences} />}
        {selectedBulkActions && <RunComplexityScore open={openRunComplexityModal} onClose={() => setOpenRunComplexityModal(false)} sequences={selectedSequences} />}
        {selectedBulkActions && <RunOptimization open={openRunOptimizationModal} onClose={() => setOpenRunOptimizationModal(false)} sequences={selectedSequences} />}
        {selectedBulkActions && <RunProteinOptimization open={openRunProteinOptimizationModal} onClose={() => setOpenRunProteinOptimizationModal(false)} sequences={selectedSequences} />}

        {viewingSequence && openBiosecurityDetails && <BiosecurityDetails open={openBiosecurityDetails} onClose={() => setOpenBiosecurityDetails(false)} sequence={viewingSequence} />}
        {viewingSequence && openComplexityDetails && <ComplexityScoreDetails open={openComplexityDetails} onClose={() => setOpenComplexityDetails(false)} sequence={viewingSequence} />}
        {viewingSequence && openOptimizationDetails && <OptimizationDetails open={openOptimizationDetails} onClose={() => setOpenOptimizationDetails(false)} sequence={viewingSequence} />}
        {viewingSequence && openProteinOptimizationDetails && <ProteinOptimizationDetails open={openProteinOptimizationDetails} onClose={() => setOpenProteinOptimizationDetails(false)} sequence={viewingSequence} />}
        {viewingSequence && openManufacturingDetails && <ManufacturingDetails open={openManufacturingDetails} onClose={() => setOpenManufacturingDetails(false)} sequence={viewingSequence} />}
      </Card>
    </>
  );
};

export default CmoSequencesTable;
