import { useMemo } from 'react';
import { Box, Modal, Stack, Typography } from '@mui/material';
import { RequestLog } from 'src/models/logs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  maxWidth: "80%",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  p: 4,
};

interface RequestLogDetailsProps {
  onClose: () => void;
  open: boolean;
  log: RequestLog;
}

function RequestLogDetails({ onClose, log, open }: RequestLogDetailsProps) {
  const handleClose = () => {
    onClose();
  };

  const detailsJSON = useMemo(() => JSON.parse(log.details), [log])
  const hasBodyOrQueryParams = useMemo(() => Object.keys(detailsJSON.body).length !== 0 || Object.keys(detailsJSON.query).length !== 0, [detailsJSON])

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ ...style, height: hasBodyOrQueryParams ? '80%' : undefined }}>
          <Stack spacing={2} direction="column" alignItems="center" sx={{ height: '100%', overflow: 'auto' }}>
            <Typography variant="h4" >
              Log details
            </Typography>
            <Typography variant="body1" sx={{ width: '100%' }} >
              <pre>
                {hasBodyOrQueryParams ? JSON.stringify(detailsJSON, null, 2) : "No details to show"}
              </pre>
            </Typography>

          </Stack>
        </Box>
      </Modal>
    </>
  );
}
export default RequestLogDetails;
