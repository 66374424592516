import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import SeqViz from 'seqviz';
import { Box, Button, CircularProgress, IconButton, Modal, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { Sequence } from 'src/models/sequence';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useEffect, useState } from 'react';
import SequenceViewer from './SequenceViewer';
import SaveIcon from '@mui/icons-material/Save';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { createSequence, updateSequence } from 'src/api/SequencesQueries';
import { useAuth0 } from '@auth0/auth0-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height: "80%",
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  p: 4
};

interface OptimizationDetailsProps {
  onClose: () => void;
  open: boolean;
  sequence: Sequence;
}

function OptimizationDetails({ onClose, sequence, open }: OptimizationDetailsProps) {
  const [openViewer, setOpenViewer] = useState(false);
  const [viewingSequence, setViewingSequence] = useState<string | null>(null);
  const [loadingReplace, setLoadingReplace] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();

  const handleClose = () => {
    onClose();
  };

  const handleClickOpen = (sequence: string) => {
    setViewingSequence(sequence);
    setOpenViewer(true);
  };

  useEffect(() => {
    setViewingSequence(null);
    setOpenViewer(false);
  }, [open]);

  const replaceSequenceWithCandidate = async (candidate: string) => {
    setCurrentCandidate(candidate);
    setLoadingReplace(true);
    const accessToken = await getAccessTokenSilently();
    await updateSequence(accessToken, sequence.id, {
      "name": `${sequence.name}*`,
      "seq": candidate
    });
    //TODO: refresh UI locally
    setLoadingReplace(false);
  }

  const createNewSequenceWithCandidate = async (candidate: string) => {
    setCurrentCandidate(candidate);
    setLoadingCreate(true);
    const accessToken = await getAccessTokenSilently();
    await createSequence(accessToken, {
      "seq": candidate,
      "name": `${sequence.name}*`,
      "type": "dna",
      "annotations": []
    });
    //TODO: refresh UI locally
    setLoadingCreate(false)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <Stack spacing={2} direction="column" alignItems="center" sx={{ height: "100%" }}>
            <Typography variant="h4" >
              Optimizations
            </Typography>
            <Typography variant="body1" >
              A list of optimization candidates with their optimization score.
              You can view the candidates and save them as new sequences or replace the original one.
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Sequence</TableCell>
                    <TableCell>Optimization score</TableCell>
                    <TableCell align='right'>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sequence.optimizations.map((optimization, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                      >
                        <TableCell>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            # {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                            sx={{ maxWidth: 300, textOverflow: "ellipsis", textTransform: 'uppercase' }}
                          >
                            {optimization.sequence}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="text.primary"
                            gutterBottom
                            noWrap
                          >
                            {Number(parseFloat(optimization.score.total_score).toFixed(4)) * 100} / 100
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Stack direction="row" spacing={1} alignItems='center' justifyContent='end'>
                            <Tooltip title="View candidate's sequence" arrow>
                              <IconButton
                                sx={{
                                  '&:hover': { background: theme.colors.secondary.lighter },
                                  color: theme.palette.secondary.main
                                }}
                                color="inherit"
                                size="small"
                                onClick={() => handleClickOpen(optimization.sequence)}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Save as new sequence" arrow>
                              {loadingCreate && currentCandidate === optimization.sequence ?
                                <CircularProgress size="1rem" /> :
                                <IconButton
                                  sx={{
                                    '&:hover': { background: theme.colors.secondary.lighter },
                                    color: theme.palette.secondary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                  onClick={() => createNewSequenceWithCandidate(optimization.sequence)}
                                >
                                  <SaveIcon fontSize="small" />
                                </IconButton>
                              }
                            </Tooltip>
                            <Tooltip title="Replace original sequence with this one" arrow>
                              {loadingReplace && currentCandidate === optimization.sequence ?
                                <CircularProgress size="1rem" /> :
                                <IconButton
                                  sx={{
                                    '&:hover': { background: theme.colors.secondary.lighter },
                                    color: theme.palette.secondary.main
                                  }}
                                  color="inherit"
                                  size="small"
                                  onClick={() => replaceSequenceWithCandidate(optimization.sequence)}
                                >
                                  <SaveAltIcon fontSize="small" />
                                </IconButton>}
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Box>
      </Modal>
      {viewingSequence && <SequenceViewer open={openViewer} onClose={() => setOpenViewer(false)} sequence={viewingSequence} linear={true} />}
    </>
  );
}
export default OptimizationDetails;
