import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { Box, Modal, Stack, Typography } from '@mui/material';
import { runBiosecurity } from 'src/api/BiosecurityQueries';
import { Sequence } from 'src/models/sequence';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 4
};

interface RunBiosecurityProps {
  open: boolean
  onClose: () => void
  sequences: Array<Sequence>
}

function RunBiosecurity({ onClose, sequences, open }: RunBiosecurityProps) {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')

  const { getAccessTokenSilently } = useAuth0();

  const handleClose = () => {
    setMessage('');
    onClose();
  };

  const runCheck = async () => {
    setLoading(true)
    setMessage('')
    const accessToken = await getAccessTokenSilently()
    const response = await runBiosecurity(accessToken, { ids: sequences.map((sequence) => sequence.id) })
    setMessage(response['message'])
    setLoading(false)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack direction="column" spacing={3} alignItems='center'>
          <Typography variant="h4" >
            Run Biosecurity check
          </Typography>
          <Typography variant="body1" >
            Powered by SecureDNA, the biosecurity check evaluates the safety of a sequence by looking
            for known pathogenic sequences and toxins.
          </Typography>
          <Typography variant="body1" sx={{ width: '100%' }}>
            Sequences: {sequences.map((sequence, index) => {
              return <Typography sx={{ fontWeight: 'bold' }} key={index}>{sequence.name}</Typography>
            })}
          </Typography>
          {message && <Typography sx={{ color: 'green' }}>{message}</Typography>}
          <LoadingButton loading={loading} variant='contained' style={{ marginTop: '20px' }} onClick={() => runCheck()}>
            Run
          </LoadingButton>

        </Stack>
      </Box>
    </Modal>
  );
}
export default RunBiosecurity;
