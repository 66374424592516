import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { modalStyle, azentaButton } from 'src/theme/styles';

interface LinkToPartnerSiteProps {
  open: boolean;
  onClose: () => void;
}

const style = {...modalStyle, width: '50%', height: '30%'};

function LinkToPartnerSite(props: LinkToPartnerSiteProps) {

  const { open, onClose } = props;

  const handleClose = () => {
    onClose();
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Stack direction="column" spacing={3} alignItems='center'>

            <Typography variant="h4" >
                Connect to Azenta
            </Typography>

            <Typography variant="body1" >
                Looks like you already have a linked Azenta account.
            </Typography>

            <Box sx={{display: 'flex', direction: 'row'}}>
              <Button component='a' href='https://clims4.genewiz.com/RegisterAccount/Index' target='_blank' variant='contained' 
                sx={ azentaButton } endIcon={<OpenInNewIcon />}>
                  Create/Link New Azenta Account
              </Button>
              <Button component='a' href='https://clims4.genewiz.com/DynamicForm/CreateView?lobId=3&serviceId=3160' target='_blank' variant='contained' 
                sx={ azentaButton } endIcon={<OpenInNewIcon />}>
                  Transfer Data to Order Form
              </Button>
              <Button onClick={()=>handleClose()} variant='contained' color='error' 
                sx={{m: 2}}>
                  Close
              </Button>
            </Box>
            
        </Stack>
      </Box>
    </Modal>
  )
}

export default LinkToPartnerSite;
