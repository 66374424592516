import { Sequence } from "src/models/sequence"

// TODO: Move to models folder
export enum DataType {
  Sequence = "Sequence",
  File = "File"
}

export interface File {
  filename: string
  mimeType: string
  size: number
  data: string
  createAt?: string
  updateAt?: string
}

// TODO: Put name back to the DTO
export interface Data {
  name?: string
  type: DataType
  attributes: File | Sequence
}

export const getAllData = async (accessToken: string): Promise<Data[] | undefined> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/data`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data
    } else {
      const message = await data["message"]
      console.log(message)
    }
  } catch (e) {
    console.log(e)
  }
}

export const createData = async (accessToken: string, dataInput: Data) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/data`,
      {
        method: "POST",
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(dataInput)
      }
    );
    const data = await response.json();
    if (response.ok) {
      return data
    } else {
      const message = await data["message"]
      console.log(message)
    }
  } catch (e) {
    console.log(e)
  }
}
