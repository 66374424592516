import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import Status404 from './pages/errors/Status404';
import ComingSoon from './pages/errors/ComingSoon';
import Aclid from './pages/tools/Aclid';
import GoldenGateWorkflow from './pages/sequences/GoldenGateWorkflow';
import Logs from './pages/superAdmin/Logs';
import DocumentsPage from './pages/admin/Documents';
import Login from './pages/auth/Login';
import AllSequences from './pages/sequences/AllSequences';
import CmoWorkflow from './pages/sequences/CmoWorkflow';
import Azenta from './pages/azenta/Azenta';
import SecureDna from './pages/tools/SecureDna';
import SecureDnaAdmin from './pages/tools/SecureDnaAdmin';
import LinkedAccounts from './pages/user/LinkedAccounts';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import OrdersPage from './pages/admin/Orders';
import RolesPage from './pages/superAdmin/Roles';
import UsersPage from './pages/superAdmin/Users';
import OrganizationsPage from './pages/superAdmin/Organizations';
import MyOrganizationPage from './pages/admin/MyOrganization';
import OrganizationResourcesPage from './pages/admin/OrganizationResources';
import MyResourcesPage from './pages/user/MyResources';
import NEB from './pages/neb/NEB';
import BLAST from './pages/blast/BLAST';
import DAMP from './pages/damp/DAMP';
import FlowChart from './pages/workflows/FlowChart';
import DNAAssemblyPage from './pages/tools/DNAAssembly';
import TwistWorkflow from './pages/sequences/TwistWorkflow';
import Twist from './pages/twist';
import ComponentLibrary from './pages/sequences/ComponentLibrary';

const ProtectedLoginPage = withAuthenticationRequired(Login);
const ProtectedAllSequences = withAuthenticationRequired(AllSequences);
const ProtectedCmoWorkflow = withAuthenticationRequired(CmoWorkflow);
const ProtectedTwistWorkflow = withAuthenticationRequired(TwistWorkflow);
const ProtectedGoldenGateWorkflow = withAuthenticationRequired(GoldenGateWorkflow);
const ProtectedAzenta = withAuthenticationRequired(Azenta);
const ProtectedTwist = withAuthenticationRequired(Twist);
const ProtectedSecureDna = withAuthenticationRequired(SecureDna);
const ProtectedSecureDnaAdmin = withAuthenticationRequired(SecureDnaAdmin);
const ProtectedAclid = withAuthenticationRequired(Aclid);
const ProtectedLinkedAccounts = withAuthenticationRequired(LinkedAccounts);
const ProtectedLogs = withAuthenticationRequired(Logs);
const ProtectedDocumentsPage = withAuthenticationRequired(DocumentsPage);
const ProtectedOrdersPage = withAuthenticationRequired(OrdersPage);
const ProtectedRolesPage = withAuthenticationRequired(RolesPage);
const ProtectedUsersPage = withAuthenticationRequired(UsersPage);
const ProtectedOrganizationsPage = withAuthenticationRequired(OrganizationsPage);
const ProtectedMyOrganizationPage = withAuthenticationRequired(MyOrganizationPage);
const ProtectedOrganizationResourcesPage = withAuthenticationRequired(OrganizationResourcesPage);
const ProtectedMyResourcesPage = withAuthenticationRequired(MyResourcesPage);
const ProtectedNEBPage = withAuthenticationRequired(NEB);
const ProtectedBLASTPage = withAuthenticationRequired(BLAST);
const ProtectedDAMPPage = withAuthenticationRequired(DAMP);
const ProtectedFlowChartPage = withAuthenticationRequired(FlowChart);
const ProtectedDNAAssembly = withAuthenticationRequired(DNAAssemblyPage);
const ProtectedComponentLibrary = withAuthenticationRequired(ComponentLibrary);  


const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <ProtectedLoginPage />
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'sequences',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <ProtectedAllSequences />
      },
      {
        path: 'component-library',
        element: <ProtectedComponentLibrary />
      },
      {
        path: 'cmo-workflow',
        element: <ProtectedCmoWorkflow />
      },
      {
        path: 'twist-workflow',
        element: <ProtectedTwistWorkflow />
      },
      {
        path: 'golden-gate-workflow',
        element: <ProtectedGoldenGateWorkflow />
      },
    ]
  },
  {
    path: 'services',
    element: <SidebarLayout />,
    children: [
      {
        path: 'twist',
        element: <ProtectedTwist />
      },
      {
        path: 'azenta',
        element: <ProtectedAzenta />
      },
      {
        path: 'neb',
        element: <ProtectedNEBPage />
      },
      {
        path: 'workflows',
        element: <ProtectedFlowChartPage />
      },
      {
        path: 'blast',
        element: <ProtectedBLASTPage />
      },
      {
        path: '*',
        element: <ComingSoon />
      }
    ]
  },
  {
    path: 'tools',
    element: <SidebarLayout />,
    children: [
      {
        path: 'secure-dna',
        element: <ProtectedSecureDna />
      },
      {
        path: 'secure-dna-admin',
        element: <ProtectedSecureDnaAdmin />
      },
      {
        path: 'aclid',
        element: <ProtectedAclid />
      },
      {
        path: 'damp',
        element: <ProtectedDAMPPage />
      },
      {
        path: 'assemble',
        element: <ProtectedDNAAssembly />
      },
      {
        path: '*',
        element: <ComingSoon />
      }
    ]
  },
  {
    path: 'user',
    element: <SidebarLayout />,
    children: [
      {
        path: 'linked-accounts',
        element: <ProtectedLinkedAccounts />
      },
      {
        path: 'my-resources',
        element: <ProtectedMyResourcesPage />
      }
    ]
  },
  {
    path: 'admin',
    element: <SidebarLayout />,
    children: [
      {
        path: 'documents',
        element: <ProtectedDocumentsPage />
      },
      {
        path: 'orders',
        element: <ProtectedOrdersPage />
      },
      {
        path: 'my-organization',
        element: <ProtectedMyOrganizationPage />
      },
      {
        path: 'organization-resources',
        element: <ProtectedOrganizationResourcesPage />
      }
    ]
  },
  {
    path: 'super-admin',
    element: <SidebarLayout />,
    children: [
      {
        path: 'logs',
        element: <ProtectedLogs />
      },
      {
        path: 'roles',
        element: <ProtectedRolesPage />
      },
      {
        path: 'users',
        element: <ProtectedUsersPage />
      },
      {
        path: 'organizations',
        element: <ProtectedOrganizationsPage />
      }
    ]
  },
];

export default routes;
