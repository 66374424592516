import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  Checkbox,
  Stack,
  Grid
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import Label from 'src/components/Label';
import { Order } from 'src/models/order';
import BulkActions from './BulkActions';

interface OrdersTableProps {
  className?: string;
  filteredOrders: Order[];
  allOrders: Order[],
  setOrders: (orders: Order[]) => void;
}

// TODO: move pagination to the backend
const applyPagination = (
  filteredOrders: Order[],
  page: number,
  limit: number
): Order[] => {
  return filteredOrders.slice(page * limit, page * limit + limit);
};

const getStatusLabel = (status): JSX.Element => {
  switch (status) {
    case "Order Delivered":
      return <Label color='success'>{status}</Label>;
    case "Order in Production":
      return <Label color='warning'>{status}</Label>;
    default:
      return <Label>{status}</Label>;
  }
};

const OrdersTable: FC<OrdersTableProps> = ({ filteredOrders, allOrders, setOrders }) => {
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [selectedOrders, setSelectedOrders] = useState<Order[]>(
    []
  );
  const selectedBulkActions = selectedOrders.length > 0;

  useEffect(() => {
    setSelectedOrders([]);
  }, [filteredOrders]);

  const handleSelectAllOrders = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedOrders(
      event.target.checked
        ? filteredOrders
        : []
    );
  };

  const handleSelectOneOrder = (
    event: ChangeEvent<HTMLInputElement>,
    orderId: string
  ): void => {
    const foundSequence = filteredOrders.find((order) => order.genscript.order_number === orderId);
    const alreadySelectedOrder = selectedOrders.filter((order) => order.genscript.order_number === orderId);
    if (alreadySelectedOrder.length === 0) {
      setSelectedOrders([...selectedOrders, foundSequence]);
    } else {
      setSelectedOrders(selectedOrders.filter((order) => order.genscript.order_number !== orderId));
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const deleteSelected = (): void => {
    const newOrders = allOrders.filter((order) => !selectedOrders.includes(order));
    setOrders(newOrders);
    setSelectedOrders([]);
  }

  const paginatedOrders = applyPagination(
    filteredOrders,
    page,
    limit
  );

  const selectedSomeSequences =
    selectedOrders.length > 0 &&
    selectedOrders.length < filteredOrders.length;
  const selectedAllSequences =
    selectedOrders.length === filteredOrders.length;

  return (
    <Card>
      <Grid container spacing={2} px={2}>
        <Grid item xs={4}>
          <BulkActions selectedOrders={selectedOrders} selectedBulkActions={selectedBulkActions} deleteSelected={deleteSelected} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='body1' sx={{ p: 2 }} textAlign='center'>
            <b>Number of orders: {filteredOrders.length}</b>
          </Typography>
        </Grid>
        <Grid item xs={4} />
      </Grid>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" colSpan={6} sx={{borderRight: "1px solid rgba(0, 0, 0, 0.1)"}}>Genscript info</TableCell>
              <TableCell align="center" colSpan={4}>Fedex info</TableCell>
            </TableRow>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedAllSequences}
                  // indeterminate={selectedSomeSequences}
                  onChange={handleSelectAllOrders}
                  icon={
                    selectedOrders.length > 0 ? (
                    <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', width: '24px', height: '24px' }}>
                      <CheckBoxOutlineBlankIcon sx={{ fontSize: '24px', color: 'rgba(0, 0, 0, 0.54)' }} />
                      <Typography variant="caption" sx={{ 
                        position: 'absolute', 
                        fontSize: '10px', 
                        fontWeight: 'normal', 
                        color: 'rgba(0, 0, 0, 0.8)', 
                        top: '50%', 
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        lineHeight: 1,
                      }}>
                        {selectedOrders.length}
                      </Typography>
                    </Box>
                    ) : undefined
                  }
                />
              </TableCell>
              <TableCell>Order name</TableCell>
              <TableCell>Order number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Order date</TableCell>
              <TableCell>Estimated delivery</TableCell>
              <TableCell>Tracking number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Received by</TableCell>
              <TableCell>Actual delivery date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrders.map((order) => {
              const isOrderSelected = selectedOrders.filter((selected) => selected.genscript.order_number === order.genscript.order_number).length > 0;
              return (
                <TableRow
                  hover
                  key={order.genscript.order_number}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isOrderSelected}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleSelectOneOrder(event, order.genscript.order_number)
                      }
                      value={isOrderSelected}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ maxWidth: 150, textOverflow: "ellipsis" }}
                      gutterBottom
                      noWrap
                    >
                      {order.genscript.order_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {order.genscript.order_number}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {getStatusLabel(order.genscript.status)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {order.genscript.order_date}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {order.genscript.estimated_delivery_date}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {order.fedex.tracking_number}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {order.fedex.status_description}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {order.fedex.received_by}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'capitalize' }}
                      gutterBottom
                      noWrap
                    >
                      {order.fedex.actual_delivery_time}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={filteredOrders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default OrdersTable;
