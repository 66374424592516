import { Box, Chip, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material';
import { SetStateAction, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { screenSequence } from 'src/api/AclidQueries';
import { Sequence } from 'src/models/sequence';
import { getAllSequences } from 'src/api/SequencesQueries';
import ErrorModal from './ErrorModal';
import { useAuth0 } from '@auth0/auth0-react';
import { Role } from 'src/models/user';
import { Organization } from 'src/models/organization';
import { getAllRoles } from 'src/api/RoleQueries';
import { createOrganization, getAllOrganizations } from 'src/api/OrganizationQueries';
import { createUser } from 'src/api/UserQueries';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px'
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function CreateOrganization(props) {
  const { getAccessTokenSilently } = useAuth0();

  const { onClose, open } = props;

  const [organizationName, setOrganizationName] = useState("");
  const [loading, setLoading] = useState(false);
  const [alreadyRun, setAlreadyRun] = useState(false);

  useEffect(() => {
    setOrganizationName("");
    setLoading(false);
    setAlreadyRun(false);
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const createOrganizationRequest = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const result = await createOrganization(accessToken, organizationName);
    if (result) {
      setAlreadyRun(true);
    }
    setLoading(false);
  }


  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack spacing={2} direction="column" alignItems="center" sx={{ height: "100%" }}>
          <Typography variant="h4" >
            Create a new organization.
          </Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            Create a new organization to manage users and resources.
            Once created, you can assign admins and enable resources for it.
          </Typography>
          <FormControl size="small" sx={{ width: '100%' }}>
            <TextField
              label="Organization name"
              variant="outlined"
              size="small"
              value={organizationName}
              onChange={(e) => setOrganizationName(e.target.value)}
            />
          </FormControl>

          {!alreadyRun &&
            <LoadingButton loading={loading} variant="contained" onClick={() => createOrganizationRequest()} disabled={!organizationName}>
              Create
            </LoadingButton>

          }
          {alreadyRun &&
            <Typography variant="body1" color="success">
              The organization has been succesfully created. Add an admin and enable resources in de table view.
            </Typography>
          }

        </Stack>
      </Box>
    </Modal>
  );
}
export default CreateOrganization;
