import { FC, ChangeEvent, useState } from 'react';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
} from '@mui/material';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import BulkActions from './BulkActions';
import UpdateAzentaOrder from 'src/components/Modals/UpdateAzentaOrder';
import DeleteConfirmation from 'src/components/Modals/OrderDeleteConfirmation';
import { AzentaSeqOrder } from 'src/models/azenta';


interface AzentaTableProps {
  className?: string;
  Orders: AzentaSeqOrder[];
}

const applyPagination = (
  Orders: AzentaSeqOrder[],
  page: number,
  limit: number
): AzentaSeqOrder[] => {
  return Orders.slice(page * limit, page * limit + limit);
};

const AzentaTable: FC<AzentaTableProps> = ({ Orders }) => {
  const [selectedOrders, setSelectedOrders] = useState<AzentaSeqOrder[]>(
    []
  );
  const selectedBulkActions = selectedOrders.length > 0;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [openSeq, setOpenSeq] = useState(false);
  const [viewingOrder, setViewingOrder] = useState<AzentaSeqOrder | null>(null);
  const [delConfirm, setDelConfirm] = useState(false);
  const [deletingOrder, setDeletingOrder] = useState<AzentaSeqOrder | null>(null);
  
  const handleSeqClickOpen = (Order: AzentaSeqOrder) => {
    setViewingOrder(Order);
    setOpenSeq(true);
  };

  const handleSeqClose = () => {
    setOpenSeq(false);
  };

  const handleDelClickOpen = (Order: AzentaSeqOrder) => {
    setDeletingOrder(Order);
    setDelConfirm(true);
  }

  const handleDelClose = () => {
    setDelConfirm(false);
  }

  const handleSelectAllOrders = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedOrders(
      event.target.checked
        ? Orders
        : []
    );
  };

  const handleSelectOneOrder = (
    event: ChangeEvent<HTMLInputElement>,
    OrderId: string
  ): void => {
    const foundOrder = Orders.find((seq) => seq.id === OrderId);
    const alreadySelectedOrder = selectedOrders.filter((order) => order.id === OrderId);
    if (alreadySelectedOrder.length === 0) {
      setSelectedOrders([...selectedOrders, foundOrder]);
    } else {
      setSelectedOrders(selectedOrders.filter((order) => order.id !== OrderId));
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const handleCopyToClipboard = (id: string) => {
    navigator.clipboard.writeText(id);
  };

  const paginatedOrders = applyPagination(
    Orders,
    page,
    limit
  );
  const selectedSomeOrders =
    selectedOrders.length > 0 &&
    selectedOrders.length < Orders.length;
  const selectedAllOrders =
    selectedOrders.length === Orders.length;
  const theme = useTheme();

  return (
    <Card>
      <Box flex={1} p={2}>
        <BulkActions selectedOrders={selectedOrders} selectedBulkActions={selectedBulkActions}/>
      </Box>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedAllOrders}
                  indeterminate={selectedSomeOrders}
                  onChange={handleSelectAllOrders}
                />
              </TableCell>
              <TableCell>Order Name</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>Sequence Count</TableCell>
              <TableCell>Pool Count</TableCell>
              <TableCell>Library Count</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrders.map((order) => {
              const isOrderSelected = selectedOrders.filter((selected) => selected.id === order.id).length > 0;
              return (
                <TableRow
                  hover
                  key={order.id}
                  selected={isOrderSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isOrderSelected}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleSelectOneOrder(event, order.id)
                      }
                      value={isOrderSelected}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {order.orderName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'uppercase' }}
                      gutterBottom
                      noWrap
                    >
                      {order.id}
                      <Tooltip title="Copy Order ID" arrow placement="right">
                        <IconButton
                          onClick={() => handleCopyToClipboard(order.id)}
                          size="small"
                          sx={{ml:1}}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'uppercase' }}
                      gutterBottom
                      noWrap
                    >
                      {order.sequenceIds.length}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'uppercase' }}
                      gutterBottom
                      noWrap
                    >
                      {order.libraryCnt}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      sx={{ textTransform: 'uppercase' }}
                      gutterBottom
                      noWrap
                    >
                      {order.sampleCnt}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Tooltip title="View/Edit Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.secondary.lighter },
                          color: theme.palette.secondary.main
                        }}
                        color="inherit"
                        size="small"
                        onClick={() => handleSeqClickOpen(order)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Order" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                        onClick={()=>handleDelClickOpen(order)}
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={Orders.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
      {viewingOrder && <UpdateAzentaOrder open={openSeq} onClose={handleSeqClose} order={viewingOrder} />}
      {deletingOrder && <DeleteConfirmation open={delConfirm} onClose={handleDelClose} order={deletingOrder} />}
    </Card>
  );
};

export default AzentaTable;
