import React, { useState, useCallback, useEffect } from 'react';
import ReactFlow, {
  ReactFlowProvider, addEdge, Background, BackgroundVariant,
  Controls, MarkerType, MiniMap, Connection, Edge, Node, Handle, Position
} from 'react-flow-renderer';
import { Box, MenuItem, Select, SelectChangeEvent, Typography, FormControl, InputLabel, Checkbox, ListItemText, OutlinedInput } from '@mui/material';
import type { MenuProps } from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import BuildIcon from '@mui/icons-material/Build';

const nodeStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  borderRadius: '10px',
  background: '#fff',
  border: '1px solid #ddd',
  cursor: 'pointer',
  backgroundColor: '#d9f7be',
  width: '225px',
  height: '125px',
};

const forwardEdgeStyle = {
  markerEnd: { 
    type: MarkerType.ArrowClosed, 
    width: 10, 
    height: 10, 
    strokeWidth: 2
  },
  style: {
    strokeWidth: 3
  }
}

const MenuProps: Partial<MenuProps> = {
  PaperProps: {
    style: {
      // maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

const handleClick = (url: string) => {
  if (url && url !== '') {
    window.location.href = url;  // TODO: Implement links/navigation
  }
};

const ToolNode: React.FC<{ data: { label: string; color: string, icon: string; url: string } }> = ({ data }) => {
  return (
    <Box sx={{...nodeStyle, bgcolor: data?.color ?? '#91ffa0', height: '50px', width: '175px', justifyContent: 'start'}} onClick={()=>handleClick(data.url)}>
      <Handle type="target" position={Position.Left} />
      <Box display='flex' alignItems='center' sx={{ml: 1}}>
        <Typography sx={{ mr: '10px' }}>{data?.icon ? data.icon : <BuildIcon/>}</Typography>
        {data.label}
      </Box>
      <Handle type="source" position={Position.Right} />
    </Box>
  );
};

const ServiceNode: React.FC<{ data: { label: string; options: string[], color: string, icon: string; url: string } }> = ({ data }) => {
  return (
    <Box sx={{...nodeStyle, bgcolor: data?.color ?? '#91ccff'}} onClick={()=>handleClick(data.url)}>
      <Handle type="target" position={Position.Left} />
      <Box display="flex" flexDirection="column" sx={{m:1}}>
        <Box display="flex" alignItems="center">
          <Typography sx={{ mr: '10px' }}>{data?.icon ? data.icon : <PrecisionManufacturingIcon/>}</Typography>
          {data.label}
        </Box>
        {data?.options && data.options.length > 0 && (
          <Box>
            <ul>
              {data?.options?.map((option: string, index: number) => 
                <li key={index}>{option}</li>
              )}
            </ul>
          </Box>
        )}
      </Box>
      <Handle type="source" position={Position.Right} />
    </Box>
  );
};

const nodeTypes = {
  tool: ToolNode,
  service: ServiceNode,
};

const initialToolNodes: Node[] = [
  { id: '0', type: 'tool', position: { x: 0, y: -50 }, data: { label: 'Benchling' } },
  { id: '1', type: 'tool', position: { x: 0, y: 50 }, data: { label: 'Geneious' } },
  { id: '2', type: 'tool', position: { x: 0, y: 150 }, data: { label: 'SnapGene' } },
  { id: '3', type: 'tool', position: { x: 0, y: 250 }, data: { label: 'Kernel' } },
  { id: '4', type: 'tool', position: { x: 0, y: 350 }, data: { label: 'DAMP Canvas' } },
];

const initialWorkflowNodes: { [key: string]: Node[] } = {
  'CMO Order Workflow': [
    { id: '5', type: 'service', position: { x: 300, y: 110 }, data: { label: 'Complexity Scoring', options: ['IDT'] } },
    { id: '6', type: 'service', position: { x: 600, y: 110 }, data: { label: 'Biosecurity Screening', options: ['SecureDNA', 'Aclid'] } },
    { id: '7', type: 'service', position: { x: 900, y: 110 }, data: { label: 'Optimization', options: ['Kent Codon Optimize', 'AIse Protein Optimize'] } },
  ],
};

const workflows: Node[] = [
  {
    id: '8', type: 'service', position: { x: 1200, y: 110 }, style: { color: 'white', fontSize: '20pt' },
    data: {
      label: 'Azenta', color: '#826ec3',
      icon: <img src="/static/images/companies/azenta_logo.png" alt="azenta" style={{ height: "50px" }} />,
    }
  },
];

const initialEdges: Edge[] = [
  { id: 'e0-5', source: '0', target: '5', ...forwardEdgeStyle },
  { id: 'e1-5', source: '1', target: '5', ...forwardEdgeStyle },
  { id: 'e2-5', source: '2', target: '5', ...forwardEdgeStyle },
  { id: 'e3-5', source: '3', target: '5', ...forwardEdgeStyle },
  { id: 'e4-5', source: '4', target: '5', ...forwardEdgeStyle },
  { id: 'e5-6', source: '5', target: '6', ...forwardEdgeStyle },
  { id: 'e6-7', source: '6', target: '7', ...forwardEdgeStyle },
  { id: 'e7-8', source: '7', target: '8', ...forwardEdgeStyle },
];

const FlowChart: React.FC = () => {
  const [selectedTools, setSelectedTools] = useState<string[]>(initialToolNodes.map(node => node.data.label));
  const [selectedWorkflow, setSelectedWorkflow] = useState<string>('CMO Order Workflow');
  const [selectedEndpoint, setSelectedEndpoint] = useState<string>('Azenta');
  const [edges, setEdges] = useState<Edge[]>(initialEdges);

  const toolOptions = initialToolNodes.map(node => node.data.label);
  const workflowOptions = Object.keys(initialWorkflowNodes);
  const endpointOptions = workflows.map(node => node.data.label);

  const handleToolChange = (event: SelectChangeEvent<typeof selectedTools>) => {
    const { target: { value } } = event;
    setSelectedTools(typeof value === 'string' ? value.split(',') : value);
  };

  const handleWorkflowChange = (event: SelectChangeEvent<string>) => {
    setSelectedWorkflow(event.target.value);
  };

  const handleEndpointChange = (event: SelectChangeEvent<string>) => {
    setSelectedEndpoint(event.target.value);
  };

  const filteredToolNodes = initialToolNodes.filter(node => selectedTools.includes(node.data.label));
  const workflowNodes = initialWorkflowNodes[selectedWorkflow];
  const endpointNode = workflows.find(node => node.data.label === selectedEndpoint);

  useEffect(() => {
    const updatedEdges = [];
  
    filteredToolNodes.forEach(toolNode => {
      if (workflowNodes.length > 0) {
        updatedEdges.push({
          id: `e${toolNode.id}-5`,
          source: toolNode.id,
          target: workflowNodes[0].id,
          ...forwardEdgeStyle
        });
      }
    });
  
    for (let i = 0; i < workflowNodes.length - 1; i++) {
      updatedEdges.push({
        id: `e${workflowNodes[i].id}-${workflowNodes[i + 1].id}`,
        source: workflowNodes[i].id,
        target: workflowNodes[i + 1].id,
        ...forwardEdgeStyle
      });
    }
  
    if (workflowNodes.length > 0 && endpointNode) {
      updatedEdges.push({
        id: `e${workflowNodes[workflowNodes.length - 1].id}-${endpointNode.id}`,
        source: workflowNodes[workflowNodes.length - 1].id,
        target: endpointNode.id,
        ...forwardEdgeStyle
      });
    }
  
    setEdges(updatedEdges);
  }, [filteredToolNodes, workflowNodes, endpointNode]);

  const nodes = [...filteredToolNodes, ...workflowNodes, endpointNode];

  const onConnect = useCallback((params: Edge | Connection) =>
    setEdges((eds) => addEdge({ ...params, markerEnd: { type: MarkerType.ArrowClosed, width: 20, height: 20, strokeWidth: 2 } }, eds)), [setEdges]);


  return (
    <div style={{ height: '75vh' }}>
      <Box sx={{ padding: 2, mx:30, backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd', display: 'flex', justifyContent: 'space-between' }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="tool-select-label">Tools</InputLabel>
          <Select
            labelId="tool-select-label"
            multiple
            value={selectedTools}
            onChange={handleToolChange}
            input={<OutlinedInput label="Tools" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {toolOptions.map((label) => (
              <MenuItem key={label} value={label}>
                <Checkbox checked={selectedTools.indexOf(label) > -1} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="workflow-select-label">Workflow</InputLabel>
          <Select
            labelId="workflow-select-label"
            value={selectedWorkflow}
            onChange={handleWorkflowChange}
            input={<OutlinedInput label="Workflow" />}
            MenuProps={MenuProps}
          >
            {workflowOptions.map((label) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="endpoint-select-label">Endpoint</InputLabel>
          <Select
            labelId="endpoint-select-label"
            value={selectedEndpoint}
            onChange={handleEndpointChange}
            input={<OutlinedInput label="Endpoint" />}
            MenuProps={MenuProps}
          >
            {endpointOptions.map((label) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <MiniMap />
        <Controls />
        <Background variant={BackgroundVariant.Lines} />
      </ReactFlow>
    </div>
  );
};

const FlowChartWithProvider: React.FC = () => (
  <ReactFlowProvider>
    <FlowChart />
  </ReactFlowProvider>
);

export default FlowChartWithProvider;
