import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import SeqViz from "seqviz";

const Carousel = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? data.length - 1 : prevIndex - 1
      );
    };
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === data.length - 1 ? 0 : prevIndex + 1
      );
    };
  
    const currentItem = data[currentIndex];
  
    return (
      <Box style={{ textAlign: "center", marginTop: "20px" }}>
        <Typography variant="h6">{currentItem.name}</Typography>
        <SeqViz
          style={{ marginTop: "10px", height: "300px" }}
          seq={currentItem.seq}
          viewer="circular"
          annotations={currentItem.annotations}
        />
  
        <Box style={{ marginTop: "10px" }}>
          <Button variant="outlined" sx={{mr:2}} onClick={handlePrev}>Previous Construct</Button>
          <Button variant="outlined" onClick={handleNext}>Next Construct</Button>
        </Box>
  
        <Typography sx={{mt:2}}>
          {currentIndex + 1} / {data.length}
        </Typography>
      </Box>
    );
  };

  export default Carousel;