import { useState } from 'react';

import { Box, Typography, Button, Grid } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchIcon from '@mui/icons-material/Search';

import SeqImporter from 'src/components/Modals/ImportSeq';
import { createData, DataType, File } from 'src/api/DataQueries';

import { useAuth0 } from '@auth0/auth0-react';


function PageHeader() {
  const [openUploadSequenceModal, setOpenUploadSequenceModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const fileContent = e.target?.result as string;
        const fileData = {
          filename: file.name,
          size: file.size,
          mimeType: file.type,
          data: fileContent
        } as File

        const accessToken = await getAccessTokenSilently();
        createData(accessToken, { type: DataType.File, attributes: fileData })
      };
      reader.readAsText(file);
    }
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          BLAST
        </Typography>
        <Typography variant="subtitle2">
          Run NCBI-BLAST on one or more sequences.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={() => setOpenUploadSequenceModal(true)}
          sx={{ mt: { xs: 2, md: 0 }, mb: 1, width: 200 }}
          variant="contained"
          startIcon={<><FileUploadIcon fontSize="small" />|<SearchIcon fontSize="small" /></>}
        >
          Add Sequence
        </Button>
        <Box sx={{ display: 'flex', direction: 'row', alignItems: 'center' }}>
          <input type="file"
            onChange={handleFileChange} id={'filePicker'}
            style={{ display: 'none' }} />
          <label htmlFor={'filePicker'}>
            <Button 
              component="span" 
              variant='contained' 
              sx={{ mt: { xs: 2, md: 0 }, mb: 1, width: 200 }}
              startIcon={<FileUploadIcon fontSize="small" />}
            >
              Upload File
            </Button>
          </label>
        </Box>
        <SeqImporter open={openUploadSequenceModal} onClose={() => setOpenUploadSequenceModal(false)} />
      </Grid>
    </Grid>
  );
}

export default PageHeader;
