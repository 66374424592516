import { LoadingButton } from '@mui/lab';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    Modal,
    Box,
    Stack
  } from '@mui/material';
import { useEffect, useState } from 'react';
import SeqViz from 'seqviz';
import { getAllGenomes, updateGenome } from 'src/api/BiosecurityQueries';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Genome } from 'src/models/genome';
import { useAuth0 } from '@auth0/auth0-react';

function SecureDnaAdmin () {
    const { getAccessTokenSilently } = useAuth0();

    const [genomes, setGenomes] = useState([])
    const [genomeModal, setGenomeModal] = useState<Genome | null>(null)
    const [loading, setLoading] = useState(false)

    const getFormattedDate = (dateString:string) => {
        const date = new Date(dateString);

        const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();

        const formattedDate = `${month}/${day}/${year}`;

        return formattedDate;
    }

    const getAdminStatusColor = (status:string) => {
        switch(status){
            case 'approved':
                return 'green'
            case 'rejected':
                return 'red'
            case 'falsePositive':
                return 'orange'
        }
    }

    const renderTable = (genomeData) => {
        const sortedData = genomeData.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
        const rows = sortedData.map((genome, index) => {
        return(
            <TableRow key={index}>
                <TableCell>{getFormattedDate(genome["timestamp"])}</TableCell>
                <TableCell>{genome['user']["email"]}</TableCell>
                <TableCell><Typography sx={{color: genome.sequence.biosecurity.status === "denied" ? "red" : "green"}}>{genome.sequence.biosecurity.status}</Typography></TableCell>
                <TableCell><Typography sx={{color: getAdminStatusColor(genome.adminStatus)}}>{genome.adminStatus !== 'falsePositive' ? genome.adminStatus : 'false positive'}</Typography></TableCell>
                <TableCell><Button onClick={() => setGenomeModal(genome)}>View</Button></TableCell>
            </TableRow>
          )
        });
        return rows;
      }

      const getGenomes = async() => {
        setLoading(true)
        const accessToken = await getAccessTokenSilently()
        const data = await getAllGenomes(accessToken)
        if(data){
          setGenomes(data)
          setLoading(false)
        }
      
      }

      useEffect(() => {
        getGenomes()
      }, [])

      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '0.5px solid #000',
        boxShadow: 24,
        p: 5,
        overflowY: 'scroll'
      };

      const getAnnotations = () => {
        const annotations = genomeModal.sequence.biosecurity.biosecurityCheck.map((check) => {
            return(
                check.hit_regions.map((region, idx) => {
                return({
                    start: region.start_index,
                    end: region.end_index,
                    id: `${check.organism.name}-hit-region-${idx+1}`,
                    color: 'red',
                    name: check.organism.name
                })
            })
        )
        })
        return annotations.flat()
      }

      const updateGenomeAdmin = async(status:string) => {
        const accessToken = await getAccessTokenSilently()
        const response = await updateGenome(accessToken, genomeModal.id, status)
        if(response){
          setGenomeModal(null)
        }
      }
      console.log(genomeModal)

    return(
        <>
        {genomeModal &&

        <Modal
        open={genomeModal !== null}
        onClose={() => setGenomeModal(null)}
        >
            <Box sx={style}>
                <Stack direction="column" spacing={2}>
                    <Typography variant="h6" component="h2">
                        User email: {genomeModal.user.email}
                    </Typography>
                    <Typography variant="body1">
                        Sequence name: {genomeModal.sequence.name}
                    </Typography>
                    <Typography variant="body1">
                        Sequence type: {genomeModal.sequence.type}
                    </Typography>
                    <Typography variant="body1">
                        Sequence biosecurity status: {genomeModal.sequence.biosecurity.status}
                    </Typography>
                    {genomeModal.sequence.biosecurity.status === 'denied' &&
                    <>
                    <Typography variant="body1">
                       Organisms Detected: {genomeModal.sequence.biosecurity.biosecurityCheck.map((check) => check.organism.name).join(", ")}
                    </Typography>
                    <Typography variant="body1">
                       Hit Regions:
                    </Typography>
                    <SeqViz seq={genomeModal.sequence.seq} viewer="linear" style={{height: '200px'}} annotations={getAnnotations()} />
                    </>
                    }
                    <Stack direction="row" spacing={2}>
                        <Button variant="contained" sx={{width: '200px', backgroundColor: 'green'}} onClick={() => updateGenomeAdmin('approved')}>Approve Sequence</Button>
                        <Button variant="contained" sx={{width: '200px', backgroundColor: 'red'}} onClick={() => updateGenomeAdmin('rejected')}>Reject Sequence</Button>
                        <Button variant="contained" sx={{width: '200px', backgroundColor: 'orange'}} onClick={() => updateGenomeAdmin('falsePositive')}>Flag as False Positive</Button>
                    </Stack>
                </Stack>
            </Box>
        </Modal>
        
        }
        <Typography sx={{margin:5}} variant="h2">
            Biosecurity Admin Dashboard
        </Typography>
        <LoadingButton variant='outlined' loading={loading} size='small' sx={{ m: 5, mb:-5, mt:0 }} onClick={getGenomes}>
            <RefreshIcon fontSize='small' />
        </LoadingButton>
        <TableContainer sx={{maxWidth: '90%', margin:5}} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Biosecurity Date Run</TableCell>
                        <TableCell>User Email</TableCell>
                        <TableCell>Biosecurity Status</TableCell>
                        <TableCell>Admin Status</TableCell>
                        <TableCell>Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {renderTable(genomes)}
                </TableBody>
                </Table>
        </TableContainer>
        </>
    )
}

export default SecureDnaAdmin