import { FC, ChangeEvent, useState, useEffect } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import { Link as RouterLink } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import {
  Tooltip,
  Divider,
  Box,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  useTheme,
  Stack,
  Link,
  Button,
  Chip
} from '@mui/material';
import { useLocation } from "react-router-dom";
import RefreshIcon from '@mui/icons-material/Refresh';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

import BulkActions from './BulkActions';
import { getAllSequences } from 'src/api/SequencesQueries';
import { IDTComplexGBlockSite } from 'src/models/sequence';
import { Sequence } from 'src/models/sequence';
import Label from 'src/components/Label';
import OlonOrder from 'src/components/Modals/OlonOrder';
import IDTOrder from 'src/components/Modals/IDTOrder';
import RunBiosecurity from 'src/components/Modals/RunBiosecurity';
import RunComplexityScore from 'src/components/Modals/RunComplexityScore';
import RunOptimization from 'src/components/Modals/RunOptimization';
import BiosecurityDetails from 'src/components/Modals/BiosecurityDetails';
import ComplexityScoreDetails from 'src/components/Modals/ComplexityScoreDetails';
import OptimizationDetails from 'src/components/Modals/OptimizationDetails';
import InventoryDetails from 'src/components/Modals/InventoryDetails';
import { useAuth0 } from '@auth0/auth0-react';
import { getInventory, orderItem } from 'src/api/QuartzyQueries';

interface QuartzyInventoryItem {
  id: string;
  name: string;
  vendor: string;
  catalog_number: string;
  price: number | null;
  unit_size: string;
  quantity: string;
  url: string;
  technical_details: string | null;
  expiration_date: string;
  auto_reminder: string;
  lot_number: string | null;
  cas_number: string | null;
  vendor_product_id: string | null;
  app_url: string;
  serial_number: string;
  lab: {
    id: string;
    name: string;
    organization: {
      id: string;
      name: string;
    };
  };
  location: {
    name: string;
  };
  sublocation: string | null;
  added_by: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  updated_by: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  type: {
    id: string;
    name: string;
  };
}



const getStatusLabel = (status): JSX.Element => {
  const map = {
    denied: {
      text: 'Denied',
      color: 'error'
    },
    success: {
      text: 'Success',
      color: 'success'
    },
    pending: {
      text: 'Pending',
      color: 'warning'
    }
  };

  const { text, color }: any = map[status];

  return <Label color={color}>{text}</Label>;
};

interface CmoWorkflowLocationState {
  initialSequences: Sequence[];
}

const applyPagination = (
  sequences: Sequence[],
  page: number,
  limit: number
): Sequence[] => {
  return sequences.slice(page * limit, page * limit + limit);
};

const CmoSequencesTable = () => {
  const { getAccessTokenSilently } = useAuth0();

  const location = useLocation()
  const { initialSequences } = location.state as CmoWorkflowLocationState ?? { initialSequences: [] }
  const [inventoryItems, setInventoryItems] = useState<QuartzyInventoryItem[]>([]);

  const [inventoryCheck, setInventoryCheck] = useState(false)
  const [allSequences, setAllSequences] = useState<Sequence[]>(initialSequences);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [requestingItemId, setRequestingItemId] = useState<string | null>(null);
  const [orderRequestedForItems, setOrderRequestedForItems] = useState<string[]>([]);


  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const paginatedSequences = applyPagination(
    allSequences,
    page,
    limit
  );

  useEffect(() => {
    updateInventoryItems()
    // Update them every 3 seconds
    setInterval(async () => {
      await updateInventoryItems()
    }, 4000);
  }, [])

  const updateInventoryItems = async () => {
    const accessToken = await getAccessTokenSilently();
    const items = await getInventory(accessToken)
    setInventoryItems(items)
  }

  const orderMissingItems = async (itemId: string, quantity: number) => {
    setRequestingItemId(itemId)
    const accessToken = await getAccessTokenSilently();
    await orderItem(accessToken, itemId, quantity.toString())
    setOrderRequestedForItems([...orderRequestedForItems, itemId])
    setRequestingItemId(null)
  }


  return (
    <Stack direction='column'>
      <Stack direction='row' spacing={2} sx={{ mb: 3 }} alignItems='center'>
        <Typography variant="h3">
          Fragments
        </Typography>
        <Chip
          label="You haven't synthetized all the fragments for this workflow yet"
          color="warning"
          icon={<WarningIcon />}
        />
      </Stack>
      <Card>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Order</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedSequences.map((seq) => {
                return (
                  <TableRow
                    hover
                    key={seq.id}
                  >
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {seq.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label="Fragment not synthetized yet"
                        variant='outlined'
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        size='small'
                        component={RouterLink}
                        to="/sequences/twist-workflow"
                        state={{ initialSequences: [seq] }}
                      >
                        Order from Twist
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={allSequences.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      </Card>

      <Stack direction='row' spacing={2} sx={{ my: 3 }} alignItems='center'>
        <Typography variant="h3">
          Materials and reagents
        </Typography>
        <Chip
          label={inventoryItems.some((item, index) => parseInt(item.quantity) < index + 1) ? "You are missing some inventory items to complete this workflow" : "You have all the items needed for this workflow"}
          color={inventoryItems.some((item, index) => parseInt(item.quantity) < index + 1) ? "warning" : "success"}
          icon={inventoryItems.some((item, index) => parseInt(item.quantity) < index + 1) ? <WarningIcon /> : <CheckIcon />}
        />
      </Stack>


      <Card>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Unit size</TableCell>
                <TableCell>Units needed</TableCell>
                <TableCell>Units in inventory</TableCell>
                <TableCell>Request more</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inventoryItems.map((item, index) => {
                return (
                  <TableRow
                    hover
                    key={item.id}
                  >
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                        sx={{ maxWidth: 300, textOverflow: "ellipsis" }}
                      >
                        {item.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.vendor}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {item.unit_size}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color="text.primary"
                        gutterBottom
                        noWrap
                      >
                        {/* Making stuff up. These should come from the protocol file */}
                        {index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        color={parseInt(item.quantity) >= index + 1 ? 'green' : 'red'}
                        sx={{ width: 'fit-content', border: '1px solid', borderColor: parseInt(item.quantity) >= index + 1 ? 'green' : 'red', borderRadius: 1, padding: 2 }}

                      >
                        {item.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {(parseInt(item.quantity) >= index + 1) ?
                        <Chip
                          label="Enough of this item in your lab to make this workflow"
                          color="success"
                          variant='outlined'
                          sx={{
                            height: 'auto',
                            '& .MuiChip-label': {
                              display: 'block',
                              whiteSpace: 'normal',
                            },
                          }}
                        />
                        :
                        <LoadingButton
                          loading={requestingItemId === item.id}
                          onClick={() => orderMissingItems(item.id, index + 1 - parseInt(item.quantity))}
                          disabled={orderRequestedForItems.includes(item.id)}
                          variant="outlined"
                          color="primary"
                          size='small'
                        >
                          {orderRequestedForItems.includes(item.id) ? 'Order requested' : 'Request missing units'}
                        </LoadingButton>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box p={2}>
          <TablePagination
            component="div"
            count={allSequences.length}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 25, 30]}
          />
        </Box>
      </Card>
    </Stack >
  );
};

export default CmoSequencesTable;

