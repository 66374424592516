import { useState } from 'react';

import { Box, Typography, Button, Grid } from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import CreateAzentaOrder from 'src/components/Modals/CreateAzentaOrder';


function PageHeader() {

  const [openCreateAzentaOrderModal, setOpenCreateAzentaOrderModal] = useState(false);

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Azenta Orders
        </Typography>
        <Typography variant="subtitle2">
          Manage all of your Azenta Order Forms, Samples, and Libraries.
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={()=>setOpenCreateAzentaOrderModal(true)}
          sx={{ mt: { xs: 2, md: 0 } }}
          variant="contained"
          startIcon={<NoteAddIcon fontSize="small" />}
        >
          Start new order form
        </Button>
        <CreateAzentaOrder open={openCreateAzentaOrderModal} onClose={()=>setOpenCreateAzentaOrderModal(false)} />
      </Grid>
    </Grid>
  );
}

export default PageHeader;
