import { Box, Container, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Footer from 'src/components/Footer';
import ExcelUploader from './ExcelUploader';
import PopupWindow from './PopupWindow';

function DNAAssemblyPage() {
   
    return (
      <>
        <Helmet>
          <title>DNA Assembly Tool</title>
        </Helmet>
        <PageTitleWrapper>
            <Stack direction="row" spacing={2}>
                <Typography variant="h2">
                    DNA Assembly Tool
                </Typography>
                <Box sx={{m: 4}}>
                    <PopupWindow />
                </Box>
            </Stack>
        </PageTitleWrapper>
        <Container maxWidth="lg">
          <ExcelUploader />
        </Container>
        <Footer />
      </>
    );
  }
  
  export default DNAAssemblyPage;
  