import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const PopupWindow = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>

      <IconButton color="primary" onClick={handleOpen}>
        <HelpOutlineIcon />
      </IconButton>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
        DNA Assembly Tool Info
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1">
            This tool provides a simple Golden Gate DNA assembly workflow. You must upload one Assembly Schema which is an excel file containing columns: Assembly Name, Backbone, Promoter, X Component Name ...,
            Construct Name (will be name of assembled file), Components (A comma separated sequence of column names in the order you want those components assembled for that row. ex: Backbone,Promoter,CDS,RBS,Terminator which are the desired parts and are all real column names in the excel).
             <br></br><br></br>You must also upload a zip file containing the DNA components. The zip file must contain a collection of folders where each folder name matches a column name in the excel (ex. A folder named 'Promoter' which is also a column name in the excel) 
             These subfolders must contain genbank files containg the DNA components. The genbank files must be named after the column value of the component of interest. 
             <br></br><br></br>For example, a component 'Backbone' is a column name and a backbone file is named 'DVK_AE.gb' where DVK_AE is a column value in the excel inside the Backbone column.
             The tool will then generate the DNA assembly results in a zip file. Each file in this results zip will be named after the corresponding construct name specified in the uploaded excel.
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupWindow;
