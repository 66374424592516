import { FC, ChangeEvent, useState, useEffect } from 'react';
import {
  Divider,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Typography,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
} from '@mui/material';

import { Role, User } from 'src/models/user';
import { assignRolesToUser, getRoles } from 'src/api/RoleQueries';
import { useAuth0 } from '@auth0/auth0-react';

interface UsersTableProps {
  className?: string;
  users: User[];
  setUsers: (users: User[]) => void;
}

const applyPagination = (
  users: User[],
  page: number,
  limit: number
): User[] => {
  return users.slice(page * limit, page * limit + limit);
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UsersTable: FC<UsersTableProps> = ({ users, setUsers }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [allRoles, setAllRoles] = useState<Role[]>([]);


  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const fetchRoles = async () => {
    const accessToken = await getAccessTokenSilently();
    const responseRoles = await getRoles(accessToken);
    if (responseRoles) {
      setAllRoles(responseRoles);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleRoleChange = async (user, roleNames) => {
    const findRoles = allRoles.filter((role) => roleNames.includes(role.name));
    const updatedUsers = users.map((u) => {
      if (u.id === user.id) {
        return { ...u, roles: findRoles };
      }
      return u;
    });
    setUsers(updatedUsers);

    const accessToken = await getAccessTokenSilently();
    assignRolesToUser(accessToken, user.id, findRoles.map((role) => role.id));
  };



  const paginatedUsers = applyPagination(
    users,
    page,
    limit
  );

  return (
    <Card>
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Roles</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => {
              return (
                <TableRow
                  hover
                  key={user.email}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {user.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <FormControl size='small'>
                      <Select
                        multiple
                        size='small'
                        value={user.roles.map((role) => role.name)}
                        onChange={(e) => handleRoleChange(user, e.target.value)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {allRoles.map((role) => (
                          <MenuItem
                            key={role.id}
                            value={role.name}
                          >
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={users.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default UsersTable;
