import { Box, Icon, Container, Link, Typography, styled } from '@mui/material';

const FooterWrapper = styled(Container)(
  ({ theme }) => `
        margin-top: ${theme.spacing(4)};
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <Box
        pb={4}
        display={{ xs: 'block', md: 'flex' }}
        alignItems="center"
        textAlign={{ xs: 'center', md: 'left' }}
        justifyContent="space-between"
      >
        <Box>
          <Typography variant="subtitle1">
            &copy; 2024 - MPI Dashboard
          </Typography>
        </Box>
        <Icon sx={{height: "100%", width: "50%"}}>
          <img height="30" width="300" src="/static/images/logo/lattice_svg.svg"/>
        </Icon>
        <Typography
          sx={{
            pt: { xs: 2, md: 0 }
          }}
          variant="subtitle1"
        >
          Crafted by{' '}
          <Link
            href="https://latticeautomation.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lattice Automation
          </Link>
        </Typography>
      </Box>
    </FooterWrapper>
  );
}

export default Footer;
