import SeqViz from 'seqviz';
import { Box, Modal, Stack, Typography } from '@mui/material';
import SequenceViewer from './SequenceViewer';
import { Sequence } from 'src/models/sequence';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 4
};

interface BiosecurityDetailsProps {
  onClose: () => void;
  open: boolean;
  sequence: Sequence;
}

function BiosecurityDetails({ onClose, sequence, open }: BiosecurityDetailsProps) {

  const handleClose = () => {
    onClose();
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Stack direction="column" spacing={2} alignItems='center'>
          <Typography variant="h4" >
            SecureDNA Biosecurity check details
          </Typography>
          <Typography variant="h5" >
            Sequence name: {sequence.name}
          </Typography>
          <Typography variant="h5" >
            Status: {sequence.biosecurity.status}
          </Typography>
        </Stack>
        {sequence.biosecurity.status === 'denied' &&
          <>
            <Typography sx={{ marginTop: '20px' }} variant="h5">
              Organisms: {sequence.biosecurity.biosecurityCheck.map((check) => check.organism.name).join(', ')}
            </Typography>
            <Typography sx={{ marginTop: '20px' }} variant="h5">
              Hit Regions:
            </Typography>
            <SeqViz
              style={{ marginTop: '10px', height: '300px' }}
              seq={sequence.seq} viewer="linear"
              annotations={[...sequence.annotations, ...sequence.biosecurity.biosecurityCheck.map((hazard) => hazard.hit_regions.map((hit, index) => {
                return {
                  name: `${hazard.organism.name} hit ${index + 1}`,
                  start: hit.start_index,
                  end: hit.end_index,
                  direction: 1,
                  color: 'red'
                }
              })).flat()]}

            />
          </>
        }

      </Box>
    </Modal>
  );
}
export default BiosecurityDetails;
