import { useEffect, useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Divider, List, ListItem, Modal, Stack, Typography } from '@mui/material';
import { Sequence } from 'src/models/sequence';
import { Issue, TwistQuote } from 'src/models/twist';
import { useAuth0 } from '@auth0/auth0-react';
import { createQuote, getQuoteStatus, orderQuote } from 'src/api/TwistQueries';
import { LoadingButton } from '@mui/lab';
import RefreshIcon from '@mui/icons-material/Refresh';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 3,
};

interface TwistQuoteModalProps {
  open: boolean
  onClose: () => void
  constructId: string
}

function TwistQuoteModal({ onClose, constructId, open }: TwistQuoteModalProps) {
  const { getAccessTokenSilently } = useAuth0();

  const [loading, setLoading] = useState(true);
  const [orderingQuote, setOrderingQuote] = useState(false);
  const [quoteAlreadyOrdered, setQuoteAlreadyOrdered] = useState(false);
  const [twistQuote, setTwistQuote] = useState<TwistQuote | null>(null);
  const [qouteId, setQuoteId] = useState<string>('');

  const createTwistQuote = async () => {
    const accessToken = await getAccessTokenSilently();
    const response = await createQuote(accessToken, constructId);
    setQuoteId(response['id']);
    return response['id'];
  }

  const getTwistQuoteStatus = async (quoteId: string) => {
    const accessToken = await getAccessTokenSilently();
    const response = await getQuoteStatus(accessToken, quoteId);
    setTwistQuote(response);
    if (response.status_info.status !== 'PENDING') {
      setLoading(false);
    }
  }

  const createQuoteAndFetchStatus = async () => {
    setLoading(true);
    const quoteId = await createTwistQuote();
    await getTwistQuoteStatus(quoteId);

    // TODO: Clear this interval
    const interval = setInterval(() => {
      getTwistQuoteStatus(quoteId)
    }, 3000)
  }

  const orderTwistQuote = async () => {
    setOrderingQuote(true);
    const accessToken = await getAccessTokenSilently();
    await orderQuote(accessToken, qouteId);
    setOrderingQuote(false);
    setQuoteAlreadyOrdered(true);
  }

  useEffect(() => {
    createQuoteAndFetchStatus()
  }, [])


  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style} >
        {loading ?
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
          :
          <Stack direction="column" spacing={2} sx={{ overflowY: 'scroll', height: '100%' }}>
            <Stack direction='row' justifyContent='space-between' sx={{ pr: 2 }}>
              <Typography variant="h4" gutterBottom>
                Quote Details
              </Typography>
            </Stack>
            {twistQuote?.status_info.status === 'PENDING' ?
              <>
                <Typography variant="h6" color="primary" gutterBottom textAlign='center'>
                  Quote is being processed. Please wait for a few minutes and refresh.
                </Typography>
              </>
              :
              <>
                <Typography variant="h6" color="primary" gutterBottom>
                  Total Price: ${twistQuote.quote.price}
                </Typography>

                <Typography variant="h6" gutterBottom>
                  <strong>Quote Lines:</strong>
                </Typography>
                <List>
                  {twistQuote.quote.quote_lines.map((line) => (
                    <ListItem key={line.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography variant="body1"><strong>Product:</strong> {line.product_name}</Typography>
                        <Typography variant="body2">Description: {line.description}</Typography>
                        <Typography variant="body2">Quantity: {line.quantity}</Typography>
                        <Typography variant="body2">Unit Price: ${line.list_unit_price}</Typography>
                      </Box>
                      <Typography variant="body2" color="secondary">
                        Total: ${line.customer_total}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6"><strong>Shipping Information</strong></Typography>
                <Typography variant="body2">First name: {twistQuote.shipping_address?.first_name || 'N/A'}</Typography>
                <Typography variant="body2">Last name: {twistQuote.shipping_address?.last_name || 'N/A'}</Typography>
                <Typography variant="body2">Street: {twistQuote.shipping_address?.state_name || 'N/A'}</Typography>
                <Typography variant="body2">City: {twistQuote.shipping_address?.city || 'N/A'}</Typography>
                <Typography variant="body2">State: {twistQuote.shipping_address?.state || 'N/A'}</Typography>
                <Typography variant="body2">TAT: {twistQuote.tat.business_days + ' days' || 'N/A'}</Typography>

                <Divider sx={{ my: 2 }} />
                {quoteAlreadyOrdered ?
                  <Typography variant="h6" color="primary" textAlign='center' sx={{ p: 2 }}>
                    Quote has been ordered! Check the status of the order in the Twist order dashboard
                  </Typography>
                  :
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    loading={orderingQuote}
                    onClick={() => orderTwistQuote()}>
                    Order!
                  </LoadingButton>
                }
              </>
            }
          </Stack>}
      </Box>
    </Modal>
  );
}
export default TwistQuoteModal;
