import React, { useState } from 'react';

import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography, } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { LoadingButton } from '@mui/lab';

import { AzentaSeqOrder,       AzentaLibrary,          AzentaPool,
         initialOrderDefaults, initialLibraryDefaults, initialPoolDefaults,
         azentaOrderOptions } from 'src/models/azenta';
import { createAzentaOrder, getAllAzentaOrders } from 'src/api/AzentaOrderQueries';
import { useAuth0 } from '@auth0/auth0-react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '33%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px',
  padding: 4,
  overflowY: 'auto'
};

interface AzentaOrderProps {
  open: boolean;
  onClose: () => void;
}

function AzentaOrder(props: AzentaOrderProps) {
  const { open, onClose } = props;
  const order: AzentaSeqOrder = initialOrderDefaults;
  const { getAccessTokenSilently } = useAuth0();

  const [formValues, setFormValues] = useState<AzentaSeqOrder>(order);
  const [loading,    setLoading]    = useState(false);
  const [message,    setMessage]    = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string | number | boolean | Date>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleCreateOrder = async () => {
    setLoading(true);
    try {
      const Order: AzentaSeqOrder = {
        id: '',
        ...formValues
      };
      const accessToken = await getAccessTokenSilently();
      const result = await createAzentaOrder(accessToken, Order);
      if (!result) {
        setMessage("Error creating Azenta order");
      } else {
        setMessage(`Azenta order created successfully`);
      }
    } catch (error) {
      console.error("Error creating Azenta order:", error);
      setMessage("Error creating Azenta order");
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setMessage('');
    onClose();
  };

  const renderFormField = (key: keyof AzentaSeqOrder) => {
    const isSelectField = Object.prototype.hasOwnProperty.call(azentaOrderOptions, key);
  
    if (isSelectField) {
      const fieldOptions = azentaOrderOptions[key as keyof typeof azentaOrderOptions];
      return (
        <FormControl key={key} fullWidth margin="normal">
          <InputLabel sx={{ backgroundColor: 'white', pl:1, pr:1 }}>{key.charAt(0).toUpperCase() + key.slice(1)}</InputLabel>
          <Select
            name={key}
            value={formValues[key] ?? ''}
            onChange={(e) => handleInputChange(e as SelectChangeEvent<string | number | boolean | Date>)}
          >
            {fieldOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return (
      <TextField key={key} name={key} 
        label={key.charAt(0).toUpperCase() + key.slice(1)} 
        type={typeof formValues[key] === 'number' ? 'number' : 'text'} 
        value={formValues[key] ?? ''} 
        onChange={handleInputChange} 
        fullWidth margin="normal"
      />
    );

  };


  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Typography variant="h4" style={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
            Create Azenta Order Record
          </Typography>
          <br />
          <Typography variant="body1" style={{ position: 'sticky', top: 40, background: 'white', zIndex: 1 }}>
            Start preparing a sequencing order form that can be submitted to Azenta.
          </Typography>
        </Box>
        <Box width='100%'>
          <Typography sx={{textAlign: 'center', fontWeight: 'bold'}}>
            Order Details
          </Typography>
          <Box sx={{height: '40vh', overflowY: 'auto'}}>
            {Object.keys(order).map(key => (
              key !== 'id' && key !== 'pools' ? // && key !== 'sequenceIds' ?
                renderFormField(key as keyof AzentaSeqOrder)
              : 
                ''
            ))}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <LoadingButton loading={loading} onClick={handleCreateOrder} variant='contained' color='success' sx={{ m: 2 }}>
            Create Order Record
          </LoadingButton>
          <Button onClick={handleClose} variant='contained' color='error' sx={{ m: 2 }}>
            Cancel
          </Button>
        </Box>
        {message &&
          <Typography sx={{ color: message.startsWith('Error') ? 'red' : 'green', mt: 5, textAlign: 'center' }}>
            {message}
          </Typography>
        }
      </Box>
    </Modal>
  );

}

export default AzentaOrder;
