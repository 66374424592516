import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from 'src/models/user';
import { getAllUsers } from 'src/api/UserQueries';
import UsersTable from './UsersTable';

function UsersPage() {
  const { getAccessTokenSilently } = useAuth0();

  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, [])

  const fetchUsers = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const responseUsers = await getAllUsers(accessToken);
    if (responseUsers) {
      setUsers(responseUsers);
      setLoading(false);
    }
  }

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <LoadingButton loading={loading} variant='outlined' size='small' sx={{ mb: 1 }} onClick={() => fetchUsers()}>
              <RefreshIcon fontSize='small'/>
            </LoadingButton>
            <UsersTable users={users} setUsers={setUsers}/>
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default UsersPage;
