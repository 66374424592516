import { Box, Button, Modal, Typography } from '@mui/material';
import { Sequence } from 'src/models/sequence';

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  borderRadius: '16px'
};

interface IDTOrderProps {
  open: boolean,
  onClose: () => void,
  sequences: Array<Sequence>
}

function IDTOrder({ onClose, open }: IDTOrderProps) {

  const handleClose = () => {
    onClose();
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box sx={style}>
        <Typography variant="h4" >
          Create IDT order
        </Typography>
        <Button variant="contained">
          Download order
        </Button>
      </Box>
    </Modal>
  );
}
export default IDTOrder;
